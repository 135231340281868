import axiosTokenInstance from "../../../../services/api/service-client";

export const getCardSaved = async () => {
    try {
        const response = await axiosTokenInstance.get(
            `api/clients/cards/tokenizations`
        );

        return response;
    } catch (error) {
        const message = error?.response?.data;
        throw new Error(
            message?.detail ?? "Error al obtener las tarjetas guardadas"
        );
    }
};

export const deleteCard = async (cardId) => {
    try {
        const response = await axiosTokenInstance.delete(
            `api/clients/cards/tokenizations/${cardId}`
        );

        return response;
    } catch (error) {
        const message = error?.response?.data;
        throw new Error(
            message?.title ?? "Error al eliminar la tarjeta guardada"
        );
    }
};
