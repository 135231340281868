import { useEffect } from "react";
import { usePixelMeta } from "utils/hooks/usePixelMeta";
import { usePixelTiktok } from "utils/hooks/usePixelTiktok";

let isInitialPixelsPurchase = false;

export const usePixelsPurchase = ({ ignoreInitialition = false }) => {
    const {
        initialPixelMeta,
        initFacebookPixelWithAdvanceMatching,
        trackFacebookPixel,
    } = usePixelMeta({
        pixelID: process.env?.REACT_APP_PURCHASE_FACEBOOK_PIXEL,
    });
    const {
        initialPixelTiktok,
        initTiktokPixelWithAdvanceMatching,
        trackTiktokPixel,
    } = usePixelTiktok({
        pixelID: process.env?.REACT_APP_PURCHASE_TIKTOK_PIXEL,
    });

    useEffect(() => {
        if (isInitialPixelsPurchase) return;
        if (ignoreInitialition) return;
        initialPixelMeta();
        initialPixelTiktok();
        isInitialPixelsPurchase = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const manualInitPixels = () => {
        initialPixelMeta();
        initialPixelTiktok();
        isInitialPixelsPurchase = true;
    };

    const addShippingInfo = ({ contact }) => {
        trackTiktokPixel("CompleteRegistration");
        initTiktokPixelWithAdvanceMatching(contact);

        initFacebookPixelWithAdvanceMatching(contact);
        trackFacebookPixel("CompleteRegistration");
    };

    const clickButtonInitialPage = (data) => {
        trackTiktokPixel("ClickButton", data);
    };

    const pixelSearch = () => {
        trackTiktokPixel("Search");
        trackFacebookPixel("Search");
    };

    const pixelAddToCart = ({
        sku,
        name,
        currency = "MXN",
        amount,
        quantity,
    }) => {
        trackTiktokPixel("AddToCart", {
            content_type: "product",
            quantity,
            description: name,
            content_id: sku,
            currency: currency,
            value: amount,
        });
        trackFacebookPixel("AddToCart", {
            contents: quantity,
            content_category: name,
            content_ids: sku,
            currency: currency,
            value: amount,
        });
    };

    const pixelPurchase = ({
        quantity,
        name,
        sku,
        currency = "MXN",
        amount,
    }) => {
        trackTiktokPixel("CompletePayment", {
            content_type: "product",
            quantity,
            description: name,
            content_id: sku,
            currency: currency,
            value: amount,
        });
        trackFacebookPixel("Purchase", {
            content_type: "product",
            num_items: 1,
            content_name: name,
            currency: currency,
            content_ids: sku,
            value: amount,
        });
    };

    return {
        addShippingInfo,
        clickButtonInitialPage,
        pixelSearch,
        pixelAddToCart,
        pixelPurchase,
        manualInitPixelsPurchase: manualInitPixels,
    };
};
