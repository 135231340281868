import React from "react";
import { Grid } from "@mui/material";
import TicketContainer from "./container";
import { getTickets } from "_models/redux/tickets/action";

const TicketPage = () => {
    return (
        <Grid container xs={12}>
            <TicketContainer />
        </Grid>
    );
};

export default TicketPage;
