import React from "react";
import { Container, Grid } from "@mui/material";
import Logo from "assets/images/logo.png";
import giftImage from "assets/images/landings/gift/bun.png";
import activeQRImage from "assets/images/landings/esim-activation/qr.png";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";

const GiftActivationHeader = (props) => {
    const { giftDetails } = props || {};

    return (
        <Container>
            <Grid container className="gift-landing-content">
                <Grid item xs={12} md={7} className="gift-landing-header">
                    <img src={giftImage} className={"gift-landing-img"} />
                </Grid>
                <Grid item xs={12} md={7} className="gift-landing-text">
                    <img src={Logo} className={"logo"} />
                    <h1>
                        <span>{giftDetails?.name || "Hola"}, </span>
                    </h1>
                    <h1 className="responsive-title">tu eSIM de regalo</h1>
                    <h1>ya está aquí</h1>
                    <p>
                        Disfruta de <span>10</span>GB{" "}
                        <div className="signos">+</div> <span>500</span>min{" "}
                        <div className="signos">+</div> <span>250</span>sms{" "}
                        <div className="signos">x</div> <span>7</span> días.
                    </p>
                    <h2>
                        <AccessAlarmsIcon /> Actívala ahora,{" "}
                        <span>la eSIM caduca en 30 días.</span>
                    </h2>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={5}
                    display={"flex"}
                    justifyContent={"center"}
                >
                    <Grid container className="gift-code-container">
                        <Grid item xs={12} className="gift-code">
                            <Grid container className="gift-box">
                                <Grid item xs={12} className="gift-qr-text">
                                    <h3>Tu número Dalefon es</h3>
                                    <h1>
                                        {giftDetails?.direct_number || "---"}
                                    </h1>
                                    <p>
                                        Escanea el código QR con el celular en
                                        donde deseas activar tu eSIM y sigue los
                                        pasos.
                                    </p>
                                </Grid>
                                <Grid item xs={12} className="gift-image">
                                    <img
                                        src={
                                            giftDetails?.qr_img_url ||
                                            activeQRImage
                                        }
                                        className={"active-gift-image"}
                                    />
                                </Grid>
                            </Grid>
                            <h5>
                                <span>¡Ojo!</span> {""} Si eliminas la eSIM,
                                cambias o formateas tu equipo, necesitaras
                                adquirir una nueva, ya que no es transferible.
                            </h5>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};

export default GiftActivationHeader;
