import React from "react";

//Material UI:
import { Box, Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

//Styles MUI:
import { styleButton } from "./styles/customButtonStyle";

//CSS:
import "../../assets/styles/css/utils/templates/custom-button.css";

const CustomButtonComponent = ({
    text,
    bgColor,
    height,
    onClick = () => {},
    async = false,
    loading,
}) => {
    return (
        <Box className="container-custom-button">
            {async === true ? (
                <LoadingButton
                    className="custom-button"
                    fullWidth
                    sx={styleButton({ bgColor, height })}
                    type="submit"
                    loading={loading}
                    loadingPosition="center"
                    onClick={onClick}
                >
                    {text}
                </LoadingButton>
            ) : (
                <Button
                    className="custom-button"
                    fullWidth
                    variant="contained"
                    sx={styleButton({ bgColor, height })}
                    onClick={onClick}
                    type="submit"
                >
                    {text}
                </Button>
            )}
        </Box>
    );
};

export default CustomButtonComponent;
