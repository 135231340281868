import { OFFERSELECTION, OFFERSELECTION_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const offerSelectionAction = (params) => async (dispatch) => {
    const { tokenView, sku } = params || "";

    try {
        const response = await axiosAuthInstance.patch(
            `/api/leads/steps/offer-selection`,
            { product_sku: sku },
            {
                headers: {
                    Authorization: `Bearer ${tokenView}`,
                },
            }
        );

        dispatch({
            type: OFFERSELECTION,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: OFFERSELECTION_ERROR,
            payload: e || {},
        });
    }
};
