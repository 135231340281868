import {
    OFFERSELECTION,
    OFFERSELECTION_ERROR,
    OFFERSELECTION_CLEAN,
    OFFERSELECTION_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    offerSelectionObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case OFFERSELECTION:
            return {
                ...state,
                offerSelectionObj: action.payload,
                loading: true,
            };
        case OFFERSELECTION_ERROR:
            return {
                ...state,
                offerSelectionObj: action.payload,
                loading: false,
            };
        case OFFERSELECTION_CLEAN:
            return {
                ...state,
                offerSelectionObj: action.payload,
                loading: true,
            };
        case OFFERSELECTION_CLEAN_ERROR:
            return {
                ...state,
                offerSelectionObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
