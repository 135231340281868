import axiosTokenInstance from "services/api/service-client";

import {
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_ERROR,
} from "_models/redux/types";

export const deleteSubscriptionAction = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.delete(
            `/api/clients/actions/prepaid-subscription`
        );

        const { data } = response;

        dispatch({
            type: DELETE_SUBSCRIPTION,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: DELETE_SUBSCRIPTION_ERROR,
            payload: e || {},
        });
    }
};
