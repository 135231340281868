export const registerList = () => {
    return {
        registerOptions: {
            purchase_order_number: {
                required: "El número de orden es requerido",
            },
            receiver_legal_id: {
                required: "El RFC del receptor es requerido",
                minLength: {
                    value: 12,
                    message:
                        "El RFC del receptor debe contener mínimo 12 dígitos",
                },
            },
            receiver_legal_name: {
                required: "La razón social es requerida",
            },
            receiver_tax_regime: {
                required: "El régimen fiscal es requerido",
            },
            receiver_legal_zipcode: {
                required: "El código postal es requerido",
                minLength: {
                    value: 5,
                    message: "El código postal debe contener 5 dígitos",
                },
                maxLength: {
                    value: 5,
                    message: "",
                },
            },
            cfdi_use: {
                required: "El uso de CFDI es requerido",
            },
            email: {
                // required: "El correo electrónico es requerido",
                pattern: {
                    value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9-]+\.[a-zA-Z]+/,
                    message:
                        "El valor ingresado no coincide con el formato del correo electrónico",
                },
            },
        },
    };
};
