import {
    LEADS_PURCHASEREQUEST,
    LEADS_PURCHASEREQUEST_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const leadsPurchaseRequestAction = (params) => async (dispatch) => {
    const { tokenView } = params || "";
    try {
        const response = await axiosAuthInstance.get(
            `/api/leads/steps/v3/purchase-request`,
            {
                headers: {
                    Authorization: `Bearer ${tokenView}`,
                },
            }
        );

        dispatch({
            type: LEADS_PURCHASEREQUEST,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: LEADS_PURCHASEREQUEST_ERROR,
            payload: e || {},
        });
    }
};
