import React from "react";
import RouterContainer from "../container";

//Components:
import LoginContainer from "modules/login/LoginContainer";

const PrivateRoutes = ({ children }) => {
    return (
        <RouterContainer
            loggedChildren={children}
            unloggedChildren={<LoginContainer />}
        />
    );
};

export default PrivateRoutes;
