import {
    CLEAN_GET_PURCHASE_PAYMENT_STATUS,
    CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR,
} from "_models/redux/types";

export const cleanPurchasePaymentStatusAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_GET_PURCHASE_PAYMENT_STATUS,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_GET_PURCHASE_PAYMENT_STATUS_ERROR,
            payload: [],
        });
    }
};
