import React, { useEffect } from "react";

import { Container } from "@mui/material";

import UserTicketsPage from "modules/support/tickets/flow/userTickets1/page";

const TicketsComponent = (props) => {
    return (
        <Container>
            <UserTicketsPage />
        </Container>
    );
};

export default TicketsComponent;
