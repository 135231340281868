import { GET_LEAD_DATA, GET_LEAD_DATA_ERROR } from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const getLeadDataAction = (params) => async (dispatch) => {
    try {
        const { lead_id, lead_key, recaptcha } = params || {};
        const requestData = { lead_key, recaptcha_token: recaptcha };

        const response = await axiosAuthInstance.get(
            `/api/recover/leads/${lead_id}`,
            {
                params: requestData,
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: GET_LEAD_DATA,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: GET_LEAD_DATA_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
