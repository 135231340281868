import {
    GET_DEVICES_MODELS,
    GET_DEVICES_MODELS_ERROR,
    CLEAN_GET_DEVICES_MODELS,
    CLEAN_GET_DEVICES_MODELS_ERROR,
} from "_models/redux/types";

const initialState = {
    modelsDevicesObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_DEVICES_MODELS:
            return {
                ...state,
                modelsDevicesObj: action.payload,
                loading: true,
            };
        case GET_DEVICES_MODELS_ERROR:
            return {
                ...state,
                modelsDevicesObj: action.payload,
                loading: false,
            };
        case CLEAN_GET_DEVICES_MODELS:
        case CLEAN_GET_DEVICES_MODELS_ERROR:
            return {
                ...state,
                modelsDevicesObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
