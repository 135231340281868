import React, { useState } from "react";

import { RouterContext } from "./context";
import RouterContainer from "./container";

import PrivatePage from "./pages/private";
import PublicPage from "router/pages/public";

const RouterComponent = () => {
    const [routerTitle, setRouterTitle] = useState();

    const routerValues = {
        routerTitle,
        setRouterTitle,
    };
    return (
        <RouterContext.Provider value={routerValues}>
            <RouterContainer
                loggedChildren={<PrivatePage />}
                unloggedChildren={<PublicPage />}
            />
        </RouterContext.Provider>
    );
};

export default RouterComponent;
