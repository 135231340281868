export const gridItemContainerCard = {
    backgroundColor: "white",
    borderRadius: 10,
    p: 4,
};

export const typographyNoteCard = {
    fontSize: 14,
    textAlign: "left",
};

export const typographyTitleCard = {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "Poppins-Medium",
    textAlign: "left",
    mb: 2,
};
