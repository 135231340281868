import imgMasterCard from "assets/images/payment/mastercard.svg";
import imgVisa from "assets/images/payment/visa.svg";
import imgAmex from "assets/images/payment/amex.svg";
import imgCardDefault from "assets/images/payment/cardDefault.png";

export const IMAGES_CARDS = {
    MASTERCARD: {
        src: imgMasterCard,
        alt: "Tarjeta MasterCard",
        title: "MasterCard",
    },
    VISA: {
        src: imgVisa,
        alt: "Tarjeta Visa",
        title: "Visa",
    },
    AMEX: {
        src: imgAmex,
        alt: "Tarjeta American Express",
        title: "American Express",
    },
    "AMERICAN EXPRESS": {
        src: imgAmex,
        alt: "Tarjeta American Express",
        title: "American Express",
    },
    DEFAULT: {
        src: imgCardDefault,
        alt: "Tarjeta Otra",
        title: "Otra",
    },
};
