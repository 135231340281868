import {
    RESET_PASSWORD_STATUS,
    RESET_PASSWORD_STATUS_ERROR,
    RESET_PASSWORD_STATUS_CLEAN,
    RESET_PASSWORD_STATUS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    resetPasswordStatus: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case RESET_PASSWORD_STATUS:
        case RESET_PASSWORD_STATUS_CLEAN:
            return {
                ...state,
                resetPasswordStatus: action.payload,
                loading: true,
            };
        case RESET_PASSWORD_STATUS_ERROR:
        case RESET_PASSWORD_STATUS_CLEAN_ERROR:
            return {
                ...state,
                resetPasswordStatus: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
