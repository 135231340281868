import React from "react";

//Material UI:
import { Box } from "@mui/material";

//Styles MUI:
import { gridItemBase } from "../styles-mui/giftStyleMUI";

//Components:
import { Outlet } from "react-router-dom";

function GiftMainPageComponent() {
    return (
        <Box sx={gridItemBase}>
            <Outlet />
        </Box>
    );
}

export default GiftMainPageComponent;
