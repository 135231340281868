import { CHECKIMEI, CHECKIMEI_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const checkImeiAction = (params) => async (dispatch) => {
    const { token, imei } = params || "";

    try {
        const response = await axiosAuthInstance.patch(
            `/api/leads/steps/check-imei`,
            { imei },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        dispatch({
            type: CHECKIMEI,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: CHECKIMEI_ERROR,
            payload: e || {},
        });
    }
};
