import {
    POST_LEAD_CHECK_IMEI,
    POST_LEAD_CHECK_IMEI_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const postLeadCheckImeiAction = (params) => async (dispatch) => {
    const { lead_id, lead_key, imei, recaptcha } = params || {};

    try {
        const response = await axiosAuthInstance.post(
            `/api/recover/leads/${lead_id}/imei-compatibility`,
            {
                imei: imei,
                recaptcha_token: recaptcha,
                lead_key: lead_key,
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: POST_LEAD_CHECK_IMEI,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: POST_LEAD_CHECK_IMEI_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
