import axiosAuthInstance from "../../../services/api/authentication";
import { SET_USER_GIFT, SET_USER_GIFT_ERROR } from "_models/redux/types";

// Función de acción simulada
export const setUserGiftAction = (params) => async (dispatch) => {
    const { token, payload } = params || {};

    try {
        const response = await axiosAuthInstance.post(
            `api/leads/steps/gift/set-info`,
            payload,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: SET_USER_GIFT,
            payload: data,
            status: status,
            method: "post",
        });
    } catch (e) {
        dispatch({
            type: SET_USER_GIFT_ERROR,
            payload: e,
        });
    }
};
