import { Grid } from "@mui/material";
import PrepaidAuthnInvoiceContent from "./PrepaidAuthnInvoiceContent";

const PrepaidAuthnInvoicePresentational = () => {
    return (
        <Grid item xs={12} className="section invoice">
            <Grid container className="flex" alignItems="center">
                <Grid item xs={12} className="title-section">
                    <h5>Datos de la factura</h5>
                </Grid>
            </Grid>

            <PrepaidAuthnInvoiceContent />
        </Grid>
    );
};

export default PrepaidAuthnInvoicePresentational;
