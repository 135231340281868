export const formInputs = (params) => {
    const {
        errors,
        registerOptions,

        selectsReducer,

        handlers,
    } = params || {};

    const {
        stateOptionSelected,
        municipalityOptionSelected,
        neighborhoodOptionSelected,
        offersOptionSelected,

        stateOptions,
        municipalityOptions,
        neighborhoodOptions,
        offersOptions,
    } = selectsReducer || {};

    const { onFocusHandler, onChangeHandler } = handlers || {};

    return {
        inputs: {
            clientInputs: [
                {
                    type: "text",
                    id: "name",
                    label: "Nombre completo *",
                    placeholder: "Nombre completo *",
                    containerCls: "m-t-20",
                    error: errors?.name,
                    validations: registerOptions?.name,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
                {
                    type: "number",
                    id: "phone_number",
                    label: "Número de contacto *",
                    placeholder: "Número de contacto *",
                    containerCls: "m-t-20",
                    error: errors?.phone_number,
                    validations: registerOptions?.phone_number,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
                {
                    type: "email",
                    id: "email",
                    label: "Correo electrónico (opcional)",
                    placeholder: "Correo electrónico (opcional)",
                    containerCls: "m-t-20",
                    error: errors?.email,
                    validations: registerOptions?.email,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
            ],
            imeiInputs: [
                {
                    type: "text",
                    id: "imei",
                    label: "Ingresa el código IMEI",
                    placeholder: "Ingresa el código IMEI",
                    containerCls: "m-t-20",
                    error: errors?.imei,
                    validations: registerOptions?.imei,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
            ],
            shipmentInputs: [
                {
                    type: "text",
                    id: "zipcode",
                    label: "Código Postal *",
                    placeholder: "Código Postal",
                    containerCls: "m-t-20",
                    error: errors?.zipcode,
                    validations: registerOptions?.zipcode,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },

                {
                    type: "select",
                    cls: "form-control",
                    id: "state",
                    label: "Estado *",
                    placeholder: "Estado",
                    containerCls: "p-tb-20",
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                    validations: registerOptions?.state,
                    error: errors?.state,
                    options: stateOptions?.states,
                    value: stateOptionSelected,
                    valueProgrammatically: true,
                },
                {
                    type: "select",
                    cls: "form-control",
                    id: "municipality",
                    label: "Municipio *",
                    placeholder: "Municipio",
                    containerCls: "p-tb-20",
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                    validations: registerOptions?.municipality,
                    error: errors?.municipality,
                    options: municipalityOptions,
                    value: municipalityOptionSelected,
                    valueProgrammatically: true,
                },
                {
                    type: "select",
                    cls: "form-control",
                    id: "neighborhood",
                    label: "Colonia *",
                    placeholder: "Colonia",
                    containerCls: "p-tb-20",
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                    validations: registerOptions?.neighborhood,
                    error: errors?.neighborhood,
                    options: neighborhoodOptions,
                    value: neighborhoodOptionSelected,
                    valueProgrammatically: true,
                },

                {
                    type: "text",
                    id: "address",
                    label: "Dirección *",
                    placeholder: "Calle y número int / ext",
                    containerCls: "m-t-20",
                    error: errors?.address,
                    validations: registerOptions?.address,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
                {
                    type: "text",
                    id: "contact_phone_number",
                    label: "Núm. contacto servicio *",
                    placeholder: "Num. de contacto para servicio de mensajería",
                    containerCls: "m-t-20",
                    error: errors?.contact_phone_number,
                    validations: registerOptions?.contact_phone_number,
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                },
            ],
            packageInputs: [
                {
                    type: "select",
                    cls: "form-control",
                    id: "offer_id",
                    label: "Paquete *",
                    placeholder: "Paquete",
                    containerCls: "p-tb-20",
                    onFocusHandler: onFocusHandler,
                    onChangeHandler: onChangeHandler,
                    validations: registerOptions?.offer_id,
                    error: errors?.offer_id,
                    options: offersOptions,
                    value: offersOptionSelected,
                    valueProgrammatically: true,
                },
            ],
        },
    };
};

export default formInputs;
