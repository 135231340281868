import { useContext } from "react";

import { Grid, Box, Typography, Button } from "@mui/material";

import QRImage from "assets/images/lead-recovery/qr.png";
import UsersImage from "assets/images/lead-recovery/users.png";
import CalendarImage from "assets/images/lead-recovery/calendar.png";

import ESIMCompatible from "assets/images/template/esim-compatible.png";

import { LeadContext } from "../../data/contexts/leadContext";

const ImeiEsimResponseComponent = () => {
    const leadContext = useContext(LeadContext);
    const { dispatchLeadData } = leadContext || {};

    return (
        <Grid
            container
            className="lead-recovery-wrapper lead-container-congratulations"
        >
            <Grid item md={6} xs={12} className="lead-recovery-imei-section">
                <Box
                    sx={{
                        py: { xs: 2, md: 4 },
                        px: { xs: 0, md: 6 },
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: 25, md: 35 },
                            textAlign: "center",
                        }}
                    >
                        <span className="poppins-bold-font">¡Felicidades!</span>
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: { xs: 18, md: 25 },
                            textAlign: "center",
                        }}
                    >
                        <span className="poppins-font">
                            Tu equipo es compatible con{" "}
                        </span>
                        <span className="poppins-semibold-font">eSIM</span>
                    </Typography>

                    <Box
                        className="lead-recovery-imei-element"
                        sx={{
                            textAlign: "center",
                            display: { xs: "none", md: "flex" },
                            my: 2,
                        }}
                    >
                        <img
                            src={ESIMCompatible}
                            className={"no-compatibility-image"}
                            alt="Imagen de incompatibilidad"
                        />
                    </Box>
                </Box>
            </Grid>
            <Grid
                item
                md={6}
                xs={12}
                className="lead-recovery-section"
                sx={{
                    background: "#ffffff",
                    borderEndEndRadius: "20px",
                    borderStartEndRadius: "20px",
                    borderStartStartRadius: { xs: "20px", md: "0px" },
                    borderEndStartRadius: { xs: "20px", md: "0px" },
                    color: "#2f2b31",
                    py: { xs: 0, md: 2 },
                }}
            >
                <Box
                    sx={{
                        py: { xs: 2, md: 4 },
                        px: { xs: 2, md: 6 },
                    }}
                >
                    <Typography
                        className="lead-recovery-imei-element"
                        sx={{
                            textAlign: "center",
                            fontSize: { xs: 18, md: 22 },
                        }}
                    >
                        <span className="poppins-regular-font  m-b-0">
                            Ahora puedes ser parte del futuro,{" "}
                        </span>
                    </Typography>

                    <Typography
                        className="lead-recovery-imei-element"
                        sx={{
                            textAlign: "center",
                            fontSize: { xs: 20, md: 22 },
                        }}
                    >
                        <span className="poppins-bold-font m-t-0">
                            descubre la eSIM
                        </span>
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "center",
                            mb: 2,
                            fontSize: { xs: 16 },
                            mt: { xs: 2, md: 6 },
                        }}
                    >
                        <span className="poppins-regular-font">
                            Olvídate de los 'chips' y dale un vistazo al futuro
                            de la telefonía con nuestra eSIM.
                        </span>
                    </Typography>

                    <Typography
                        sx={{
                            textAlign: "center",
                            mt: 3,
                            mb: { xs: 2, md: 6 },
                            fontSize: { xs: 17 },
                        }}
                    >
                        <span className="poppins-bold-italic-font">
                            Más segura, más práctica y la activas al instante
                        </span>
                    </Typography>

                    <Grid
                        container
                        className="lead-recovery-imei-element"
                        spacing={3}
                    >
                        <Grid item md={4} xs={12}>
                            <Box className="lead-recovery-imei-esim">
                                <Box
                                    className="lead-recovery-imei-esim-data"
                                    sx={{ textAlign: "center" }}
                                >
                                    <img
                                        src={QRImage}
                                        className={"esim-compatible-image"}
                                        alt="Imagen QR"
                                    />
                                </Box>
                                <Box className="lead-recovery-imei-esim-data">
                                    <p>Fácil de instalar</p>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={4} xs={6}>
                            <Box className="lead-recovery-imei-esim">
                                <Box
                                    className="lead-recovery-imei-esim-data"
                                    sx={{ textAlign: "center" }}
                                >
                                    <img
                                        src={UsersImage}
                                        className={"esim-compatible-image"}
                                        alt="Imagen usuarios"
                                    />
                                </Box>
                                <Box className="lead-recovery-imei-esim-data">
                                    <p>Dos líneas en tu dispositivo</p>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item md={4} xs={6}>
                            <Box className="lead-recovery-imei-esim">
                                <Box
                                    className="lead-recovery-imei-esim-data"
                                    sx={{ textAlign: "center" }}
                                >
                                    <img
                                        src={CalendarImage}
                                        className={"esim-compatible-image"}
                                        alt="Imagen calendario"
                                    />
                                </Box>
                                <Box className="lead-recovery-imei-esim-data">
                                    <p>Sin plazos forzosos</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* <Grid
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 2,
                        }}
                    >
                        <Button
                            type="button"
                            variant="text"
                            onClick={() =>
                                dispatchLeadData({
                                    property: "compatibility",
                                    value: undefined,
                                })
                            }
                            sx={{
                                fontSize: { xs: 16, md: 18 },
                                color: "#fd45d9",
                                textTransform: "none",
                                mt: { xs: 2, md: 0 },
                            }}
                        >
                            <span className="poppins-semibold-font">
                                ¡Inténtalo con otro equipo!
                            </span>
                        </Button>
                    </Grid> */}
                </Box>
            </Grid>
        </Grid>
    );
};

export default ImeiEsimResponseComponent;
