import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { MenuContext } from "./context";

import { useLogin } from "./hook";

import { setLoginDataHelper } from "_controllers/helpers/setLoginData";

import { loginAction } from "_models/redux/login/action";

import { cleanPurchaseContactInfoAction } from "_models/redux/add-purchase-contact-info/clean";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";
import { cleanGetOffersAction } from "_models/redux/get-offers/clean";
import { cleanOfferSelectionAction } from "_models/redux/offer-selection/clean";
import { cleanPaymentAction } from "_models/redux/payment/clean";
import { cleanShippinghAddressAction } from "_models/redux/shipping-address/clean";
import { cleanMunicipalitiesAction } from "_models/redux/municipalities/clean";
import { cleanNeighborhoodsAction } from "_models/redux/neighborhoods/clean";
import { cleanPaymentConfirmAction } from "_models/redux/payment-confirm/clean";
import { cleanCompleteProcessAction } from "_models/redux/complete-process/clean";

//Components:
import ClientMenuComponent from "./components/ClientMenuComponent";

const MenuContainer = (props) => {
    const history = useNavigate();

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [loginResponse, setLoginResponse] = useState();
    const [loginResponseCls, setLoginResponseCls] = useState({
        container: "",
        text: "",
    });

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const {
        login,

        loginAction,
        cleanPurchaseContactInfoAction,
        cleanCheckImeiAction,
        cleanGetOffersAction,
        cleanOfferSelectionAction,
        cleanPaymentAction,
        cleanShippinghAddressAction,
        cleanMunicipalitiesAction,
        cleanNeighborhoodsAction,
        cleanPaymentConfirmAction,
        cleanCompleteProcessAction,
    } = props;

    const { sendMethod } = useLogin({
        login,
        loginAction,
    });

    const sendLogin = (data) => {
        setVariant("indeterminate");
        sendMethod(data);
    };

    const loginValues = {
        sendLogin,

        loginResponse,
        setLoginResponse,
        loginResponseCls,
        setLoginResponseCls,

        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    useEffect(() => {
        cleanPurchaseContactInfoAction();
        cleanCheckImeiAction();
        cleanGetOffersAction();
        cleanOfferSelectionAction();
        cleanShippinghAddressAction();
        cleanMunicipalitiesAction();
        cleanNeighborhoodsAction();
        cleanPaymentAction();
        cleanPaymentConfirmAction();
        cleanCompleteProcessAction();
    }, []);

    useEffect(() => {
        if (typeof login.login == "undefined") {
            return false;
        }
        const { token, expires_at, refresh_token, response } =
            login.login || {};
        if (
            typeof token != "undefined" &&
            typeof expires_at != "undefined" &&
            typeof refresh_token != "undefined"
        ) {
            setLoginResponse("¡Bienvenido!");
            setLoginResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            setLoginDataHelper(login.login, "LoginContainer");
            history("/dashboard");
        } else if (typeof response != "undefined") {
            const { data } = response || {};
            const { detail } = data || {};
            setLoginResponse(detail || "");
            setLoginResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            setLoginDataHelper(
                { token: null, expires_at: null, refresh_token: null },
                "MenuContainer"
            );
            history("/menu");
        }
    }, [login]);

    return (
        <MenuContext.Provider value={loginValues}>
            <ClientMenuComponent />
        </MenuContext.Provider>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    login: state.login,

    beCustomer: state.beCustomer,
    checkImei: state.checkImei,
    getOffers: state.getOffers,
    offerSelection: state.offerSelection,
    shippingAddress: state.shippingAddress,
    municipalitiesData: state.municipalitiesData,
    neighborhoodsData: state.neighborhoodsData,
    payment: state.payment,
    paymentConfirm: state.paymentConfirm,
    completeProcess: state.completeProcess,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    loginAction,

    cleanPurchaseContactInfoAction,
    cleanCheckImeiAction,
    cleanGetOffersAction,
    cleanOfferSelectionAction,
    cleanShippinghAddressAction,
    cleanMunicipalitiesAction,
    cleanNeighborhoodsAction,
    cleanPaymentAction,
    cleanPaymentConfirmAction,
    cleanCompleteProcessAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
