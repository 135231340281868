import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Grid } from "@mui/material";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const QRNotFoundContent = (props) => {
    const { state } = useLocation();
    const { payload } = state || {};
    const { detail, title } = payload || {};

    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Grid container className="qr-not-found-container">
            <Grid item xs={12}>
                <h1 className="qr-not-found-legend">
                    <WarningAmberIcon /> {title}
                </h1>
                <h3 className="qr-not-found-legend">{detail}</h3>
            </Grid>
        </Grid>
    );
};

export default QRNotFoundContent;
