import { useEffect, useState } from "react";
import { deleteCard, getCardSaved } from "../service/payment-methods-service";

const INIT_STATE_MODAL_RESPONSE = { open: false, isError: false, message: "" };

export const usePaymentMethods = () => {
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalResponseState, setModalResponseState] = useState(
        INIT_STATE_MODAL_RESPONSE
    );

    useEffect(() => {
        setIsLoading(true);
        const getCards = async () => {
            try {
                const resp = await getCardSaved();
                const { cards } = resp.data;
                setCards(cards);
                setModalResponseState(INIT_STATE_MODAL_RESPONSE);
            } catch (error) {
                setModalResponseState({
                    isError: true,
                    message: error.message,
                    open: true,
                });
            } finally {
                setIsLoading(false);
            }
        };

        getCards();
    }, []);

    const handleDeleteCard = async (cardId) => {
        try {
            await deleteCard(cardId);
            setCards((prevCards) =>
                prevCards.filter((card) => card.id !== cardId)
            );
            setModalResponseState({
                isError: false,
                message: "Tarjeta eliminada",
                open: true,
            });
        } catch (error) {
            setModalResponseState({
                isError: true,
                message: error.message,
                open: true,
            });
        }
    };

    const handleCloseModalResponse = () => {
        setModalResponseState(INIT_STATE_MODAL_RESPONSE);
    };

    return {
        cards,
        isLoading,
        handleDeleteCard,
        modalResponseState,
        handleCloseModalResponse,
    };
};
