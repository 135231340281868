export const useReducerHelper = (state, action) => {
    switch (action.type) {
        case "propertyValue":
        default: {
            return {
                ...state,
                [action.property]: action.value,
            };
        }
    }
};
