import { useContext, useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";

import { InvoiceContext } from "../data/invoiceContext";

import InvoiceByStepRegisterComponent from "./InvoiceByStepRegisterComponent";
import InvoiceByStepStatusComponent from "./InvoiceByStepStatusComponent";

import Header from "../widgets/header";

const InvoiceContentComponent = (props) => {
    const invoiceContext = useContext(InvoiceContext);
    const { steps, invoiceData, dispatchInvoiceData, invoiceStep2 } =
        invoiceContext || {};
    const { stepCounter } = invoiceData || {};
    const { postInvoiceSimPurchase } = invoiceStep2 || {};
    const { step } = steps || {};
    const [myStep, setMyStep] = useState(<h1>hello world</h1>);

    // return step === 1 ? <h1>hola mundo</h1> : <h1>adiós mundo</h1>;

    useEffect(() => {
        dispatchInvoiceData({
            property: "stepCounter",
            value: 1,
        });
    }, []);

    useEffect(() => {
        switch (stepCounter) {
            case 1:
                setMyStep(
                    <>
                        {/* <h1>STEP 1</h1> */}
                        <InvoiceByStepStatusComponent />
                    </>
                );
                break;

            case 2:
                setMyStep(
                    <>
                        {/* <h1>STEP 2</h1>
                        <h2>FORM</h2> */}
                        <InvoiceByStepRegisterComponent />
                    </>
                );
                break;

            case 3:
                let uuid = postInvoiceSimPurchase.payload.uuid;
                window.location.href = `/factura/${uuid}/descarga`;
                break;

            default:
                setMyStep(
                    <>
                        <h1>DEFAULT</h1>
                    </>
                );
                break;
        }
    }, [stepCounter, postInvoiceSimPurchase]);

    return (
        <Grid container className="invoice-content" mt={5}>
            <Container>
                <Header />
                <Grid container mb={10} display={"flex"} alignItems={"center"}>
                    {myStep}
                </Grid>
            </Container>
        </Grid>
    );
};

export default InvoiceContentComponent;
