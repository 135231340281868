import React from "react";
import { Grid } from "@mui/material";
import activeQRImage from "assets/images/landings/esim-activation/qr.png";

const EsimQR = (props) => {
    const { esimDetails } = props || {};

    return (
        <Grid container className="qr-code-container">
            <Grid item xs={12} className="qr-code">
                <h1>Actívala ahora</h1>
                <p>La vigencia de tu paquete iniciará al instalar tu eSIM</p>
                <Grid container className="qr-box">
                    <Grid item xs={12} md={7}>
                        <h3>Tu número Dalefon es</h3>
                        <h1>{esimDetails?.direct_number || "---"}</h1>
                        <p>
                            Escanea el código QR con el celular en donde deseas
                            activar tu eSIM y sigue los pasos.
                        </p>
                    </Grid>
                    <Grid item xs={12} md={5} className="qr-image">
                        <img
                            src={esimDetails?.qr_img_url || activeQRImage}
                            className={"active-qr-image"}
                        />
                    </Grid>
                </Grid>
                <h5>
                    <span>¡Ojo!</span> {""} Si eliminas la eSIM, cambias o
                    formateas tu equipo, necesitaras adquirir una nueva, ya que
                    no es transferible.
                </h5>
            </Grid>
        </Grid>
    );
};

export default EsimQR;
