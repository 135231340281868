export const containerInvoiceDownloadPrepaid = {
    backgroundColor: "white",
    py: { xs: 6, md: 9 },
};

export const typographyTitle = {
    fontSize: { xs: 25, md: 40 },
    color: "#4F3078",
    fontWeight: "bold",
    fontFamily: "Poppins-Bold",
    mb: 3,
};

export const typographySubtitle = {
    fontSize: { xs: 16, md: 20 },
    fontFamily: "Poppins-Regular",
    mx: { xs: 6, md: 0 },
};

export const containerFiles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const gridContainerFiles = {
    background: "#F5F5FA 0% 0% no-repeat padding-box",
    borderRadius: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    mb: 5,
    py: 4.5,
};

export const gridItemFiles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const gridContainerButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
