import {
    LEADS_PURCHASEREQUEST_CLEAN,
    LEADS_PURCHASEREQUEST_ERROR_CLEAN,
} from "_models/redux/types";

export const cleanLeadsPurchaseRequestAction = () => async (dispatch) => {
    try {
        dispatch({
            type: LEADS_PURCHASEREQUEST_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: LEADS_PURCHASEREQUEST_ERROR_CLEAN,
            payload: [],
        });
    }
};
