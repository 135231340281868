// Import: Middleware Action & Custom Types
import {
    CLEAN_NEWPASSWORD,
    CLEAN_NEWPASSWORD_ERROR,
} from "_models/redux/types";

// Action name
const action = "cleanForgotAction";

// Action function
export const cleanAccountAction = (params) => async (dispatch) => {
    // Endpoint: Try & Catch

    try {
        dispatch({
            type: CLEAN_NEWPASSWORD,
            payload: [],
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: CLEAN_NEWPASSWORD_ERROR,
            payload: [],
        });
    }
};
