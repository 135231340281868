import PortalNavigation from "modules/portal/components/navigation";
import PortalFooter from "modules/portal/components/footer";
import {
    Box,
    Button,
    Container,
    Dialog,
    DialogContent,
    Grid,
    Skeleton,
    Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { CardPayment } from "../components/card-payment/CardPayment";
import {
    bold,
    generalStyleMui,
    mainContainer,
    semibold,
    modalStyle,
} from "../styles/generalStyleMui";
import { usePaymentMethods } from "../hooks/usePaymentMethods";
import { EmptyContainer } from "../components/empty-container/EmptyContainer";

export const MyPaymentMethodsPage = () => {
    const {
        cards,
        isLoading,
        handleDeleteCard,
        modalResponseState,
        handleCloseModalResponse,
    } = usePaymentMethods();
    const { open, isError, message } = modalResponseState;
    return (
        <div>
            <PortalNavigation />
            <div className={"header"}>
                <Container>
                    <Grid
                        container
                        sx={{
                            ...generalStyleMui,
                            mb: cards.length === 0 ? "150px" : "45px",
                            mt: cards.length === 0 ? "50px" : "45px",
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            {isLoading ? (
                                <Skeleton
                                    width={300}
                                    variant="text"
                                    sx={{
                                        fontSize: "1rem",
                                    }}
                                />
                            ) : cards.length > 0 ? (
                                <Typography variant="h1" fontSize={28}>
                                    Mis tarjetas
                                </Typography>
                            ) : (
                                <Box>
                                    <Typography
                                        variant="h1"
                                        fontSize={{ xs: 25, md: 31 }}
                                        sx={semibold}
                                    >
                                        Agrega tu primer tarjeta
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        fontSize={{ xs: 30, md: 36 }}
                                        color={"#8D69F5"}
                                        sx={bold}
                                    >
                                        al hacer una recarga
                                    </Typography>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <Container maxWidth={false} sx={{ backgroundColor: "white" }}>
                <Container sx={mainContainer}>
                    {isLoading ? (
                        <>
                            <Skeleton
                                variant="rounded"
                                width={310}
                                height={85}
                            />
                            <Skeleton
                                variant="rounded"
                                width={310}
                                height={85}
                            />
                        </>
                    ) : cards.length > 0 ? (
                        cards?.map(
                            ({ id, card_network, masked_card }, index) => (
                                <CardPayment
                                    key={id}
                                    isPurple={index % 2 === 0}
                                    typeCard={card_network}
                                    cardNumber={masked_card}
                                    title={card_network}
                                    id={id}
                                    handleDeleteCard={handleDeleteCard}
                                />
                            )
                        )
                    ) : (
                        <Box mt={"-120px"}>
                            <EmptyContainer />
                        </Box>
                    )}
                </Container>
            </Container>

            <Dialog
                open={open}
                onClose={handleCloseModalResponse}
                aria-labelledby="Alerta de respuesta"
                sx={modalStyle}
            >
                <Button
                    color="inherit"
                    onClick={handleCloseModalResponse}
                    sx={{
                        minWidth: "auto",
                        position: "absolute",
                        right: "0px",
                        padding: "10",
                        fontSize: "16px",
                    }}
                >
                    &times;
                </Button>
                <DialogContent
                    sx={{
                        width: "310px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "21px",
                        justifyContent: "center",
                    }}
                >
                    {!isError ? (
                        <CheckCircleIcon
                            sx={{ fill: "#8677F4", fontSize: "40px" }}
                        />
                    ) : (
                        <ErrorIcon sx={{ fill: "#E62121", fontSize: "40px" }} />
                    )}

                    <Typography>{message}</Typography>
                </DialogContent>
            </Dialog>

            <PortalFooter />
        </div>
    );
};
