import { PAYMENT_CLEAN, PAYMENT_CLEAN_ERROR } from "_models/redux/types";

export const cleanPaymentAction = () => async (dispatch) => {
    try {
        dispatch({
            type: PAYMENT_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: PAYMENT_CLEAN_ERROR,
            payload: [],
        });
    }
};
