import React from "react";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const ProductAcordion = (props) => {
    const { element, index } = props;

    const descriptionFunction = (ift_description) => {
        const ift_description_array = ift_description.split("\r\n");

        return ift_description_array.map((elemento, clave) => {
            return (
                <span key={clave}>
                    {" "}
                    {elemento}
                    <br />
                </span>
            );
        });
    };

    return element ? (
        <Accordion eventKey={index}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id={"accordion_item_" + index}
                className="row"
            >
                <Typography className="accordion-title">
                    {element.name}
                </Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Typography>
                    {descriptionFunction(element.ift_description)}
                </Typography>
                <Typography>{element.ift_limitations}</Typography>
            </AccordionDetails>
        </Accordion>
    ) : null;
};

export default ProductAcordion;
