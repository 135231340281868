export const gridContainer = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

export const titleContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const typographyTitleOne = {
    fontSize: { xs: 36, md: 64 },
    color: "white",
    lineHeight: { xs: 1.5, md: 1.2 },
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 40, md: 72 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: "start",
};

export const typographyDescription = {
    mt: { xs: 2, md: 0 },
    fontSize: 16,
    mb: 3,
    textAlign: { xs: "start", md: "center" },
    lineHeight: { xs: 1.7, md: 1.6 },
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};

export const formContainer = {
    mt: { xs: 4, md: 0 },
    mb: { xs: 3, md: 0 },
    backgroundColor: "white",
    borderRadius: 7,
    px: { xs: 3, md: 8 },
    pt: { xs: 3, md: 5 },
    pb: { xs: 0.5, md: 3 },
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};

export const retryContainer = {
    fontFamily: "Poppins-Regular",
    fontSize: 14,
    color: "purple",
    backgroundColor: "#f2ebff",
    textTransform: "none",
    mt: 1.5,
    "&:hover": {
        backgroundColor: "#e0d5f3",
    },
};

export const buttonComponent = {
    mt: 2,
    mb: 2,
};
