import axiosAuthInstance from "../../../services/api/authentication";
import { VERIFY_PIN_GIFT, VERIFY_PIN_GIFT_ERROR } from "_models/redux/types";

// Función de acción

export const verifyPinGiftAction = (params) => async (dispatch) => {
    const { token, verification_code } = params || "";
    console.log("verifyPinGiftAction -> params", params);
    try {
        const response = await axiosAuthInstance.patch(
            `api/leads/steps/gift/code-verification`,
            { verification_code },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const { data, status } = response || {};

        dispatch({
            type: VERIFY_PIN_GIFT,
            payload: data || {},
            status: status || {},
            method: "patch",
        });
    } catch (e) {
        const resp = e || {};
        dispatch({
            type: VERIFY_PIN_GIFT_ERROR,
            payload: { ...resp, valid: false },
        });
    }
};

export const resendPinGiftAction = (params) => async (dispatch) => {
    const { notificationType, token } = params || "";
    try {
        const response = await axiosAuthInstance.post(
            `api/leads/steps/gift/verification-codes/re-send`,
            { notification_type: notificationType },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response;
    } catch (e) {
        return e;
    }
};
