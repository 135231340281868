import React from "react";

import { Grid, CircularProgress } from "@mui/material";

const HelperLoaderPageComponent = (props) => {
    const { color } = props || {};

    return (
        <Grid item xs={12} className="page-loader">
            <CircularProgress className={color || ""} />
        </Grid>
    );
};

export default HelperLoaderPageComponent;
