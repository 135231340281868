export const containerSuccessPage = {
    backgroundColor: "white",
    py: { xs: 4, md: 7 },
    background:
        "transparent linear-gradient(180deg, #E8E8F9 0%, #FEFEFE 100%) 0% 0% no-repeat padding-box",
};

export const typographyTitleSuccess = {
    color: "#4F3078",
    fontSize: { xs: 28, md: 40 },
    fontWeight: "bold",
    fontFamily: "Poppins-Bold",
    mb: 3,
};

export const typographySubTitleSuccess = { fontSize: 16, mb: { xs: 3, md: 4 } };

export const gridContainerInfoProduct = {
    background: "#FAF8FF 0% 0% no-repeat padding-box",
    borderRadius: "25px",
    border: "1px solid #E6E4EF",
    py: 4,
    color: "#3A2951",
    mb: 4,
};

export const gridItemInfoProduct1 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const typographyNameProduct = {
    fontSize: { xs: 25, md: 28 },
    fontWeight: "bold",
    fontFamily: "Poppins-Bold",
};

export const typographyPriceProduct = {
    fontSize: { xs: 60, md: 81 },
    my: 1,
    fontWeight: "bold",
    fontFamily: "Poppins-ExtraBold",
};

export const typographyValidityProduct = {
    fontWeight: "bold",
    fontSize: 18,
    fontFamily: "Poppins-Bold",
};

export const gridItemInfoPayment = { mt: { xs: 0, md: 2 } };

export const typographyValueOfItem = {
    fontSize: 17,
    textAlign: "right",
    fontWeight: "bold",
    fontFamily: "Poppins-SemiBold",
};

export const gridContainerDetailsProduct = {
    mt: 3,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    px: 3,
};

export const typographyTitleDetail = {
    fontSize: 15,
    fontFamily: "Poppins-Medium",
    textAlign: "left",
};

export const gridItemButtonInvoice = {
    mt: 3,
    display: "flex",
    justifyContent: "center",
};

export const gridContainerButtonBackToHome = {
    display: "flex",
    justifyContent: "center",
};
