import { useReducer } from "react";

import { useFormValues } from "./invoiceUseformMethods";

import { useInvoiceReducer } from "../data/useInvoiceReducer";

export const useInvoiceStep1 = (props) => {
    //Form Library Method
    const { formValues } = useFormValues();

    //Redux Method and Props
    const { stepOneInvoice, stepOneInvoiceAction } = props || {};

    const initialInvoiceDataStep1 = {
        formValues,
    };

    const [invoiceDataStep1, dispatchInvoiceDataStep1] = useReducer(
        useInvoiceReducer,
        initialInvoiceDataStep1
    );

    const invoiceStep1 = {
        invoiceDataStep1,
        dispatchInvoiceDataStep1,
        stepOneInvoice,
        stepOneInvoiceAction,
    };

    return { invoiceStep1 };
};
