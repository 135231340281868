import { useContext, useEffect } from "react";

import { Grid } from "@mui/material";

import { PrepaidAuthnInvoiceContext } from "../data/contexts/prepaidAuthnInvoiceContext";

export const usePrepaidAuthnInvoiceForm = () => {
    const prepaidAuthnInvoiceContext = useContext(PrepaidAuthnInvoiceContext);
    const { variables_, id_transaction } = prepaidAuthnInvoiceContext || {};
    const { reduxValues, reducerValues, formInputs, formInit } =
        variables_ || {};

    const { postPrepaidAuthnInvoice, postPrepaidAuthnInvoiceAction } =
        reduxValues || {};

    const { prepaidAuthnReducer, dispatchPrepaidAuthnReducer } =
        reducerValues || {};
    const { loading, submmited, responseComponent, legalStoredSelected } =
        prepaidAuthnReducer || {};

    const { legalInputs, legalInputsRegistered } = formInputs || {};

    const { handleSubmit, register, control, errors } = formInit || {};

    const onSubmit = (e) => {
        const {
            legal_id,
            legal_name,
            legal_email,
            legal_zipcode,
            legal_tax_regime_id,
            legal_cfdi_use_id,
            legal_card_type_id,
        } = e || {};

        dispatchPrepaidAuthnReducer({
            property: "responseComponent",
            value: undefined,
        });

        dispatchPrepaidAuthnReducer({
            property: "submmited",
            value: true,
        });

        if (
            legalStoredSelected != 0 ||
            (legal_tax_regime_id != 0 &&
                legal_cfdi_use_id != 0 &&
                legal_card_type_id != 0)
        ) {
            dispatchPrepaidAuthnReducer({
                property: "loading",
                value: true,
            });

            const prepaid_id = id_transaction || null;
            //TODO: Change this to the dynamic value:
            const store_legal_data = false;

            if (typeof postPrepaidAuthnInvoiceAction === "function") {
                let post_data = {};

                if (legalStoredSelected !== 0 && legalStoredSelected !== 1) {
                    post_data = {
                        legal_id: legalStoredSelected,
                        prepaid_id: parseInt(prepaid_id),
                        payment_method_id: legal_card_type_id,
                    };
                } else {
                    post_data = {
                        prepaid_id: parseInt(prepaid_id),
                        payment_method_id: legal_card_type_id,
                        invoice: {
                            receiver: {
                                legal_id: legal_id,
                                legal_name: legal_name,
                                email: legal_email,
                                zipcode: legal_zipcode,
                                tax_regime: legal_tax_regime_id,
                                cfdi_use_id: legal_cfdi_use_id,
                                store_legal_data,
                            },
                        },
                    };
                }

                postPrepaidAuthnInvoiceAction({ post_data });
            }
        }
    };

    const responseComponentHandler = ({ type, title, detail }) => {
        if (type === "success") {
            return (
                <Grid container>
                    <Grid item xs={12} className="success-incidence">
                        {title}
                    </Grid>
                    <Grid item xs={12} className="success-incidence">
                        {detail}
                    </Grid>
                </Grid>
            );
        } else if (type === "error") {
            return (
                <Grid container>
                    <Grid item xs={12} className="required-incidence">
                        {title}
                    </Grid>
                    <Grid item xs={12} className="required-incidence">
                        {detail}
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        {title}
                    </Grid>
                    <Grid item xs={12}>
                        {detail}
                    </Grid>
                </Grid>
            );
        }
    };

    useEffect(() => {
        const { loaded, payload, state, status } =
            postPrepaidAuthnInvoice || {};

        if (state === "error") {
            const { title, detail } = payload || {};

            dispatchPrepaidAuthnReducer({
                property: "loading",
                value: false,
            });

            dispatchPrepaidAuthnReducer({
                property: "responseComponent",
                value: responseComponentHandler({ title, detail }),
            });
        } else if (state === "success") {
            const { links } = payload || {};

            dispatchPrepaidAuthnReducer({
                property: "loading",
                value: false,
            });

            dispatchPrepaidAuthnReducer({
                property: "responseComponent",
                value: responseComponentHandler({
                    type: "success",
                    title: "Éxito",
                    detail: "Se ha realizado la factura correctamente",
                }),
            });

            //TODO: Redirect to success page
        }
    }, [postPrepaidAuthnInvoice]);

    return {
        onSubmit,
        legalInputs,
        legalInputsRegistered,
        handleSubmit,
        register,
        control,
        errors,
        loading,
        submmited,
        responseComponent,
    };
};
