import { useState, useEffect } from "react";

//React Router Dom:
import { useNavigate, useLocation } from "react-router-dom";

//UseForm:
import { useForm } from "react-hook-form";

//Moment:
import moment from "moment";

export function useInvoiceYourPrepaidHook(props) {
    let navigate = useNavigate();
    const { state } = useLocation();

    const { prepaid } = state || {};

    const amount = state?.amount || prepaid?.amount;
    const product_name = state?.product_name || prepaid?.product_name;
    const prepaid_id = state?.id || prepaid?.prepaid_id;
    const operation_date = state?.created || prepaid?.operation_date;

    const {
        myLegals,
        getMyLegalsAction,
        cleanMyLegalsAction,
        invoiceAutentification,
        invoiceAutentificationAction,
        cleanInvoiceAutentificationAction,
    } = props || {};

    const { payload } = myLegals || {};
    const { legal_ids } = payload || {};

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dateOfOperation, setDateOfOperation] = useState("");
    const [selectRFC, setSelectRFC] = useState("");
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({});

    const handleChangeGenerateInvoice = (event) => {
        const { value } = event.target;

        if (value === "New invoice data") setSelectRFC(value);
        else {
            setSelectRFC(value);
            const fields = [
                "cfdi_use_id",
                "legal_id",
                "legal_name",
                "tax_regime",
                "zipcode",
                "email",
            ];
            fields.forEach((field) =>
                setValue(field, event.target.value[field])
            );
        }
    };

    const postData = async (dataForm) => {
        setLoading(true);
        const payload = {
            prepaid_id: prepaid_id,
            payment_method_id: dataForm.payment_method_id,
            invoice: {
                receiver: {
                    legal_id: dataForm.legal_id,
                    legal_name: dataForm.legal_name,
                    tax_regime: dataForm?.tax_regime,
                    zipcode: dataForm.zipcode,
                    cfdi_use_id: dataForm.cfdi_use_id,
                    email: dataForm.email,
                    store_legal_data: dataForm.store_legal_data || false,
                },
            },
        };

        try {
            await invoiceAutentificationAction(payload);
        } catch (error) {
            console.error("error", error);
        } finally {
            setLoading(false);
            console.log("finally");
        }
    };

    useEffect(() => {
        if (invoiceAutentification?.status === 200) {
            navigate(`/paquete/facturar/download`, {
                state: invoiceAutentification.payload,
            });
        } else if (invoiceAutentification?.status > 200) {
            setIsDialogOpen(true);
        }
    }, [invoiceAutentification]);

    useEffect(() => {
        if (state?.id) {
            setDateOfOperation(moment.unix(operation_date).format("DD/MM/YY"));
        } else {
            const date = new Date(operation_date);
            setDateOfOperation(
                date.toLocaleDateString("es-MX", {
                    year: "2-digit",
                    month: "2-digit",
                    day: "2-digit",
                })
            );
        }
        getMyLegalsAction();

        return () => {
            cleanMyLegalsAction();
        };
    }, [operation_date, cleanMyLegalsAction, getMyLegalsAction]);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (!legal_ids) setSelectRFC("New invoice data");

        return () => {
            cleanInvoiceAutentificationAction();
        };
    }, []);

    const valuesMetodo = [
        {
            id: "03",
            name: "Transferencia electrónica de fondos",
        },
        {
            id: "04",
            name: "Tarjeta de crédito",
        },
        {
            id: "28",
            name: "Tarjeta de débito",
        },
    ];

    return {
        selectRFC,
        valuesMetodo,
        postData,
        handleChangeGenerateInvoice,
        product_name,
        amount,
        dateOfOperation,
        legal_ids,
        invoiceAutentification,
        isDialogOpen,
        setIsDialogOpen,
        register,
        handleSubmit,
        watch,
        errors,
        loading,
    };
}
