import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { usePixels } from "./usePixels";
import { NOTIFICATIONS_TYPES } from "_models/redux/types";
const INITIAL_INTERVAL_STATE = { minutes: 2, seconds: 0 };

export const useCardVerification = ({
    verifyPinGiftAction,
    verifyPinGift,
    resendPinGiftAction,
    cleanVerifyPinGift,
    setResetPin,
}) => {
    const { payload } = verifyPinGift || {};
    const { valid, response } = payload || {};
    const statusCode = response?.status;
    const [intervalState, setIntervalState] = useState(INITIAL_INTERVAL_STATE);
    const [showReenviar, setShowReenviar] = useState(false);
    const [showMethods, setShowMethods] = useState(false);
    const [method, setMethod] = useState(NOTIFICATIONS_TYPES.SMS);
    const [modalMessage, setModalMessage] = useState("");
    const { pixelCompleteRegistration } = usePixels();

    const location = useLocation();
    const navigate = useNavigate();
    const tokenLead = location.state?.tokenLead || "";
    const [status, setStatus] = useState("process");
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        if (valid === true) {
            setStatus("success");
            pixelCompleteRegistration(tokenLead);
            navigate("/esim-gift/email", {
                state: {
                    tokenLead: tokenLead,
                },
            });
            cleanVerifyPinGift();
        } else if (valid === false) {
            setStatus("error");
        } else {
            setStatus("process");
        }
    }, [payload, navigate]);

    useEffect(() => {
        if (statusCode >= 300) {
            console.log("response", response);

            if (
                response?.data?.context ===
                "verification_code_invalid_or_expired"
            ) {
                setStatus("error");
                setResetPin((prev) => !prev);
            } else if (
                response?.data?.context === "lead_closed_error" &&
                statusCode === 409
            ) {
                navigate("/esim-gift-error", {
                    state: {
                        title: response?.data?.title,
                        responseMessage: response?.data?.detail,
                    },
                });
            } else {
                setOpenModal(true);
                setModalMessage(response?.data?.detail);
            }
        }
    }, [statusCode, response, navigate, tokenLead, setResetPin]);

    useEffect(() => {
        if (showReenviar) return;

        const countdown = setInterval(() => {
            setIntervalState((prevInterval) => {
                const { minutes, seconds } = prevInterval;
                if (seconds > 0) {
                    return { minutes, seconds: seconds - 1 };
                } else if (minutes > 0) {
                    return { minutes: minutes - 1, seconds: 59 };
                } else {
                    clearInterval(countdown);
                    setShowReenviar(true);
                    return { minutes: 0, seconds: 0 };
                }
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, [showReenviar]);

    const handleOnFinish = (code) => {
        setStatus("wait");
        verifyPinGiftAction({ verification_code: code, token: tokenLead });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    };

    const handleSetMethod = (method) => {
        setMethod(method);
    };

    const handleForward = (notificationType) => {
        if (!showReenviar) return;
        setIntervalState(INITIAL_INTERVAL_STATE);
        setShowReenviar(false);
        setShowMethods(false);
        resendPinGiftAction({ notificationType, token: tokenLead });
    };

    const hanldeTryAgain = () => {
        setShowMethods(true);
    };

    const handleCloseMethods = () => {
        setShowMethods(false);
    };

    const handleClose = () => {
        setOpenModal(false);
        if (statusCode === 401) {
            navigate("/esim-gift");
        }
    };
    return {
        status,
        handleOnFinish,
        handleSubmit,
        handleForward,
        handleClose,
        openModal,
        statusCode,
        intervalState,
        showReenviar,
        hanldeTryAgain,
        showMethods,
        method,
        handleSetMethod,
        modalMessage,
        handleCloseMethods,
    };
};
