import {
    PRODUCTITEM,
    PRODUCTITEM_ERROR,
    SET_LOADING_UUID,
} from "_models/redux/types";
import axiosTokenInstance from "services/api/service-client";

export const productItemAction = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/v2/prepaids/offers`
        );

        const { data } = response;

        dispatch({
            type: PRODUCTITEM,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: PRODUCTITEM_ERROR,
            payload: e || {},
        });
    }
};

export const setLoadingUUIDAction = (params) => async (dispatch) => {
    dispatch({
        type: SET_LOADING_UUID,
        payload: params,
    });
};
