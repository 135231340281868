import { ACTIVE_OFFERS, ACTIVE_OFFERS_ERROR } from "_models/redux/types";

const initialState = {
    activeOffersdObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACTIVE_OFFERS:
            return {
                ...state,
                activeOffersdObj: action.payload,
                loading: true,
            };
        case ACTIVE_OFFERS_ERROR:
            return {
                ...state,
                activeOffersdObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
