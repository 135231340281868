import { Route, Routes } from "react-router-dom";

import PortalPage from "modules/portal/page";
import HistoryListComponent from "modules/portal/pages/history/components/HistoryListComponent";
import MyCardsPage from "modules/portal/pages/my-cards/page";
import MyLegalsPage from "modules/portal/pages/my-legals/page";
import PaymentErrorComponent from "modules/portal/pages/payment/components/PaymentErrorComponent";
import PaymentSuccessPage from "modules/portal/pages/payment/components/SuccessPageComponent";
import PortabilityPage from "modules/portal/pages/portability/page";
import PromotionsPage from "modules/portal/pages/promotions/page";
import AdminSuscription from "modules/portal/pages/suscription/suscription";
import SupportPage from "modules/support/page";
import TicketPage from "modules/support/tickets/page";
import TroubleshootingPage from "modules/troubleshooting/page";
import ProductsPage from "../../modules/portal/pages/products/page";
import WbcPurchasePageComponent from "modules/portal/pages/wbc-purchase/WbcPurchasePageComponent";

import ModalUpdateClientDataPage from "modules/portal/pages/modal-update-client-data/page";

import LogoutPage from "modules/logout/page";
import WbcPrepaidPage from "modules/portal/pages/wbc-prepaid/page";
import InvoicePage from "modules/invoice-landing/invoicePage";

import PrepaidAuthnInvoicePage from "modules/prepaid-authn-invoice/PrepaidAuthnInvoicePage";
import InvoiceYourPrepaidComponent from "modules/portal/pages/invoice/InvoiceYourPrepaidComponent";
import InvoiceDownloadPrepaidComponent from "modules/portal/pages/invoice/InvoiceDownloadPrepaidComponent";
import PendingComponent from "modules/portal/pages/payment/components/PendingComponent";
import { MyPaymentMethodsPage } from "modules/portal/my-payment-methods/pages/MyPaymentMethodsPage";

const SubnavigationRoutes = () => {
    return (
        <div className="subnavigation-routes">
            <Routes>
                <Route path="/inicio" element={<PortalPage />} />
                <Route
                    path="/troubleshooting"
                    element={<TroubleshootingPage />}
                />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/incidencias" element={<TicketPage />} />
                <Route path="/historial" element={<HistoryListComponent />} />
                <Route path="/portabilidad" element={<PortabilityPage />} />

                <Route
                    path="/admin-suscription"
                    element={<AdminSuscription />}
                />

                <Route path="/mis-tarjetas" element={<MyCardsPage />} />
                <Route
                    path="/mis-metodos-de-pago"
                    element={<MyPaymentMethodsPage />}
                />
                <Route path="/mis-datos-fiscales" element={<MyLegalsPage />} />
                <Route path="/promociones" element={<PromotionsPage />} />

                <Route path="/paquetes" element={<ProductsPage />} />

                <Route
                    path="/modal-update-data"
                    element={<ModalUpdateClientDataPage />}
                />

                <Route
                    path="/authn-invoice/prepaid"
                    element={<PrepaidAuthnInvoicePage />}
                />

                <Route
                    path="/paquete/success"
                    element={<PaymentSuccessPage />}
                />
                <Route
                    path="/paquete/error"
                    element={<PaymentErrorComponent />}
                />
                <Route path="/paquete/pending" element={<PendingComponent />} />

                <Route
                    path="/completado/paquete/:uuid"
                    element={<WbcPrepaidPage />}
                />

                <Route
                    path="/paquete/facturar"
                    element={<InvoiceYourPrepaidComponent />}
                />

                <Route
                    path="/paquete/facturar/download"
                    element={<InvoiceDownloadPrepaidComponent />}
                />

                <Route
                    path="/completado/compra/:uuid"
                    element={<WbcPurchasePageComponent flow="" />}
                />

                <Route path="/logout" element={<LogoutPage />} />

                <Route path="/factura" element={<InvoicePage />} />

                <Route path="/*" element={<PortalPage />} />
            </Routes>
        </div>
    );
};

export default SubnavigationRoutes;
