import { useContext, useEffect } from "react";

import { Grid } from "@mui/material";

import { LeadContext } from "./data/contexts/leadContext";

import LeadContentComponent from "./components/LeadContentComponent";
import LeadNotFoundComponent from "./components/LeadNotFoundComponent";
import HelperLoaderPageComponent from "utils/helpers/loaders/pages/helper-loader-page-component";

import CustomRecaptchaComponent from "./components/CustomRecaptchaComponent";

import Footer from "modules/portal/components/footer";
import BackdropLoader from "utils/components/loader/BackdropLoader";
import CustomDialog from "utils/helpers/genericModal/modal";

const LeadPresentational = () => {
    const leadContext = useContext(LeadContext);
    const {
        leadData,
        recaptchaContainerProps,
        gralLoaderOpen,
        setGralLoaderOpen,
        setCustomModalClose,
    } = leadContext || {};
    const { pageLoaded } = leadData || {};

    const { setCallActionFlag, reCaptchaAction, setReCaptchaAction } =
        recaptchaContainerProps || {};

    const {
        customModalOpen,
        customModalResponseTitle,
        customModalResponseContent,
    } = leadData || {};

    useEffect(() => {
        setReCaptchaAction("recover_leads_details");
    }, []);

    useEffect(() => {
        if (reCaptchaAction === "recover_leads_details") {
            setCallActionFlag(true);
        }
    }, [reCaptchaAction]);

    return (
        <Grid container className={"lead-wrapper"}>
            <Grid item xs={12}>
                {pageLoaded ? (
                    <LeadContentComponent />
                ) : pageLoaded === false ? (
                    <LeadNotFoundComponent />
                ) : (
                    <HelperLoaderPageComponent color={"white"} />
                )}
            </Grid>
            <Grid item xs={12}>
                {" "}
                <Footer />
            </Grid>
            <CustomRecaptchaComponent
                containerProps={recaptchaContainerProps}
            />

            <CustomDialog
                title={customModalResponseTitle}
                content={customModalResponseContent}
                open={customModalOpen}
                onClose={() => setCustomModalClose(false)}
                backgroundColor="#ffffff"
            />
            <BackdropLoader open={gralLoaderOpen} setOpen={setGralLoaderOpen} />
        </Grid>
    );
};

export default LeadPresentational;
