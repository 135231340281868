import React from "react";
import { Container, Grid } from "@mui/material";

const HistoryHeader = (props) => {
    return (
        <div className={"header"}>
            <Container>
                <Grid container className="history-header">
                    <Grid item xs={12} className="history-text">
                        <h1>
                            Tus últimas <span>compras</span>
                        </h1>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default HistoryHeader;
