import React from "react";

//Css:
import "../../../../../assets/styles/css/portal/pages/payment/components/success-page.css";

//Redux:
import { connect } from "react-redux";

//Redux Actions:
import { cleanPaymentAction } from "_models/redux/payment/clean";

//Material UI:
import { Grid, Container, Typography, Box } from "@mui/material";

//Styles MUI:
import {
    containerSuccessPage,
    gridContainerButtonBackToHome,
    gridContainerDetailsProduct,
    gridContainerInfoProduct,
    gridItemButtonInvoice,
    gridItemInfoPayment,
    gridItemInfoProduct1,
    typographyNameProduct,
    typographyPriceProduct,
    typographySubTitleSuccess,
    typographyTitleDetail,
    typographyTitleSuccess,
    typographyValidityProduct,
    typographyValueOfItem,
} from "./styles-mui/successPageStylesMUI";

//Images:
import imgSuccessPayment from "assets/images/portal/pages/payment/components/success-page/success-payment.png";

//Hooks:
import useSuccessPageHook from "./hooks/useSuccessPageHook";

//Components:
import PortalNavigation from "modules/portal/components/navigation";
import PortalFooter from "modules/portal/components/footer";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const PaymentSuccessPage = (props) => {
    const {
        product_name,
        amount,
        product_duration,
        infoProduct,
        state,
        routeSuccess,
        navigate,
    } = useSuccessPageHook(props);

    return (
        <>
            <PortalNavigation />
            <Container
                sx={containerSuccessPage}
                maxWidth={false}
                className="container-success-page"
            >
                <Container maxWidth="xs" sx={{ textAlign: "center" }}>
                    <Box sx={{ mb: 2 }}>
                        <img
                            className="img-success-payment"
                            src={imgSuccessPayment}
                            alt="Persona con los ojo iluminados por estrellas dando a entender que está asombrado"
                        />
                    </Box>

                    <Typography variant="h1" sx={typographyTitleSuccess}>
                        ¡Recarga exitosa!
                    </Typography>

                    <Typography sx={typographySubTitleSuccess}>
                        Dalelover, tu recarga se ha realizado con éxito
                    </Typography>

                    <Grid container sx={gridContainerInfoProduct}>
                        <Grid item xs={12} sx={gridItemInfoProduct1}>
                            <Box>
                                <Typography
                                    variant="h3"
                                    sx={typographyNameProduct}
                                >
                                    {product_name}
                                </Typography>
                                <Typography
                                    variant="h2"
                                    sx={typographyPriceProduct}
                                >
                                    ${Number(amount).toLocaleString()}
                                </Typography>
                                <Typography sx={typographyValidityProduct}>
                                    Vigencia {product_duration} días
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={gridItemButtonInvoice}>
                            <Box sx={{ width: "60%" }}>
                                <CustomButtonComponent
                                    text={"Factura tu recarga"}
                                    bgColor={"pink"}
                                    height={"50px"}
                                    onClick={() =>
                                        navigate(`/paquete/facturar`, {
                                            state: state,
                                        })
                                    }
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={gridItemInfoPayment}>
                            {infoProduct.map((item, index) => (
                                <Grid
                                    container
                                    sx={gridContainerDetailsProduct}
                                    key={index}
                                >
                                    <Grid
                                        item
                                        xs={7}
                                        sx={{ textAlign: "left" }}
                                    >
                                        <Typography sx={typographyTitleDetail}>
                                            {item.title}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography sx={typographyValueOfItem}>
                                            {item.value}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>

                    <Grid container sx={gridContainerButtonBackToHome}>
                        <Grid item xs={8}>
                            <CustomButtonComponent
                                text={"Volver a inicio"}
                                bgColor={"violet"}
                                height={"50px"}
                                onClick={routeSuccess}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <PortalFooter />
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    payment: state.payment,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    cleanPaymentAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSuccessPage);
