export const generalStyleMui = {
    width: "100%",
    textAlign: "center",
    marginTop: "60px",
    marginBottom: "75px",
};

export const semibold = {
    fontWeight: "600",
    fontFamily: "Poppins-Medium",
};

export const bold = {
    fontWeight: "700",
    fontFamily: "Poppins-Bold",
};

export const mainContainer = {
    py: "45px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
};

export const modalStyle = {
    Button: {
        fontWeight: "600",
        textTransform: "capitalize",
        color: "#676568",
    },
    ".modal-title": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: 16,
        ...semibold,
        gap: 1,
        mt: 1,
    },
    ".modal-actions": {
        p: 2,
        display: "flex",
        justifyContent: "space-around",
        color: "#676568",
    },
};
