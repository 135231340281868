// // Import: Middleware Action & Custom Types
// import MiddlewareClass from "services/MiddlewareClass";
import { NEWPASSWORD, NEWPASSWORD_ERROR } from "_models/redux/types";

import axiosAuthInstance from "../../../services/api/authentication";

export const setPasswordAction = (params) => async (dispatch) => {
    const { direct_number, pin, new_password, recaptcha_token } = params || {
        direct_number: undefined,
    };

    try {
        const response = await axiosAuthInstance.post(
            `/api/v2/clients/${direct_number}/set-password`,
            { pin: pin, new_password, recaptcha_token }
        );

        const { data } = response;

        dispatch({
            type: NEWPASSWORD,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: NEWPASSWORD_ERROR,
            payload: e || {},
        });
    }
};
