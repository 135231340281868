import { LOGINACCOUNT, LOGINLOGINACCOUNT_ERROR } from "_models/redux/types";
import axiosAuthInstance from "../../../services/api/authentication";

export const loginAccountsAction = (params) => async (dispatch) => {
    try {
        const response = await axiosAuthInstance.post(
            `/api/v3/clients/accounts`,
            params
        );

        dispatch({
            type: LOGINACCOUNT,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: LOGINLOGINACCOUNT_ERROR,
            payload: e || {},
        });
    }
};
