import {
    SHIPPINGADDRESS,
    SHIPPINGADDRESS_ERROR,
    SHIPPINGADDRESS_CLEAN,
    SHIPPINGADDRESS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    shippingAddressObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SHIPPINGADDRESS:
            return {
                ...state,
                shippingAddressObj: action.payload,
                loading: true,
            };
        case SHIPPINGADDRESS_ERROR:
            return {
                ...state,
                shippingAddressObj: action.payload,
                loading: false,
            };
        case SHIPPINGADDRESS_CLEAN:
        case SHIPPINGADDRESS_CLEAN_ERROR:
            return {
                ...state,
                shippingAddressObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
