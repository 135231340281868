import { useState, useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useFormInitHelper } from "utils/helpers/forms/useFormInitHelper";

import { prepaidAuthnInvoiceReducer } from "../data/reducers/prepaidAuthnInvoiceReducer";

import { prepaidAuthnInvoiceFormInputs } from "../data/inputs/prepaidAuthnInvoiceFormInputs";

import { prepaidAuthnInvoiceFormVariables } from "../data/variables/prepaidAuthnInvoiceFormVariables";
import { usePrepaidAuthnInvoiceHandlers_ } from "./usePrepaidAuthnInvoiceHandlers_";

export const usePrepaidAuthnInvoiceVariables = (props) => {
    /*** REDUX Data ***/
    const {
        getStoredLegals,
        getStoredLegalsAction,
        cleanGetStoredLegalsAction,

        postPrepaidAuthnInvoice,
        postPrepaidAuthnInvoiceAction,
        cleanPostPrepaidAuthnInvoiceAction,

        listRFC,
        listRFCAction,
        cleanGetListRfcAction,
    } = props || {};

    const reduxValues = {
        getStoredLegals,
        getStoredLegalsAction,
        cleanGetStoredLegalsAction,

        postPrepaidAuthnInvoice,
        postPrepaidAuthnInvoiceAction,
        cleanPostPrepaidAuthnInvoiceAction,

        listRFC,
        listRFCAction,
        cleanGetListRfcAction,
    };

    /*** REDUCER Data ***/
    const initReducer = {
        loading: false,
        submmited: false,

        cdfiUseOptionSelected: 0,
        taxRegimeOptionSelected: 0,
        cardTypeOptionSelected: 0,

        legalStoredSelected: 0,
        legalStoredOptions: [],

        responseComponent: undefined,
    };

    const [prepaidAuthnReducer, dispatchPrepaidAuthnReducer] = useReducer(
        prepaidAuthnInvoiceReducer,
        initReducer
    );

    const reducerValues = { prepaidAuthnReducer, dispatchPrepaidAuthnReducer };

    const {
        cdfiUseOptionSelected,
        taxRegimeOptionSelected,
        cardTypeOptionSelected,

        legalStoredSelected,
    } = prepaidAuthnReducer || {};

    /*** FORM Data ***/
    const { formInit } = useFormInitHelper();
    const { formVariables } = prepaidAuthnInvoiceFormVariables();
    /*** FORM Inputs Data ***/
    const { formInputs } = prepaidAuthnInvoiceFormInputs({
        formVariables,
        optionsSelected: {
            cdfiUseOptionSelected,
            taxRegimeOptionSelected,
            cardTypeOptionSelected,

            legalStoredSelected,
        },
        handlers: usePrepaidAuthnInvoiceHandlers_({
            dispatchPrepaidAuthnReducer,
        }),
    });

    useEffect(() => {
        listRFCAction();
    }, []);
    useEffect(() => {
        if (listRFC?.listRFCObj) {
            const { listRFCObj } = listRFC || {};
            const { legal_ids } = listRFCObj || {};

            const options = legal_ids?.map((item) => {
                return {
                    id: item.legal_id,
                    name: item.legal_name,
                };
            });

            options?.push({ id: 1, name: "Otro" });

            //TODO: Agregar como 1era opción del dropdown
            options?.push({ id: 0, name: "Seleccione una opción" });

            dispatchPrepaidAuthnReducer({
                property: "legalStoredOptions",
                value: options,
            });
        }
    }, [listRFC]);

    const variables_ = {
        reduxValues,
        reducerValues,

        formInit,
        formVariables,
        formInputs,
    };

    return { variables_ };
};
