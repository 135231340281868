export const getTaxRegimeOptions = () => {
    return {
        taxRegimeOptions: [
            { id: "0", name: "Seleccione una opción" },
            { id: "601", name: "601 - General de Ley Personas Morales" },
            {
                id: "603",
                name: "603 - Personas Morales con Fines no Lucrativos",
            },
            { id: "606", name: "606 - Arrendamiento" },
            {
                id: "612",
                name: "612 - Personas Físicas con Actividades Empresariales y Profesionales",
            },
            {
                id: "620",
                name: "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            },
            { id: "621", name: "621 - Incorporación Fiscal" },
            {
                id: "622",
                name: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            },
            { id: "623", name: "623 - Opcional para Grupos de Sociedades" },

            { id: "624", name: "624 - Coordinados" },
            {
                id: "625",
                name: "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            },
            { id: "626", name: "626 - Régimen Simplificado de Confianza" },
        ],
    };
};
