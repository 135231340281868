import {
    CLEAN_POST_LEAD_CHECK_IMEI,
    CLEAN_POST_LEAD_CHECK_IMEI_ERROR,
} from "_models/redux/types";

export const cleanPostLeadCheckImeiAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_POST_LEAD_CHECK_IMEI,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_POST_LEAD_CHECK_IMEI_ERROR,
            payload: [],
        });
    }
};
