// Import: Middleware Action & Custom Types
import {
    CLEAN_CHECK_ZIP_CODE_GIFT,
    CLEAN_CHECK_ZIP_CODE_GIFT_ERROR,
} from "_models/redux/types";

// Función de acción
export const cleanCheckZipCodeGift = () => async (dispatch) => {
    // Endpoint: Try & Catch
    try {
        dispatch({
            type: CLEAN_CHECK_ZIP_CODE_GIFT,
            payload: undefined,
        });
    } catch (e) {
        dispatch({
            type: CLEAN_CHECK_ZIP_CODE_GIFT_ERROR,
            payload: undefined,
        });
    }
};
