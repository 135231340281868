import { getCfdiUseOptions } from "utils/helpers/legal-options/legalCfdiUseOptions";
import { getTaxRegimeOptions } from "utils/helpers/legal-options/legalTaxesRegimeOptions";
import { getCardTypeOptions } from "utils/helpers/legal-options/legalCardTypeOptions";

import { getValidations } from "../validations/prepaidAuthnInvoiceFormValidations";

export const prepaidAuthnInvoiceFormVariables = () => {
    const formVariables = {
        ...getValidations(),
        ...getCfdiUseOptions(),
        ...getTaxRegimeOptions(),
        ...getCardTypeOptions(),
    };

    return { formVariables };
};
