import React from "react";
import {
    Box,
    TextField,
    Typography,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Grid,
} from "@mui/material";
import {
    inputCardStyle,
    pinContainer,
} from "modules/gift/components/gift-compatibility/card-zip-code/cardZipCodeStyleMUI";
import { CardGift } from "modules/gift/components/shared/CardGift";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const CardGoodCoverage = ({
    register,
    handleSubmit,
    errors,
    onSubmit,
    loading,
}) => {
    return (
        <CardGift>
            <Typography
                variant="body1"
                fontSize={{ xs: 18, md: 16 }}
                textAlign={{ xs: "center", md: "left" }}
            >
                Lo tienes todo para convertirte en un Dalelover. Por favor,
                ingresa tus datos.
            </Typography>
            <Typography
                variant="body1"
                mb={1}
                fontSize={13}
                mt={{ xs: "39px", md: "28px" }}
            >
                Nombre completo
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Nombres y apellidos"
                sx={inputCardStyle}
                {...register("fullName", {
                    required: "El nombre completo es requerido",
                })}
                error={Boolean(errors.fullName)}
                helperText={errors.fullName?.message}
                name="fullName"
            />
            <Typography variant="body1" mb={1} fontSize={13} mt={"21px"}>
                Número celular
            </Typography>
            <TextField
                fullWidth
                hiddenLabel
                variant="outlined"
                placeholder="Número celular"
                sx={inputCardStyle}
                {...register("phoneNumber", {
                    required: "El número celular es requerido",
                })}
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                name="phoneNumber"
            />

            <Grid item xs={12} sx={pinContainer}>
                <FormControl>
                    <FormLabel
                        sx={{
                            color: { xs: "#ffffff", md: "#262626" },
                            mb: { xs: 3, md: 2 },
                            fontSize: { xs: "18px", md: "16px" },
                        }}
                        id="demo-radio-buttons-group-label"
                    >
                        ¿Cómo deseas recibir tu código?
                    </FormLabel>
                    <RadioGroup
                        row
                        name="row-radio-buttons-group"
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="sms"
                    >
                        <FormControlLabel
                            value="sms"
                            control={
                                <Radio
                                    sx={{
                                        color: { xs: "#ffffff", md: "#aaa5b3" },
                                        "&.Mui-checked": {
                                            color: {
                                                xs: "#a361ff",
                                                md: "#a361ff",
                                            },
                                        },
                                    }}
                                />
                            }
                            label="Por SMS"
                            {...register("notificationType")}
                        />
                        <FormControlLabel
                            value="whatsapp"
                            control={
                                <Radio
                                    sx={{
                                        color: { xs: "#ffffff", md: "#aaa5b3" },
                                        "&.Mui-checked": {
                                            color: {
                                                xs: "#a361ff",
                                                md: "#a361ff",
                                            },
                                        },
                                    }}
                                />
                            }
                            label="Por Whatsapp"
                            {...register("notificationType")}
                        />
                    </RadioGroup>
                </FormControl>
            </Grid>

            <Box mt={{ xs: "70px", md: "32px" }}>
                <CustomButtonComponent
                    async={true}
                    bgColor={"primary-purple"}
                    text={"Continuar"}
                    height={55}
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                />
            </Box>
        </CardGift>
    );
};

export default CardGoodCoverage;
