const excludeUrl = ["localhost", "midalefon", "midalefon.mx"];
export const CAMPAINFIELDS = {
    channelId: "channel_id",
    campaingId: "campaing_id",
    referenceLead: "reference_lead",
    referer: "referer",
};
export const saveCampaingData = () => {
    const url = new URLSearchParams(window.location.search);
    const channelId = url.get("chid");
    const campaingId = url.get("cid");
    const referenceLead = url.get("r");
    const referer = document.referrer;

    if (channelId) {
        localStorage.setItem(CAMPAINFIELDS.channelId, channelId);
    }

    if (campaingId) {
        localStorage.setItem(CAMPAINFIELDS.campaingId, campaingId);
    }

    if (referenceLead) {
        localStorage.setItem(CAMPAINFIELDS.referenceLead, referenceLead);
    }
    const shouldIgnoreReferer = excludeUrl.some((e) => referer.includes(e));

    if (referer && !shouldIgnoreReferer) {
        localStorage.setItem("referer", referer);
    }

    return getCampaingData();
};

export const getCampaingData = () => {
    return {
        channelId: localStorage.getItem(CAMPAINFIELDS.channelId) ?? 1,
        campaingId: localStorage.getItem(CAMPAINFIELDS.campaingId) ?? 1,
        referenceLead: localStorage.getItem(CAMPAINFIELDS.referenceLead) ?? "",
        referer: localStorage.getItem(CAMPAINFIELDS.referer) ?? "",
    };
};

export const removeCampaingData = () => {
    localStorage.removeItem(CAMPAINFIELDS.channelId);
    localStorage.removeItem(CAMPAINFIELDS.campaingId);
    localStorage.removeItem(CAMPAINFIELDS.referenceLead);
    localStorage.removeItem(CAMPAINFIELDS.referer);
};

export const deleteOneCampaingData = (key) => {
    localStorage.removeItem(key);
};
