import React from "react";

// Redux:
import { connect } from "react-redux";

// Redux actions:
import { checkImeiAction } from "_models/redux/check-imei/action";
import { cleanCheckImeiAction } from "_models/redux/check-imei/clean";
import { initRegisterAction } from "_models/redux/initRegister/action";
import { listBrandsDevices } from "_models/redux/consult-brand-device/action";
import { listDevicesModels } from "_models/redux/consult-device-models/action";
import { postCheckingCompatibleDevice } from "_models/redux/select-devices/action";
import { cleanCheckingDeviceAction } from "_models/redux/select-devices/clean";

//Styles MUI:
import {
    gridItemContainerSeparator,
    typographySeparator,
} from "./styles-mui/checkCompatibilityImeiStylesMUI";

//Material UI:
import { Grid, Typography } from "@mui/material";

//Hooks:
import useCheckCompatibilityImeiHook from "./hooks/useCheckCompatibilityImeiHook";

//Components:
import CheckCardBrandAndModelComponent from "./components/CheckCardBrandAndModelComponent";
import CheckCardIMEIComponent from "./components/CheckCardIMEIComponent";
import BaseImeiCheckComponent from "utils/components/base-imei-check/BaseImeiCheckComponent";

const CheckCompatibilityImeiComponent = (props) => {
    const {
        register,
        handleSubmit,
        onSubmit,
        errors,
        response,
        responseErrors,
        isLoading,
        selectedBrandId,
        selectedModelId,
        handleBrandChange,
        handleModelChange,
        isBrandSelected,
        isModelsSelected,
        isCheckboxChecked,
        handleCheckboxChange,
        checkboxError,
        onSubmitByModel,
        createLeadLoading,
        initRegisterObj,
        imeiValue,
        setImeiValue,
        imeiLoading,
        verifyButtonClicked,
        registerOptions,
    } = useCheckCompatibilityImeiHook(props);

    return (
        <BaseImeiCheckComponent>
            <CheckCardBrandAndModelComponent
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                handleBrandChange={handleBrandChange}
                handleModelChange={handleModelChange}
                selectedBrandId={selectedBrandId}
                selectedModelId={selectedModelId}
                isBrandSelected={isBrandSelected}
                isModelsSelected={isModelsSelected}
                isCheckboxChecked={isCheckboxChecked}
                handleCheckboxChange={handleCheckboxChange}
                checkboxError={checkboxError}
                response={response}
                responseErrors={responseErrors}
                isLoading={isLoading}
                onSubmitByModel={onSubmitByModel}
                props={props}
                verifyButtonClicked={verifyButtonClicked}
            />

            <Grid item xs={12} md={1} sx={gridItemContainerSeparator}>
                <Typography sx={typographySeparator}>O</Typography>
            </Grid>

            <CheckCardIMEIComponent
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                register={register}
                registerOptions={registerOptions}
                errors={errors}
                imeiValue={imeiValue}
                setImeiValue={setImeiValue}
                response={response}
                responseErrors={responseErrors}
                createLeadLoading={createLeadLoading}
                initRegisterObj={initRegisterObj}
                imeiLoading={imeiLoading}
            />
        </BaseImeiCheckComponent>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    checkImei: state.checkImei,
    initRegister: state.initRegister,
    getBrandsDevices: state.getBrandsDevices,
    getDevicesModels: state.getDevicesModels,
    postCompatibleDevices: state.postCompatibleDevices,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    checkImeiAction,
    cleanCheckImeiAction,
    initRegisterAction,
    listBrandsDevices,
    listDevicesModels,
    postCheckingCompatibleDevice,
    cleanCheckingDeviceAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckCompatibilityImeiComponent);
