import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { profileUpdatedoAction } from "_models/redux/profile-updated/action";
import { cleanCheckImeiAction } from "_models/redux/profile-updated/clean";
import ModalUpdateClientDataComponent from "./components/component";

const ModalUpdateClientDataPage = (props) => {
    const {
        profileUpdated,
        setShowSuccessDialog,
        isLoading,
        setIsLoading,
        setIsDialogOpen,
    } = props || {};

    const { payload } = profileUpdated || {};

    useEffect(() => {
        return () => {
            props?.cleanCheckImeiAction();
        };
    }, []);

    useEffect(() => {
        if (payload && payload.message === "success") {
            localStorage.setItem("is_profile_updated", true);
            setShowSuccessDialog(true);
        }
    }, [payload]);

    const handleDataUpdate = (data) => {
        props.profileUpdatedoAction(data);
    };

    return (
        <>
            <ModalUpdateClientDataComponent
                onDataUpdate={handleDataUpdate}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                setIsDialogOpen={setIsDialogOpen}
            />
        </>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    profileUpdated: state.profileUpdated,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    profileUpdatedoAction,
    cleanCheckImeiAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalUpdateClientDataPage);
