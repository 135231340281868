import { useEffect } from "react";
import { usePixelMeta } from "utils/hooks/usePixelMeta";
import { usePixelTiktok } from "utils/hooks/usePixelTiktok";

let isInitialPixelsBuying = false;

export const usePixelsBuying = ({ ignoreInitialition = false }) => {
    const { initialPixelMeta, trackFacebookPixel } = usePixelMeta({
        pixelID: process.env?.REACT_APP_COMPRA_FACEBOOK_PIXEL,
    });
    const { initialPixelTiktok, trackTiktokPixel } = usePixelTiktok({
        pixelID: process.env?.REACT_APP_COMPRA_TIKTOK_PIXEL,
    });

    useEffect(() => {
        if (isInitialPixelsBuying) return;
        if (ignoreInitialition) return;
        initialPixelMeta();
        initialPixelTiktok();
        isInitialPixelsBuying = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const manualInitPixels = () => {
        initialPixelMeta();
        initialPixelTiktok();
        isInitialPixelsBuying = true;
    };

    const pixelSearch = () => {
        trackTiktokPixel("Search");
        trackFacebookPixel("Search");
    };

    const pixelAddToCart = ({
        sku,
        name,
        currency = "MXN",
        amount,
        quantity,
    }) => {
        trackTiktokPixel("AddToCart", {
            content_type: "product",
            quantity,
            description: name,
            content_id: sku,
            currency: currency,
            value: amount,
        });
        trackFacebookPixel("AddToCart", {
            contents: quantity,
            content_category: name,
            content_ids: sku,
            currency: currency,
            value: amount,
        });
    };

    const pixelPurchase = ({
        quantity,
        name,
        sku,
        currency = "MXN",
        amount,
    }) => {
        trackTiktokPixel("CompletePayment", {
            content_type: "product",
            quantity,
            description: name,
            content_id: sku,
            currency: currency,
            value: amount,
        });
        trackFacebookPixel("Purchase", {
            content_type: "product",
            num_items: 1,
            content_name: name,
            currency: currency,
            content_ids: sku,
            value: amount,
        });
    };

    return {
        pixelSearch,
        pixelAddToCart,
        manualInitPixels,
        pixelPurchaseBuying: pixelPurchase,
    };
};
