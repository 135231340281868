import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { usePixels } from "./usePixels";
import { useForm } from "react-hook-form";

export const useCardPromotion = ({
    checkImeiGiftAction,
    checkImeiGift,
    cleanCheckImeiGift,
}) => {
    const location = useLocation();
    const { pixelSearch } = usePixels();
    const tokenLead = location.state?.tokenLead || "";
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openModalMessage, setOpenModalMessage] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        setLoading(true);
        checkImeiGiftAction({ imei: data?.imei.trim(), token: tokenLead });
        pixelSearch(tokenLead);
    };

    useEffect(() => {
        if (checkImeiGift?.status === 200) {
            navigate("/esim-gift/compatibility", {
                state: {
                    isCompatibility: checkImeiGift?.payload?.esim_compatible,
                    tokenLead: tokenLead,
                },
            });
            cleanCheckImeiGift();
            setLoading(false);
        } else if (checkImeiGift?.payload?.response?.status >= 300) {
            if (
                checkImeiGift?.payload?.response?.data?.context ===
                    "lead_closed_error" &&
                checkImeiGift?.payload?.response?.status === 409
            ) {
                navigate("/esim-gift-error", {
                    state: {
                        title: checkImeiGift?.payload?.response?.data?.title,
                        responseMessage:
                            checkImeiGift?.payload?.response?.data?.detail,
                    },
                });
            }

            setOpenModalMessage(
                checkImeiGift?.payload?.response?.data?.detail || ""
            );
            setOpenModal(true);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }, [checkImeiGift, navigate, tokenLead, openModalMessage]);

    const handleClose = () => {
        setOpenModal(false);
        if (checkImeiGift?.payload?.response?.status === 401) {
            window.location.href = "/esim-gift";
        }
    };

    return {
        loading,
        openModalMessage,
        openModal,
        setOpenModal,
        handleClose,
        onSubmit,
        register,
        handleSubmit,
        errors,
    };
};
