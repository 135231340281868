import React, { useContext, useEffect, useState } from "react";

/*** Custom - start ***/
import { useNavigate } from "react-router-dom";
import { closeLoggedSession } from "_controllers/helpers/closeLoggedSession";
import { AppContext } from "_models/context/app";
import { getLoginDataHelper } from "_controllers/helpers/getLoginData";

//CCS:
import "../../../assets/styles/css/portal/components/responsive-app-bar.css";

//Material-UI:
import {
    Grid,
    Box,
    AppBar,
    Toolbar,
    Typography,
    Container,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import CardGiftcardOutlinedIcon from "@mui/icons-material/CardGiftcardOutlined";
import CreditCardIcon from "@mui/icons-material/CreditCard";

//Images:
import Logo from "assets/images/logo.png";

//Styles MUI:
import {
    appBarStyle,
    boxContainerMenuAccountDesktop,
    boxContainerMenuAccountPhone,
    boxContainerMenuDesktop,
    boxContainerMenuPhoneHamburger,
    buttonMenuDesktopStyle,
    gridContainerMenuAccountDesktop,
    menuMuiPapper,
} from "./styles-mui/responsiveAppBar";
/*** Custom - end ***/

function ResponsiveAppBar() {
    const appContext = useContext(AppContext);
    const { userName, setUserName, directNumber, setDirectNumber } =
        appContext || {};

    const [loginDataStorage, setLoginDataStorage] = useState();

    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        const getLoginDataResponse = getLoginDataHelper();
        setLoginDataStorage(getLoginDataResponse);
    }, []);

    useEffect(() => {
        const {
            userName,
            userLastName,
            userMothersLastName,
            userDirectNumber,
        } = loginDataStorage || {
            userName: "",
            userLastName: "",
            userMothersLastName: "",
            userDirectNumber: "",
        };

        const userNameValue = userName === null ? "" : userName;
        const userLastNameValue = userLastName === null ? "" : userLastName;
        const userMothersLastNameValue =
            userMothersLastName === null ? "" : userMothersLastName;
        const userDirectNumberValue =
            userDirectNumber === null ? "" : userDirectNumber;

        setUserName(
            userNameValue +
                " " +
                userLastNameValue +
                " " +
                userMothersLastNameValue
        );

        setDirectNumber(userDirectNumberValue);
    }, [loginDataStorage]);

    /*** Custom - start ***/
    const pages = [
        { key: "home", label: "Inicio", link: "navigationHome" },
        { key: "bundles", label: "Paquetes", link: "navigationPackages" },
        {
            key: "history",
            label: "Historial de compra",
            link: "navigationHistory",
        },
        {
            key: "portability",
            label: "Portabilidad",
            link: "navigationPortability",
        },
    ];

    const history = useNavigate();

    const navigationHome = () => {
        history("/portal");
    };

    const navigationHistory = () => {
        history("/historial");
    };
    const navigationPackages = () => {
        history("/paquetes");
    };

    const navigationPortability = () => {
        history("/portabilidad");
    };

    const navigationMyCards = () => {
        history("/mis-metodos-de-pago");
    };

    const navigationMyLegals = () => {
        history("/mis-datos-fiscales");
    };
    const navigationPromotions = () => {
        history("/promociones");
    };
    const AdminSuscriptionRoute = () => {
        history("/admin-suscription");
    };

    const logoutMethod = () => {
        closeLoggedSession("logoutMethod");
    };

    const goToMethod = (value = undefined) => {
        switch (value) {
            case "navigationHome":
                navigationHome();
                break;
            case "navigationPackages":
                navigationPackages();
                break;
            case "navigationHistory":
                navigationHistory();
                break;
            case "navigationPortability":
                navigationPortability();
                break;

            case "navigationMyCards":
                navigationMyCards();
                break;
            case "navigationMyLegals":
                navigationMyLegals();
                break;
            case "navigationPromotions":
                navigationPromotions();
                break;

            case "logoutMethod":
                logoutMethod();
                break;

            default:
                navigationHome();
                break;
        }
    };

    const optionsMenuAccount = [
        {
            item: "Mis tarjetas",
            onClick: () => goToMethod("navigationMyCards"),
            icon: <CreditCardIcon />,
        },
        {
            item: "Mis datos fiscales",
            onClick: () => goToMethod("navigationMyLegals"),
            icon: <TextSnippetOutlinedIcon />,
        },
        {
            item: "Promociones",
            onClick: () => goToMethod("navigationPromotions"),
            icon: <CardGiftcardOutlinedIcon />,
        },
        /* {
            item: "Pago recurrente",
            onClick: () => AdminSuscriptionRoute(),
            icon: <AccessTimeIcon />,
        }, */
        {
            item: "Cerrar sesión",
            onClick: () => logoutMethod(),
            icon: <LogoutIcon />,
        },
    ];

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            {...props}
        />
    ))(({ theme }) => ({
        "& .MuiPaper-root": menuMuiPapper(theme),
    }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    /*** Custom - end ***/

    return (
        <AppBar
            position="static"
            sx={appBarStyle}
            className="container-app-bar"
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Grid
                        item
                        xs={3}
                        sx={{ display: { xs: "none", md: "flex" } }}
                    >
                        <a href="/portal">
                            <img
                                src={Logo}
                                className={"image-logo-app-bar"}
                                alt="logo de Dalefon"
                            />
                        </a>
                    </Grid>

                    <Box sx={boxContainerMenuPhoneHamburger}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ display: { xs: "block", md: "none" } }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.key}
                                    onClick={() => goToMethod(page.link)}
                                    sx={{
                                        display: { xs: "flex", md: "none" },
                                        textTransform: "none",
                                    }}
                                >
                                    <Typography textAlign="left">
                                        {page.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Box sx={boxContainerMenuDesktop}>
                        {pages.map((page) => (
                            <Button
                                key={page.key}
                                onClick={() => goToMethod(page.link)}
                                sx={buttonMenuDesktopStyle}
                            >
                                {page.label}
                            </Button>
                        ))}
                    </Box>

                    <Box className={"user"} sx={boxContainerMenuAccountDesktop}>
                        <Grid container sx={gridContainerMenuAccountDesktop}>
                            <Grid item xs={10}>
                                <p>
                                    <span>{userName}</span>
                                </p>
                                <p>{directNumber}</p>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    id="demo-customized-button"
                                    aria-controls={
                                        open
                                            ? "demo-customized-menu"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    variant="filled"
                                    disableElevation
                                    onClick={handleClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                ></Button>
                                <Grid item xs={2} className="icon">
                                    <StyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                            "aria-labelledby":
                                                "demo-customized-button",
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                    >
                                        {optionsMenuAccount.map((option) => {
                                            return (
                                                <MenuItem
                                                    key={option.item}
                                                    onClick={option.onClick}
                                                    disableRipple
                                                >
                                                    {option.icon}
                                                    {option.item}
                                                </MenuItem>
                                            );
                                        })}
                                    </StyledMenu>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={boxContainerMenuAccountPhone}>
                        <Tooltip title="Cuenta">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                            >
                                <Avatar
                                    alt="Usuario"
                                    sx={{ p: 0, backgroundColor: "#522db1" }}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {optionsMenuAccount.map((option) => {
                                return (
                                    <MenuItem
                                        key={option.item}
                                        onClick={option.onClick}
                                        disableRipple
                                    >
                                        {option.icon}
                                        <Typography sx={{ ml: 1 }}>
                                            {option.item}
                                        </Typography>
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
