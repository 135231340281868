import { useEffect } from "react";

export const useFlowsProductsHook = (props) => {
    const {
        state,
        getOffers,
        setTabIndex,
        setTabSubcategory,
        setErrorMessage,
        setLoadingCircular,
        getOffersAction,
        setClassicSorted,
        setSlimSorted,
        setFitSorted,
        setLiteSorted,
    } = props || {};

    useEffect(() => {
        const { loading, getOffersObj } = getOffers || {};

        if (!loading) {
            const { name } = getOffersObj || {};
            if (name === "AxiosError") {
                setLoadingCircular(false);
                setErrorMessage(
                    "Por el momento no se encontraron paquetes, inténtelo más tarde."
                );
            } else {
                setErrorMessage("");
            }
        } else {
            const { data } = getOffersObj || {};
            if (typeof data !== "undefined") {
                setLoadingCircular(false);
                const { products } = data || {};

                if (
                    typeof products !== "undefined" &&
                    Array.isArray(products) &&
                    products.length === 0
                ) {
                    setErrorMessage(
                        "Por el momento no se encontraron paquetes, inténtelo más tarde."
                    );
                } else {
                    setErrorMessage("");
                    // Categorizar productos
                    const classicProducts = [];
                    const slimProducts = [];
                    const fitProducts = [];
                    const liteProducts = [];

                    products.forEach((product) => {
                        if (product.name.toLowerCase().includes("clásico")) {
                            classicProducts.push(product);
                        } else if (
                            product.name.toLowerCase().includes("slim")
                        ) {
                            slimProducts.push(product);
                        } else if (product.name.toLowerCase().includes("fit")) {
                            fitProducts.push(product);
                        } else if (
                            product.name.toLowerCase().includes("lite")
                        ) {
                            liteProducts.push(product);
                        }
                    });

                    setClassicSorted(classicProducts);
                    setSlimSorted(slimProducts);
                    setFitSorted(fitProducts);
                    setLiteSorted(liteProducts);
                }
            } else {
                setErrorMessage("");
            }
        }
    }, [getOffers]);

    useEffect(() => {
        setLoadingCircular(true);
        getOffersAction(state);
    }, [state, getOffersAction, setLoadingCircular]);

    const handleTabChange = (event, newTabIndex) => {
        console.log(newTabIndex);
        setTabIndex(newTabIndex);
        if (newTabIndex === 0) {
            setTabSubcategory(0);
        } else {
            setTabSubcategory(1);
        }
    };

    const handleSubcategoryChange = (event, newTabSubcategoryIndex) => {
        setTabSubcategory(newTabSubcategoryIndex);
    };

    return { handleTabChange, handleSubcategoryChange };
};
