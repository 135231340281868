import { Box, Grid, Typography } from "@mui/material";
import imgGift from "assets/images/gift/imagen-con-esim-de-regalo-exito.png";
import imgGiftMovil from "assets/images/gift/caja-de-regalo-con-esim-confeti.jpg";
import "assets/styles/css/gift/gift-welcome.css";
import { logoContainer } from "../styles-mui/giftStyleMUI";
import logoDalefon from "assets/images/logo.png";

export const GiftWelcomePage = () => {
    return (
        <Grid maxWidth={"lg"} className="gift-welcome-container">
            <Box className="movil-container">
                <img
                    src={imgGiftMovil}
                    className="gift-image"
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                    }}
                />
                <Box sx={logoContainer}>
                    <img src={logoDalefon} className="logo-title" width={160} />
                </Box>
                <Box
                    display={{ xs: "none", md: "flex" }}
                    flexDirection={"column"}
                >
                    <Typography
                        variant="p"
                        fontSize={{ xs: 36, md: 38 }}
                        lineHeight={{ md: 0.9 }}
                        className="font-medium white-color"
                    >
                        Bienvenido a la
                    </Typography>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 36, md: 72 }}
                        className="font-semibold white-color"
                    >
                        Telefonía
                    </Typography>
                    <Typography
                        variant="p"
                        fontSize={{ xs: 36, md: 86 }}
                        mb={{ xs: "30px", md: "45px" }}
                        lineHeight={"50px"}
                        className="font-bold purple-color"
                    >
                        Morada
                    </Typography>
                </Box>
                <Box className="check-email-container">
                    <Box
                        width={{ xs: "100%", md: 321 }}
                        display={"flex"}
                        flexDirection={"column"}
                    >
                        <Typography
                            variant="p"
                            fontSize={{ xs: 18, md: 20 }}
                            mb={{ xs: "50px", md: "77px" }}
                            className="white-color"
                            sx={{
                                width: { xs: "90%", md: "321px" },
                                margin: "0 auto",
                                lineHeight: 1.8,
                            }}
                        >
                            Revisa tu{" "}
                            <span className="font-medium">
                                bandeja de entrada
                            </span>{" "}
                            para conocer los detalles de tu{" "}
                            <span className="font-medium">eSIM.</span>
                        </Typography>

                        <Typography
                            variant="p"
                            fontSize={{ xs: 14, md: 15 }}
                            className="white-color"
                            mb={{ xs: "35px", md: "10px" }}
                        >
                            Ya puedes cerrar esta pestaña.
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="img-container">
                <img
                    src={imgGift}
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                    }}
                    alt="Caja de regalo abierta con esim dentro"
                />
            </Box>
        </Grid>
    );
};
