import React from "react";

//Material UI:
import { Grid, TextField, Popover, Typography } from "@mui/material";

//Stules MUI:
import {
    gridItemContainerCard,
    textFieldImei,
    typographySubtitleCard,
    typographyTitleCard,
} from "./styles-mui/compatibilityPurchaseCardImeiStylesMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function CompatibilityPurchaseCardImeiComponent({
    handleSubmit,
    onSubmit,
    register,
    registerOptions,
    imeiValue,
    setImeiValue,
    textFieldRef,
    isPopoverOpen,
    errors,
    response,
    responseErrors,
    imeiLoading,
    setIsPopoverOpen,
    calculatePopoverPosition,
}) {
    return (
        <Grid item xs={12} md={4} xl={3.5} sx={gridItemContainerCard}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                    <Typography sx={typographyTitleCard}>Por IMEI</Typography>
                    <Typography sx={typographySubtitleCard}>
                        Obtén el{" "}
                        <Typography variant="span" sx={{ fontWeight: "bold" }}>
                            IMEI
                        </Typography>{" "}
                        de tu equipo marcando al{" "}
                        <Typography variant="span" sx={{ fontWeight: "bold" }}>
                            <a
                                style={{
                                    textDecoration: "none",
                                    color: "#fc2bdc",
                                }}
                                href="tel:*#06#"
                            >
                                *#06#
                            </a>
                        </Typography>
                    </Typography>

                    <TextField
                        fullWidth
                        {...register("imeiValue", registerOptions.imeiValue)}
                        id="outlined-basic"
                        label="IMEI"
                        placeholder="Por favor, ingresa el código IMEI que aparece en tu teléfono"
                        variant="outlined"
                        value={imeiValue || ""}
                        onChange={(event) => setImeiValue(event.target.value)}
                        inputRef={textFieldRef}
                        sx={textFieldImei(isPopoverOpen)}
                    />
                    <span>
                        {errors?.imeiValue && (
                            <p className="required-incidence">
                                {errors.imeiValue.message}
                            </p>
                        )}
                        {errors?.imeiValue &&
                            ["minLength", "maxLength"].indexOf(
                                errors?.imeiValue.type
                            ) !== -1 && (
                                <p className="required-incidence">
                                    El IMEI debe ser de 15 dígitos
                                </p>
                            )}
                    </span>
                </Grid>

                <Grid
                    container
                    className="required-incidence error-response-container"
                >
                    <Grid
                        item
                        xs={12}
                        className="required-incidence error-response"
                    >
                        {response?.data?.detail}
                    </Grid>
                </Grid>

                <Grid
                    container
                    className="required-incidence error-response-container"
                >
                    {typeof responseErrors !== "undefined"
                        ? responseErrors.map((reElement, reIndex) => {
                              return typeof reElement.message !==
                                  "undefined" ? (
                                  <Grid
                                      key={reIndex}
                                      item
                                      xs={12}
                                      className="required-incidence error-response"
                                  >
                                      {reElement.message}
                                  </Grid>
                              ) : (
                                  ""
                              );
                          })
                        : null}
                </Grid>

                <Grid item xs={12}>
                    <CustomButtonComponent
                        text={"Verificar"}
                        bgColor={"violet"}
                        height={"50px"}
                        async={true}
                        loading={imeiLoading}
                    />
                </Grid>
            </form>

            <Popover
                className="imei-popover"
                open={isPopoverOpen}
                onClose={() => setIsPopoverOpen(false)}
                anchorEl={textFieldRef.current}
                disableScrollLock
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    style: {
                        backgroundColor: "#3E0D67",
                        color: "#FFFFFF",
                        width: "100%",
                        padding: 10,
                        top: calculatePopoverPosition().top,
                        left: calculatePopoverPosition().left,
                        position: "absolute",
                    },
                }}
            >
                <Typography>
                    Para saber si tu equipo es compatible, por favor ingresa tu
                    IMEI.
                </Typography>
            </Popover>
        </Grid>
    );
}

export default CompatibilityPurchaseCardImeiComponent;
