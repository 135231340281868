import React from "react";
import { Box } from "@mui/material";
import imgSocialMedia from "assets/images/utils/components/cards/social-media.svg";
import imgSocialMediaResponsive from "assets/images/gift/social-media-responsive.svg";
import {
    boxContainerImg,
    boxContainerResponsiveImg,
} from "modules/gift/styles-mui/giftStyleMUI";

const SocialMediaSection = () => (
    <>
        <Box sx={boxContainerImg}>
            <img
                src={imgSocialMedia}
                alt="Redes sociales"
                className="img-social-media"
            />
        </Box>
        <Box sx={boxContainerResponsiveImg}>
            <img
                src={imgSocialMediaResponsive}
                alt="Redes sociales"
                className="img-social-media-responsive"
            />
        </Box>
    </>
);

export default SocialMediaSection;
