export const getRegisterOptions = () => {
    return {
        registerOptions: {
            name: {
                required: "El nombre completo es requerido",
                minLength: {
                    value: 2,
                    message: "Debe tener al menos 2 caracteres",
                },
            },
            phone_number: {
                required: "El número telefónico es requerido",
                minLength: {
                    value: 10,
                    message:
                        "El número telefónico debe contener al menos 10 dígitos",
                },
            },
            email: {
                pattern: {
                    value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                    message:
                        "El valor ingresado no tiene formato de correo electrónico",
                },
            },
            offer_id: {
                required: "El producto es requerido",
            },
            state: { required: "El estado es requerido" },
            municipality: { required: "El municipio es requerido" },
            neighborhood: { required: "La colonia es requerida" },
            zipcode: { required: "El código postal es requerido" },
            address: { required: "La dirección es requerida" },
            contact_phone_number: {
                required: "El número telefónico es requerido",
            },
        },
    };
};
