import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Grid, Box, Typography, Button } from "@mui/material";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import errorImage from "assets/images/gift/heart.png";
import reload from "assets/images/gift/reload.png";
import { gridContainer, imageContainer } from "../styles-mui/responseStyleMUI";

const ErrorSignUpPageGift = () => {
    const location = useLocation();
    const { title, responseMessage } = location.state || {};

    useEffect(() => {
        return () => {
            // Acción del clean
        };
    }, []);

    const handleRefresh = () => {
        window.location.href = "/esim-gift";
    };

    const goToOffer = () => {
        window.location.href = "https://www.dalefon.mx/";
    };

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth={"lg"}>
                <Grid container sx={gridContainer}>
                    <Grid item xs={12} md={4}>
                        <Box sx={imageContainer}>
                            <img src={errorImage} width={50} />
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    fontSize: "36px",
                                    textAlign: "center",
                                    mb: 2,
                                    color: "#ffffff",
                                }}
                            >
                                {title || "Algo salió mal"}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "#ffffff",
                                    mb: 4,
                                    width: "100%",
                                    whiteSpace: "pre-wrap",
                                }}
                            >
                                {responseMessage || (
                                    <p>
                                        {" "}
                                        Hubo un error, pero no te preocupes, no
                                        hemos abierto tu regalo.
                                        {"\n"}
                                        {"\n"}Por favor, refresca la página..
                                    </p>
                                )}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 2,
                                }}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: "#7e2ce9",
                                        color: "#ffffff",
                                        borderRadius: "30px",
                                        height: "50px",
                                        width: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        "&:hover": {
                                            backgroundColor: "#6b1dcf",
                                        },
                                    }}
                                    onClick={handleRefresh}
                                >
                                    <img
                                        src={reload}
                                        alt="reload"
                                        style={{
                                            marginRight: "8px",
                                            marginTop: "-2px",
                                            width: "15px",
                                            height: "15px",
                                        }}
                                    />
                                    Refrescar
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 2,
                                }}
                            >
                                <Button
                                    sx={{
                                        marginTop: 1,
                                        color: "#ffffff",
                                        borderRadius: "30px",
                                        border: "1px solid #ffffff",
                                        height: "50px",
                                        width: "200px",
                                    }}
                                    onClick={goToOffer}
                                >
                                    Conocer oferta
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

export default ErrorSignUpPageGift;
