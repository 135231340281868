import {
    CLEAN_GET_LANDING_ESIM,
    CLEAN_GET_LANDING_ESIM_ERROR,
} from "_models/redux/types";

export const cleanGetLandingEsimAction = () => async (dispatch) => {
    try {
        dispatch({
            type: CLEAN_GET_LANDING_ESIM,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: CLEAN_GET_LANDING_ESIM_ERROR,
            payload: [],
        });
    }
};
