export const gridContainerCheckCompatibility = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: { xs: 3, md: 1 },
};

export const gridItemTitle = {
    mt: { xs: 1, md: 4 },
    mb: { xs: 1, md: 0 },
};

export const typographyTitle = {
    fontSize: { xs: 22, md: 38 },
    fontWeight: "600",
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    mt: { xs: 1, md: 0 },
    mx: { xs: 5, md: 0 },
};

export const typographyTitle2 = {
    fontSize: { xs: 22, md: 28 },
    fontWeight: "800",
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    mb: { xs: 1, md: 0 },
    mx: { xs: 5, md: 0 },
    mt: { xs: 0, md: 2 },
};

export const typographySubTitle = {
    fontSize: { xs: 14, md: 16 },
    letterSpacing: 1.2,
    textAlign: "center",
    color: "white",
    my: { xs: 2, md: 3 },
    mx: { xs: 2, md: 20, lg: 30, xl: 40 },
};

export const gridContainerContent = {
    mt: { xs: 2, md: 4 },
    display: "flex",
    justifyContent: "space-evenly",
};

export const typographyTheftReport = {
    color: "white",
    fontSize: 14,
    textAlign: "center",
};
