import React from "react";

import HomeHeaderComponent from "./sections/header/HomeHeaderComponent";
import ActiveOffersContainer from "./sections/active_offers/container";

const HomePage = (props) => {
    return (
        <div>
            <HomeHeaderComponent />
            <ActiveOffersContainer />
            {/* <HomeInactive /> */}
        </div>
    );
};

export default HomePage;
