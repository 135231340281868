import React from "react";
import { connect } from "react-redux";
import { InvoiceContext } from "./data/invoiceContext";
import { useInvoiceContainer } from "./invoiceContainer";
import { postInvoiceSimPurchaseAction } from "_models/redux/post-invoice-sim-purchase/action";
import { cleanPostInvoiceSimPurchaseAction } from "_models/redux/post-invoice-sim-purchase/clean";
import { stepOneInvoiceAction } from "_models/redux/step-invoice-1/action";
import { invoicePageDownloadAction } from "_models/redux/invoice-page-download/action";

import InvoicePresentational from "./invoicePresentational";
import { createInvoiceStep2Action } from "_models/redux/step-invoice-2/action";

const InvoicePage = (props) => {
    const { invoiceValues } = useInvoiceContainer(props);

    return (
        <InvoiceContext.Provider value={invoiceValues}>
            <InvoicePresentational />
        </InvoiceContext.Provider>
    );
};

const mapStateToProps = (state) => ({
    postInvoiceSimPurchase: state?.postInvoiceSimPurchase,
    stepOneInvoice: state.stepOneInvoice,
    createInvoiceStep2: state.createInvoiceStep2,
    InvoicePageDownload: state.InvoicePageDownload,
});

const mapDispatchToProps = {
    postInvoiceSimPurchaseAction,
    cleanPostInvoiceSimPurchaseAction,
    stepOneInvoiceAction,
    createInvoiceStep2Action,
    invoicePageDownloadAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage);
