import React from "react";
import {
    Box,
    TextField,
    Tooltip,
    Typography,
    Grid,
    FormHelperText,
} from "@mui/material";
import askSvg from "assets/images/gift/ask-question.svg";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import { FaCheck } from "react-icons/fa6";
import {
    boxContainerMinAndSMS,
    boxContainerNoShareAndShareWifi,
    buttonComponent,
    inputStyle,
    typographyIMEI,
    typographyLabel,
    typographyNoShareAndShareWifi,
    TypographyValidity,
} from "modules/gift/styles-mui/giftStyleMUI";
import imgNoShareWifi from "assets/images/utils/components/cards/no-hotspot.svg";

const ImeiForm = ({
    handleButtonClickWithLoader,
    onSubmit,
    register,
    handleSubmit,
    errors,
    loading,
}) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} md={12} px={{ xs: "35px", md: "65px" }}>
                <Typography sx={TypographyValidity}>
                    7 días de vigencia
                </Typography>
                {/* No Share Wifi */}
                <Box sx={boxContainerNoShareAndShareWifi}>
                    <img
                        src={imgNoShareWifi}
                        width={15}
                        height={15}
                        className="circular-icon"
                    />
                    <Typography sx={typographyNoShareAndShareWifi}>
                        No comparte datos
                    </Typography>
                </Box>
                {/* Minutos y SMS */}
                <Box sx={boxContainerMinAndSMS}>
                    <FaCheck className="circular-icon" />
                    <Typography variant="p">Minutos y SMS</Typography>
                </Box>
                {/* IMEI */}
                <Box>
                    <Box
                        sx={{
                            width: "100%",
                            textAlign: "left",
                        }}
                    >
                        <Typography variant="span" sx={typographyIMEI}>
                            IMEI
                            <Tooltip
                                title="Con tu IMEI nos aseguramos de que tu celular sea compatible con la red Dalefon (banda 28)."
                                placement="bottom-start"
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            bgcolor: "#fff",
                                            color: "#303030",
                                        },
                                    },
                                }}
                            >
                                <img
                                    src={askSvg}
                                    alt="signo de cerrar pregunta"
                                    width={11}
                                    height={11}
                                />
                            </Tooltip>
                        </Typography>

                        <p className="poppins-regular-font font-size-16">
                            Obten el IMEI de tu teléfono marcando{" "}
                            <a href="tel:*#06#" className="tel-link">
                                *#06#
                            </a>
                        </p>
                    </Box>
                    <TextField
                        hiddenLabel
                        fullWidth
                        placeholder="Ingresa el IMEI de tu celular"
                        sx={inputStyle}
                        {...register("imei", {
                            required: "IMEI es requerido",
                            minLength: {
                                value: 15,
                                message: "El IMEI debe tener 15 caracteres",
                            },
                            pattern: {
                                value: /^[0-9]{15}$/,
                                message:
                                    "El IMEI debe ser un número de 15 dígitos",
                            },
                            validate: (value) =>
                                value.trim().length === 15 ||
                                "El IMEI debe tener 15 caracteres",
                        })}
                        error={Boolean(errors.imei)}
                        onChange={(e) => {
                            e.target.value = e.target.value.replace(/\s+/g, "");
                        }}
                    />
                    {errors.imei && (
                        <FormHelperText sx={{ color: "white" }}>
                            {errors.imei.message}
                        </FormHelperText>
                    )}
                </Box>
                {/* Button */}
                <Box sx={buttonComponent}>
                    <CustomButtonComponent
                        async={true}
                        bgColor={"primary-purple"}
                        text={"Verificar compatibilidad"}
                        height={45}
                        onClick={handleButtonClickWithLoader}
                        loading={loading}
                    />
                </Box>
                <Grid item xs={12}>
                    <Box>
                        <Typography sx={typographyLabel}>
                            *Al aceptar este regalo, estás llevándote un Dale 69
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

export default ImeiForm;
