import React, { useContext, useEffect } from "react";

import { RouterContext } from "./context";

import ErrorPage from "modules/error/page";

import { getLoginDataHelper } from "_controllers/helpers/getLoginData";
import { useCampaing } from "utils/hooks/useCampaing";

const RouterContainer = (props) => {
    const routerContext = useContext(RouterContext);
    const { userLogged } = getLoginDataHelper();
    useCampaing();

    const { loggedChildren, unloggedChildren } = props || {
        loggedChildren: <ErrorPage />,
        unloggedChildren: <ErrorPage />,
    };
    useEffect(() => {
        routerContext.setRouterTitle("Mi navegación");
    }, []);

    return userLogged ? loggedChildren : unloggedChildren;
};

export default RouterContainer;
