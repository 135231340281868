import { Container, Grid } from "@mui/material";
import "assets/styles/css/gift/gift.css";
import logoDalefon from "assets/images/logo.png";
export const GiftLayoutPage = ({ children }) => {
    return (
        <Container maxWidth="lg" className="layout-conteiner">
            <img src={logoDalefon} alt="Logo dalefon" width={160} />
            <Grid
                container
                display={"flex"}
                alignItems={"center"}
                height={{ xs: "auto", md: "80%" }}
                mt={{ xs: "60px", md: 0 }}
            >
                {children}
            </Grid>
        </Container>
    );
};
