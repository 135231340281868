import activityMethod from "./activity";

export default function responseContstructor(params) {
    const log = params.log || "";
    const type = params.type || {};
    const data = params.data || {};
    const request = params.request || "";
    const response = params.response || {};
    const headers = response.headers || {};
    activityMethod({
        log: log,
        type: request + ":" + type,
        date: headers.date || "",
    });
    return { type: type, data: data };
}
