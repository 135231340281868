import { useReducer } from "react";

import { useFormValues } from "./invoiceUseformMethods";

import { useInvoiceReducer } from "../data/useInvoiceReducer";

export const useInvoiceStep2 = (props) => {
    //Form Library Method
    const { formValues } = useFormValues();

    //Redux Method and Props
    const {
        postInvoiceSimPurchase,
        postInvoiceSimPurchaseAction,
        cleanPostInvoiceSimPurchaseAction,
    } = props || {};

    const initialInvoiceDataStep2 = {
        formValues,

        pageLoaded: false,

        reCaptchaKey: process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "",
        reCaptchaAction: "stamp_purchase_transaction",
        reCaptchaToken: undefined,
        reCaptchaSubmit: undefined,

        formResponse: undefined,
        loaderResponse: undefined,
        sectionResponse: "form",
    };

    const [invoiceDataStep2, dispatchInvoiceDataStep2] = useReducer(
        useInvoiceReducer,
        initialInvoiceDataStep2
    );

    const invoiceStep2 = {
        invoiceDataStep2,
        dispatchInvoiceDataStep2,

        postInvoiceSimPurchase,
        postInvoiceSimPurchaseAction,
        cleanPostInvoiceSimPurchaseAction,
    };

    return {
        invoiceStep2,
    };
};
