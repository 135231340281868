export const convertToMaskCard = (cardNumber) => {
    if (!cardNumber) return;
    const endNumberCard = cardNumber.slice(-4);

    if (cardNumber.length === 15) {
        return {
            prefix: "•••• •••••• •",
            numberCard: endNumberCard,
        };
    }

    return {
        prefix: "•••• •••• ••••",
        numberCard: endNumberCard,
    };
};
