import React from "react";

//Material UI:
import {
    Grid,
    FormHelperText,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";

//Styles MUI:
import {
    gridItemContainerCard,
    typographyNoteCard,
    typographyTitleCard,
} from "./styles-mui/compatibilityPurchaseCardBrandAndModelStylesMUI";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

function CompatibilityPurchaseCardBrandAndModelComponent({
    handleSubmit,
    onSubmit,
    selectedBrandId,
    verifyButtonClicked,
    props,
    selectedModelId,
    handleBrandChange,
    handleModelChange,
    isBrandSelected,
    isModelsSelected,
    isCheckboxChecked,
    handleCheckboxChange,
    checkboxError,
    onSubmitByModel,
    isLoading,
    setIsPopoverOpen,
    setSelectedBrandId,
    responseErrors,
    response,
}) {
    return (
        <Grid item xs={12} md={4} xl={3.5} sx={gridItemContainerCard}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12}>
                    <Typography sx={typographyTitleCard}>
                        Por marca y modelo
                    </Typography>

                    <FormControl
                        fullWidth
                        error={!selectedBrandId && verifyButtonClicked}
                    >
                        <InputLabel id="demo-simple-select-label">
                            Marca
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Marca"
                            sx={{ textAlign: "left" }}
                            value={selectedBrandId || ""}
                            required={verifyButtonClicked && !isBrandSelected}
                            onChange={(event) => {
                                if (event.target.value === "otro") {
                                    setIsPopoverOpen(true);
                                    // setIsImeiModalOpen(true);
                                } else {
                                    handleBrandChange(event);
                                    setSelectedBrandId(event.target.value); // Update the selected brand in the state
                                }
                            }}
                        >
                            {props.getBrandsDevices.brandsDevicesObj?.result?.map(
                                (brand) => (
                                    <MenuItem key={brand.id} value={brand.id}>
                                        {brand.name}
                                    </MenuItem>
                                )
                            )}
                            {/* Add "Otro" option */}
                            <MenuItem value="otro">Otro</MenuItem>
                        </Select>
                        {verifyButtonClicked &&
                            !selectedBrandId && ( // Display error message if the button is clicked and brand is not selected
                                <FormHelperText>
                                    Marca es requerido
                                </FormHelperText>
                            )}
                    </FormControl>

                    <FormControl
                        sx={{ mt: 2 }}
                        fullWidth
                        error={!selectedModelId && verifyButtonClicked}
                    >
                        <InputLabel id="demo-simple-select-label">
                            Modelo
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Marca"
                            sx={{ textAlign: "left" }}
                            value={selectedModelId || ""}
                            onChange={handleModelChange}
                        >
                            {/* Mapeas los datos de las marcas de dispositivos para crear las opciones */}
                            {props.getDevicesModels.modelsDevicesObj.result?.map(
                                (models) => (
                                    <MenuItem key={models.id} value={models.id}>
                                        {models.name}
                                    </MenuItem>
                                )
                            )}
                            <MenuItem value="otro">Otro</MenuItem>
                        </Select>
                        {verifyButtonClicked &&
                            !isModelsSelected && ( // Display error message if the button is clicked and brand is not selected
                                <FormHelperText>
                                    Modelo es requerido
                                </FormHelperText>
                            )}
                    </FormControl>
                </Grid>

                <Box sx={{ display: "flex", mt: 2 }}>
                    <FormControlLabel
                        sx={{ color: "#8e8e8e" }}
                        control={
                            <Checkbox
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxChange}
                                name="checkBox"
                            />
                        }
                        label={
                            <Typography sx={typographyNoteCard}>
                                Este celular es el que usaré con mi línea
                                Dalefon.
                            </Typography>
                        }
                    />
                </Box>

                {checkboxError && (
                    <p style={{ color: "red" }}>{checkboxError}</p>
                )}

                <Grid
                    container
                    className="required-incidence error-response-container"
                >
                    <Grid
                        item
                        xs={12}
                        className="required-incidence error-response"
                    >
                        {response?.data?.detail}
                    </Grid>
                </Grid>

                <Grid
                    container
                    className="required-incidence error-response-container"
                >
                    {typeof responseErrors != "undefined"
                        ? responseErrors.map((reElement, reIndex) => {
                              return typeof reElement.message != "undefined" ? (
                                  <Grid
                                      key={reIndex}
                                      item
                                      xs={12}
                                      className="required-incidence error-response"
                                  >
                                      {reElement.message}
                                  </Grid>
                              ) : (
                                  ""
                              );
                          })
                        : null}
                </Grid>

                <Grid item xs={12}>
                    <CustomButtonComponent
                        text={"Verificar"}
                        bgColor={"violet"}
                        height={"50px"}
                        async={true}
                        loading={isLoading}
                        onClick={onSubmitByModel}
                    />
                </Grid>
            </form>
        </Grid>
    );
}

export default CompatibilityPurchaseCardBrandAndModelComponent;
