import React, { useEffect, useState } from "react";
import { useLogin } from "./useLoginHook";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { setLoginDataHelper } from "_controllers/helpers/setLoginData";

export const HomeContainerController = ({
    login,
    beCustomer,
    checkImei,
    getOffers,
    offerSelection,
    shippingAddress,
    municipalitiesData,
    neighborhoodsData,
    payment,
    paymentConfirm,
    completeProcess,

    loginAction,
    cleanPurchaseContactInfoAction,
    cleanCheckImeiAction,
    cleanGetOffersAction,
    cleanOfferSelectionAction,
    cleanPaymentAction,
    cleanShippinghAddressAction,
    cleanMunicipalitiesAction,
    cleanNeighborhoodsAction,
    cleanPaymentConfirmAction,
    cleanCompleteProcessAction,
}) => {
    const history = useNavigate();

    const {
        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const [loginResponse, setLoginResponse] = useState();
    const [loginResponseCls, setLoginResponseCls] = useState({
        container: "",
        text: "",
    });

    const [variant, setVariant] = useState("determinate");
    const [progress, setProgress] = useState(0);

    const { sendMethod } = useLogin({
        login,
        loginAction,
    });

    const sendLogin = (data) => {
        setVariant("indeterminate");
        sendMethod(data);
    };

    const loginValues = {
        sendLogin,

        loginResponse,
        setLoginResponse,
        loginResponseCls,
        setLoginResponseCls,

        variant,
        setVariant,
        progress,
        setProgress,

        watch,
        reset,
        control,
        setValue,
        getValues,
        register,
        handleSubmit,
        setError,
        errors,
    };

    useEffect(() => {
        cleanPurchaseContactInfoAction();
        cleanCheckImeiAction();
        cleanGetOffersAction();
        cleanOfferSelectionAction();
        cleanShippinghAddressAction();
        cleanMunicipalitiesAction();
        cleanNeighborhoodsAction();
        cleanPaymentAction();
        cleanPaymentConfirmAction();
        cleanCompleteProcessAction();
    }, []);

    useEffect(() => {
        if (typeof login.login == "undefined") {
            return false;
        }
        const { token, expires_at, refresh_token, response } =
            login.login || {};
        if (
            typeof token != "undefined" &&
            typeof expires_at != "undefined" &&
            typeof refresh_token != "undefined"
        ) {
            setLoginResponse("¡Bienvenido!");
            setLoginResponseCls({
                container: "success-container",
                text: "success-text",
            });
            setVariant("determinate");
            setLoginDataHelper(login.login, "LoginContainer");
            history("/dashboard");
        } else if (typeof response != "undefined") {
            const { data } = response || {};
            const { additional_details, context, detail, title, type } =
                data || {};
            setLoginResponse(detail || "");
            setLoginResponseCls({
                container: "error-container",
                text: "error-text",
            });
            setVariant("determinate");
            setLoginDataHelper(
                { token: null, expires_at: null, refresh_token: null },
                "MenuContainer"
            );
            history("/menu");
        }
    }, [login]);
    return { loginValues };
};
