import axiosTokenInstance from "services/api/service-client";

import { MYCARD_ACTION, MYCARD_ACTION_ERROR } from "_models/redux/types";

// Action function
export const getMyCardAction =
    (params = {}) =>
    async (dispatch) => {
        const { card_id } = params || {};

        // Endpoint: Try & Catch
        try {
            const response = await axiosTokenInstance.get(
                `/api/clients/actions/cards/tokenizations/${card_id}`
            );

            const { data, status } = response || {};

            dispatch({
                type: MYCARD_ACTION,
                status: status || {},
                payload: data || {},
            });
        } catch (e) {
            const { response } = e || {};
            const { data, status } = response || {};

            dispatch({
                type: MYCARD_ACTION_ERROR,
                status: status || {},
                payload: data || {},
            });
        }
    };
