import React from "react";

//CSS:
import "assets/styles/css/portal/pages/wbc-purchase/wbc-purchase-page.css";

//Redux:
import { connect } from "react-redux";

//Material UI:
import { Box, CircularProgress, Container, Typography } from "@mui/material";

//Models:
import { getPurchasePaymentStatusAction } from "_models/redux/purchase-payment-status/action";
import { cleanPurchasePaymentStatusAction } from "_models/redux/purchase-payment-status/clean";

//Styles MUI:
import {
    failImageStyle,
    styleContainer,
    titleStyle,
    legendStyle,
    messageStyle,
    boxMessageContainer,
} from "./styles-mui/wbcPurchasePageStylesMUI";

//Hooks:
import useWbcPurchasePageHook from "./hooks/useWbcPurchasePageHook";

//Components:
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import CardSuccessComponent from "./components/CardSuccessComponent";

const WbcPurchasePageComponent = (props) => {
    const {
        loading,
        dataContentWBCPurchase,
        status,
        sim_type_id,
        product_name,
        total_amount,
        detail,
        title,
        history,
        payment_order_status,
        infoProduct,
    } = useWbcPurchasePageHook(props);

    return (
        <BaseFirstStepsComponent>
            <Container
                maxWidth="xs"
                sx={styleContainer}
                className="container-wbc-purchase-page"
            >
                {loading ? (
                    <CircularProgress color="secondary" />
                ) : (
                    <>
                        <img
                            src={dataContentWBCPurchase.img}
                            alt={dataContentWBCPurchase.alt}
                            style={failImageStyle}
                        />
                        {status ? (
                            <>
                                <Typography variant="h1" sx={titleStyle}>
                                    ¡Compra exitosa!
                                </Typography>

                                <Typography sx={legendStyle}>
                                    {sim_type_id === 2 ? (
                                        <p>
                                            Consulta tu bandeja de entrada y
                                            escanea el{" "}
                                            <Typography
                                                variant="span"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                código QR
                                            </Typography>{" "}
                                            que te hemos enviado para{" "}
                                            <Typography
                                                variant="span"
                                                sx={{ fontWeight: "bold" }}
                                            >
                                                activar tu eSIM.
                                            </Typography>
                                        </p>
                                    ) : (
                                        <p>
                                            Consulta tu bandeja de entrada y
                                            sigue las intrucciones que te hemos
                                            enviado
                                        </p>
                                    )}
                                </Typography>

                                <CardSuccessComponent
                                    product_name={product_name}
                                    total_amount={total_amount}
                                    sim_type_id={sim_type_id}
                                    infoProduct={infoProduct}
                                    history={history}
                                />

                                <Box sx={{ mt: 4 }}>
                                    <CustomButtonComponent
                                        text="Finalizar"
                                        bgColor="violet"
                                        height="50px"
                                        onClick={() => history(`/`)}
                                    />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Typography variant="h1" sx={titleStyle}>
                                    {title || ""}
                                </Typography>
                                <Typography sx={legendStyle}>
                                    {dataContentWBCPurchase.note}
                                </Typography>

                                <Box sx={boxMessageContainer}>
                                    <Typography
                                        sx={messageStyle(
                                            dataContentWBCPurchase.id
                                        )}
                                    >
                                        {detail || ""}
                                    </Typography>
                                </Box>

                                <Box sx={{ mt: 2 }}>
                                    <CustomButtonComponent
                                        text={
                                            payment_order_status === "FAIL"
                                                ? `Volver al inicio`
                                                : `Consultar nuevamente`
                                        }
                                        bgColor="violet"
                                        height="50px"
                                        onClick={() =>
                                            payment_order_status === "FAIL"
                                                ? history(`/`)
                                                : window.location.reload()
                                        }
                                    />
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Container>
        </BaseFirstStepsComponent>
    );
};

const mapStateToProps = (state) => ({
    purchasePaymentStatus: state?.purchasePaymentStatus,
});

const mapDispatchToProps = {
    getPurchasePaymentStatusAction,
    cleanPurchasePaymentStatusAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WbcPurchasePageComponent);
