import React from "react";

import { Grid } from "@mui/material";
import EsimGiftConfirmation from "assets/images/landings/gift/esim-gift-confirmation.png";

const GiftConfirmationContent = () => {
    return (
        <>
            <Grid
                container
                className={"main-page"}
                justifyContent={"center"}
                height={"100vh"}
            >
                <Grid item className={"main-component-container"}>
                    <Grid
                        container
                        className={"main-component centered-component"}
                    >
                        <Grid
                            item
                            className={"main-form no-bg success-form centered"}
                        >
                            <Grid
                                item
                                md={8}
                                xs={12}
                                className="purchase-form no-form gift-confirmation-form"
                            >
                                <form>
                                    <Grid
                                        item
                                        xs={12}
                                        className="centered"
                                        margin={"50px"}
                                    >
                                        <div>
                                            <h4>¡Hemos enviado tu regalo!</h4>
                                        </div>

                                        <div
                                            className={
                                                "congratulations-container"
                                            }
                                        >
                                            <img
                                                src={EsimGiftConfirmation}
                                                className={
                                                    "main-image esim-gift-confirmation"
                                                }
                                            />
                                        </div>

                                        <div className="success-p">
                                            <p>Ya puedes cerrar esta página</p>
                                        </div>
                                    </Grid>
                                </form>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default GiftConfirmationContent;
