import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PortalNavigation from "modules/portal/components/navigation";
import PortalFooter from "modules/portal/components/footer";
import { Grid, Container, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import { prepaidSubscriptionAction } from "_models/redux/prepaid-subscription/action";
import moment from "moment";
import SMS from "assets/images/products/sms.svg";
import Minutos from "assets/images/products/minutos.svg";
import Cobertura from "assets/images/products/spot.svg";
import { deleteSubscriptionAction } from "_models/redux/delete-suscription/action";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Clock from "assets/images/suscription/renovar.png";

const AdminSuscription = (props) => {
    const { prepaidSubscription } = props || {};
    const { prepaidSubscriptiongObj } = prepaidSubscription || {};
    const { carrier_product } = prepaidSubscriptiongObj || undefined;

    const { response } = prepaidSubscriptiongObj || {};

    const [open, setOpen] = useState(false);

    useEffect(() => {
        props.prepaidSubscriptionAction();
    }, []);

    const history = useNavigate();

    const goPackage = () => {
        history("/paquetes");
    };

    let gigaBytes = carrier_product?.mobile_data / 1000;
    const totalData = gigaBytes.toLocaleString("en");

    const deleteSuscription = () => {
        props.deleteSubscriptionAction();
        props.prepaidSubscriptionAction();
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
    };

    const DeleteModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className={"custom-modal my-legals-modal"}
                    >
                        <Box sx={style}>
                            <Grid
                                container
                                className={"my-legals-modal-content"}
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        Cancelar renovación
                                    </span>
                                </Typography>
                                <Typography
                                    id="modal-modal-description"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-modal-text"}>
                                        Quiero que este paquete deje de
                                        renovarse automáticamente
                                    </span>
                                </Typography>
                                <Grid
                                    container
                                    className={"modal-modal-buttons"}
                                    sx={{ mt: 2 }}
                                >
                                    <Button
                                        onClick={handleClose}
                                        className={
                                            "modal-modal-button modal-button-back"
                                        }
                                    >
                                        Volver
                                    </Button>
                                    <Button
                                        onClick={deleteSuscription}
                                        className={
                                            "modal-modal-button modal-button-delete"
                                        }
                                    >
                                        Aceptar
                                    </Button>
                                </Grid>
                                <Typography
                                    id="modal-modal-response"
                                    sx={{ mt: 2 }}
                                >
                                    {response}
                                </Typography>
                            </Grid>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <PortalNavigation />
            <Container maxWidth="md">
                <Grid item className="title-product m-t-10 centered">
                    <h1>Paquete programado</h1>
                </Grid>

                {response?.data?.context ==
                "mobile_subscriber_account_prepaid_subscription_not_found" ? (
                    <Grid container className="suscription-container">
                        <Grid item xs={6}>
                            <img src={Clock} />
                        </Grid>

                        <Grid item xs={6} className="card-suscription">
                            <Grid
                                item
                                xs={12}
                                className="text-container-suscription"
                            >
                                <div className="title-container-suscription">
                                    <p className="color-text-suscription">
                                        Programa
                                    </p>
                                    <p className="color-text-suscription">
                                        tu paquete
                                    </p>
                                    <p className="color-text-Dalefon-tittle">
                                        Dalefon
                                    </p>
                                </div>
                                <p>
                                    Elige el paquete de tu preferencia y en el
                                    formulario de pago selecciona la casilla:
                                    <span>
                                        Renovar mi paquete automáticamente
                                        cuando finalice la vigencia.
                                    </span>
                                </p>

                                <div className="menu-buttons-container suscription-btn">
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className={"menu-buttons pink"}
                                        onClick={goPackage}
                                    >
                                        Ir a paquetes
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid container className="card-suscription-active">
                        <Grid item xs={12} md={6} className="left-column">
                            <Grid container>
                                <Grid item xs={12}>
                                    <p className="color-text-suscription">
                                        {carrier_product?.name}
                                    </p>
                                    <p className="color-text-Dalefon-tittle">
                                        {totalData}GB
                                    </p>
                                    <div className="duration-product m-t-5">
                                        Vigencia de {carrier_product?.duration}{" "}
                                        días
                                    </div>
                                    <div className="duration-product">
                                        Se renueva el
                                        <span className="renew-product">
                                            {moment(
                                                prepaidSubscriptiongObj?.offer_expires_at *
                                                    1000
                                            ).format("L")}
                                        </span>{" "}
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} className="right-column">
                            <Grid
                                container
                                className="card-detail"
                                direction="row"
                                justifyContent="center"
                            >
                                <h1 className=" color-text-programming-package">
                                    Mi paquete incluye
                                </h1>

                                <Grid
                                    item
                                    xs={12}
                                    className="background-choose-renew"
                                >
                                    <Grid container>
                                        <Grid item xs={4} className="display">
                                            <Grid
                                                container
                                                className="product-services-wrapper"
                                            >
                                                <Grid
                                                    item
                                                    xs={8}
                                                    className="product-services-container"
                                                >
                                                    <img src={SMS} />

                                                    <p>
                                                        {carrier_product?.sms}
                                                    </p>
                                                    <span>SMS*</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={4} className={"display"}>
                                            <Grid
                                                container
                                                className="product-services-wrapper"
                                            >
                                                <Grid
                                                    item
                                                    xs={8}
                                                    className="product-services-container"
                                                >
                                                    <img src={Minutos} />
                                                    <p>
                                                        {
                                                            carrier_product?.minutes
                                                        }
                                                    </p>
                                                    <span>Minutos*</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={4}
                                            className="display last-display"
                                        >
                                            <Grid
                                                container
                                                className="product-services-wrapper"
                                            >
                                                <Grid
                                                    item
                                                    xs={8}
                                                    className="product-services-container"
                                                >
                                                    <img src={Cobertura} />
                                                    <p>{}</p>
                                                    <span>Comparte Datos</span>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={
                                                "product-coverage-container"
                                            }
                                        >
                                            <span>
                                                Cobertura en EE.UU y Canadá
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            className={"cancel-suscription-container"}
                        >
                            <Button
                                variant="text"
                                className="button-cancel-suscription"
                                onClick={() => setOpen(true)}
                            >
                                Cancelar renovación
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Container>
            <PortalFooter />
            <DeleteModal />
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    prepaidSubscription: state.prepaidSubscription,
    deleteSubscription: state.deleteSubscription,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    prepaidSubscriptionAction,
    deleteSubscriptionAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(AdminSuscription);
