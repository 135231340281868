export const closeLoggedSession = (from = "default", numberChanged = false) => {
    localStorage.clear();

    if (numberChanged) {
        window.location.href = "/numero-actualizado";
    } else {
        window.location.href = "/login";
    }

    return true;
};
