export const registerList = () => {
    return {
        registerOptions: {
            name: {
                required: "El número de orden es requerido",
                minLength: {
                    value: 3,
                    message: "El número es de mínimo 3 dígitos",
                },
            },
            email: {
                required: "El correo electrónico es requerido",
                pattern: {
                    value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                    message:
                        "El valor ingresado no coincide con el formato del correo electrónico",
                },
            },
            confirm_email: {
                required: "El correo electrónico de confirmación es requerido",
                pattern: {
                    value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                    message:
                        "El valor ingresado no coincide con el formato del correo electrónico",
                },
            },
        },
    };
};
