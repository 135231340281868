import { useEffect, useState } from "react";

//React Router Dom:
import { useNavigate, useParams } from "react-router-dom";

//Images:
import imgFail from "assets/images/portal/pages/wbc-purchase/error-payment.png";
import imgPending from "assets/images/portal/pages/wbc-purchase/pago-pendiente.png";
import imgSuccess from "assets/images/portal/pages/wbc-purchase/success-payment.png";

import { usePixelsPurchase } from "modules/purchase/hook/usePixelsPurchase";
import { usePixelsBuying } from "modules/compras/pages/hooks/usePixelsBuying";

function useWbcPurchasePageHook(props) {
    const history = useNavigate();
    const params = useParams(props);

    const { manualInitPixelsPurchase, pixelPurchase } = usePixelsPurchase({
        ignoreInitialition: true,
    });
    const { manualInitPixels, pixelPurchaseBuying } = usePixelsBuying({
        ignoreInitialition: true,
    });

    const { uuid } = params || {};
    const { purchasePaymentStatus, getPurchasePaymentStatusAction } =
        props || {};
    const { purchasePaymentStatusObj } = purchasePaymentStatus || {};
    const { status, sim_type_id, landing_id } = purchasePaymentStatusObj || {};
    const {
        context,
        detail,
        title,
        additional_details,
        /* SUCCESS:  */
        product_name,
        total_amount,
        order_number,
    } = purchasePaymentStatusObj || {};
    const { exception, payment_order_status } = additional_details || {};
    const currency = "MXN";
    const infoProduct = [
        {
            title: "No. de orden",
            value: order_number,
        },
    ];

    const [loading, setLoading] = useState(true);
    const [dataContentWBCPurchase, setDataContentWBCPurchase] = useState({
        img: null,
        alt: "",
        note: "",
        id: "",
    });

    useEffect(() => {
        if (purchasePaymentStatus?.loading && purchasePaymentStatusObj) {
            setLoading(false);
        }
    }, [purchasePaymentStatusObj]);

    useEffect(() => {
        if (purchasePaymentStatus?.loading) {
            setLoading(false);
        }
    }, [purchasePaymentStatus?.loading]);

    function changeContent(img, alt, note, id) {
        setDataContentWBCPurchase({
            img: img,
            alt: alt,
            note: note,
            id: id,
        });
        setLoading(false);
    }

    useEffect(() => {
        getPurchasePaymentStatusAction({ uuid });
    }, [getPurchasePaymentStatusAction, uuid]);

    useEffect(() => {
        if (status) {
            if (landing_id === 2) {
                manualInitPixelsPurchase();

                pixelPurchase({
                    quantity: 1,
                    name: product_name,
                    sku: purchasePaymentStatusObj.product_sku,
                    currency,
                    amount: total_amount,
                });

                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else if (landing_id === 3) {
                manualInitPixels();

                pixelPurchaseBuying({
                    quantity: 1,
                    name: product_name,
                    sku: purchasePaymentStatusObj.product_sku,
                    currency,
                    amount: total_amount,
                });

                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else if (landing_id === 4) {
                history(`/app/cerrar`, {
                    state: {
                        uuid,
                        successMessageData: purchasePaymentStatusObj || {},
                        supportsDevices: sim_type_id === 2,
                    },
                });
            } else if (landing_id === 5) {
                changeContent(
                    imgSuccess,
                    "Persona con los ojo iluminados por estrellas dando a entender que está asombrado",
                    "",
                    "SUCCESS"
                );
            } else {
                changeContent(
                    imgFail,
                    "Persona asombrado con un icono de error de fondo",
                    "Tú pago no pudo ser procesado:",
                    "FAIL"
                );
            }
        } else if (payment_order_status === "FAIL") {
            changeContent(
                imgFail,
                "Persona asombrado con un icono de error de fondo",
                "Tú pago no pudo ser procesado:",
                "FAIL"
            );
        } else if (
            payment_order_status === "REQUESTED" ||
            payment_order_status === "PENDING"
        ) {
            changeContent(
                imgPending,
                "Persona guiñando el ojo",
                "Tu pago se encuentra pendiente debido al siguiente motivo:",
                "REQUESTED"
            );
        } else if (context === "server_error") {
            changeContent(
                imgFail,
                "Persona asombrado con un icono de error de fondo",
                detail ||
                    "Hubo un error al procesar tu pago, comuníquese a Dalefon.",
                "FAIL"
            );
        }
    }, [purchasePaymentStatus]);

    return {
        loading,
        dataContentWBCPurchase,
        status,
        sim_type_id,
        product_name,
        total_amount,
        detail,
        title,
        history,
        payment_order_status,
        infoProduct,
    };
}

export default useWbcPurchasePageHook;
