import {
    ACCOUNTCARDSTOKENS,
    ACCOUNTCARDSTOKENS_ERROR,
    ACCOUNTCARDSTOKENS_CLEAN,
    ACCOUNTCARDSTOKENS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    accountCardsTokenObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ACCOUNTCARDSTOKENS:
            return {
                ...state,
                accountCardsTokenObj: action.payload,
                loading: true,
            };
        case ACCOUNTCARDSTOKENS_ERROR:
            return {
                ...state,
                accountCardsTokenObj: action.payload,
                loading: false,
            };

        case ACCOUNTCARDSTOKENS_CLEAN:
            return {
                ...state,
                accountCardsTokenObj: action.payload,
                loading: true,
            };
        case ACCOUNTCARDSTOKENS_CLEAN_ERROR:
            return {
                ...state,
                accountCardsTokenObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
