const getStaticStates = () => {
    return {
        states: [
            {
                id: 1,
                iso_name: "AGU",
                name: "Aguascalientes",
            },
            {
                id: 2,
                iso_name: "BCN",
                name: "Baja California",
            },
            {
                id: 3,
                iso_name: "BCS",
                name: "Baja California Sur",
            },
            {
                id: 4,
                iso_name: "CAM",
                name: "Campeche",
            },
            {
                id: 7,
                iso_name: "CHP",
                name: "Chiapas",
            },
            {
                id: 8,
                iso_name: "CHH",
                name: "Chihuahua",
            },
            {
                id: 9,
                iso_name: "CMX",
                name: "Ciudad de México",
            },
            {
                id: 5,
                iso_name: "COA",
                name: "Coahuila de Zaragoza",
            },
            {
                id: 6,
                iso_name: "COL",
                name: "Colima",
            },
            {
                id: 10,
                iso_name: "DUR",
                name: "Durango",
            },
            {
                id: 11,
                iso_name: "GUA",
                name: "Guanajuato",
            },
            {
                id: 12,
                iso_name: "GRO",
                name: "Guerrero",
            },
            {
                id: 13,
                iso_name: "HID",
                name: "Hidalgo",
            },
            {
                id: 14,
                iso_name: "JAL",
                name: "Jalisco",
            },
            {
                id: 15,
                iso_name: "MEX",
                name: "México",
            },
            {
                id: 16,
                iso_name: "MIC",
                name: "Michoacán de Ocampo",
            },
            {
                id: 17,
                iso_name: "MOR",
                name: "Morelos",
            },
            {
                id: 18,
                iso_name: "NAY",
                name: "Nayarit",
            },
            {
                id: 19,
                iso_name: "NLE",
                name: "Nuevo León",
            },
            {
                id: 20,
                iso_name: "OAX",
                name: "Oaxaca",
            },
            {
                id: 21,
                iso_name: "PUE",
                name: "Puebla",
            },
            {
                id: 22,
                iso_name: "QUE",
                name: "Querétaro",
            },
            {
                id: 23,
                iso_name: "ROO",
                name: "Quintana Roo",
            },
            {
                id: 24,
                iso_name: "SLP",
                name: "San Luis Potosí",
            },
            {
                id: 25,
                iso_name: "SIN",
                name: "Sinaloa",
            },
            {
                id: 26,
                iso_name: "SON",
                name: "Sonora",
            },
            {
                id: 27,
                iso_name: "TAB",
                name: "Tabasco",
            },
            {
                id: 28,
                iso_name: "TAM",
                name: "Tamaulipas",
            },
            {
                id: 29,
                iso_name: "TLA",
                name: "Tlaxcala",
            },
            {
                id: 30,
                iso_name: "VER",
                name: "Veracruz de Ignacio de la Llave",
            },
            {
                id: 31,
                iso_name: "YUC",
                name: "Yucatán",
            },
            {
                id: 32,
                iso_name: "ZAC",
                name: "Zacatecas",
            },
        ],
    };
};
export default getStaticStates;
