import {
    LOGIN,
    LOGIN_ERROR,
    LOGIN_CLEAN,
    LOGIN_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    login: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN:
        case LOGIN_CLEAN:
            return {
                ...state,
                login: action.payload,
                status: action.status,
                loading: true,
            };
        case LOGIN_ERROR:
        case LOGIN_CLEAN_ERROR:
            return {
                ...state,
                login: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
