import React from "react";
import { Container, Grid } from "@mui/material";

const PortabilityHeader = (props) => {
    return (
        <div className={"header"}>
            <Container>
                <Grid container className="portability-header">
                    <Grid item xs={12} className="portability-text">
                        <h1>
                            Cambia tu <span>número</span> al anterior
                        </h1>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default PortabilityHeader;
