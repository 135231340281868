import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";

import { Container, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import PortalNavigation from "modules/portal/components/navigation";
import PortabilityHeader from "./header";
import PortalFooter from "modules/portal/components/footer";

import PortabilityImage from "assets/images/portability/portabilidad.png";

import { postPortabilityAction } from "_models/redux/portability/action";
import { cleanPortabilityAction } from "_models/redux/portability/clean-action";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

//Components:
import CustomButtonComponent from "utils/templates/CustomButtonComponent";

const PortabilityContent = (props) => {
    const logFlag = undefined;

    const { postPortabilityAction, cleanPortabilityAction, portability } =
        props;

    const { loaded, payload, status, state } = portability || {};

    const [loaderButton, setLoaderButton] = useState(false);

    const [response, setResponse] = useState("");

    const [nipValue, setNipValue] = useState();
    const [phoneValue, setPhoneValue] = useState();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        // border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    const {
        reset,
        value,
        register,
        getValues,
        handleSubmit,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        destroyMethod();
        return () => {
            destroyMethod();
        };
    }, []);

    const destroyMethod = () => {
        setOpen(false);
        cleanPortabilityAction();
    };

    useEffect(() => {
        const { message, additional_details, context, detail, title, type } =
            payload || {};

        if (loaded) {
            // && state == "error"
        }

        if (loaded && status != 200 && state == "error") {
            let responseContainerCls = "m-t-15 response-container ";
            responseContainerCls +=
                typeof state != "undefined" || state == "error"
                    ? " error "
                    : "";

            setResponse(
                <Grid container className={responseContainerCls}>
                    <Grid item xs={12} className={"response-title"}>
                        {title || ""}
                    </Grid>
                    <Grid item xs={12} className={"response-text"}>
                        {detail || message || ""}
                    </Grid>
                    <button
                        type="button"
                        className={"error-response-button"}
                        onClick={() => setOpen(false)}
                    >
                        Aceptar
                    </button>
                </Grid>
            );
            setOpen(true);
        }

        setLoaderButton(false);
    }, [portability]);

    const onSubmit = async (e) => {
        setLoaderButton(true);

        const post = {
            app_source_id: 3,
            nip: parseInt(e?.nipInput),
            number_to_port: e?.phoneInput,
        };

        if (typeof postPortabilityAction == "function") {
            postPortabilityAction(post);
        }
    };

    const registerOptions = {
        nipInput: {
            required: "NIP es requerido",
            // minLength: {
            //     value: 4,
            //     message: "mínimo 4 caracteres",
            // },
            maxLength: {
                value: 4,
                message: "máximo 4 caracteres",
            },
        },
        phoneInput: {
            required: "Número de celular es requerido",
            // minLength: {
            //     value: 10,
            //     message: "mínimo 10 caracteres",
            // },
            maxLength: {
                value: 10,
                message: "máximo 10 caracteres",
            },
        },
    };

    const ErrorModal = () => {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    <span className={"modal-error-icon"}>
                                        <ErrorOutlineIcon />
                                    </span>
                                    <span className={"modal-error-title"}>
                                        Hubo un problema
                                    </span>
                                </Typography>
                                <Typography
                                    id="modal-modal-response"
                                    sx={{ mt: 2 }}
                                >
                                    {response}
                                </Typography>
                            </Box>
                        </Modal>
                    </div>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <PortalNavigation />
            <PortabilityHeader />

            <Container maxWidth={false} sx={{ backgroundColor: "white" }}>
                <Container>
                    <Grid container className={"portability-container"}>
                        <Grid item md={6} xs={12}>
                            {status != "200" ? (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid
                                        container
                                        className={"portability-form-container"}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className={
                                                "portability-form-content"
                                            }
                                        >
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="portability-instruction">
                                                        <span
                                                            className={
                                                                "pink-number"
                                                            }
                                                        >
                                                            1
                                                        </span>
                                                        <p>
                                                            Con la SIM de tu
                                                            compañía anterior,
                                                            marca al{" "}
                                                            <span
                                                                className={
                                                                    "pink-text"
                                                                }
                                                            >
                                                                051
                                                            </span>{" "}
                                                            o envía un{" "}
                                                            <span
                                                                className={
                                                                    "pink-text"
                                                                }
                                                            >
                                                                SMS
                                                            </span>{" "}
                                                            con la palabra{" "}
                                                            <span
                                                                className={
                                                                    "pink-text"
                                                                }
                                                            >
                                                                NIP
                                                            </span>
                                                            .
                                                        </p>
                                                    </div>

                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        variant="outlined"
                                                        label="NIP"
                                                        placeholder="Ingresa el NIP. Ej. 1234"
                                                        id="nipInput"
                                                        name="nipInput"
                                                        value={nipValue}
                                                        // onChange={onNipChange}
                                                        {...register(
                                                            "nipInput",
                                                            registerOptions?.nipInput
                                                        )}
                                                        className="m-tb-20 form-input"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span>
                                                        {errors?.nipInput && (
                                                            <p className="required-incidence m-0">
                                                                {" "}
                                                                {
                                                                    errors
                                                                        ?.nipInput
                                                                        .message
                                                                }
                                                            </p>
                                                        )}
                                                    </span>
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <div className="portability-instruction">
                                                        <span
                                                            className={
                                                                "pink-number"
                                                            }
                                                        >
                                                            2
                                                        </span>
                                                        <p>
                                                            Ingresa el número
                                                            celular de tu
                                                            compañía anterior.
                                                        </p>
                                                    </div>

                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        label="Número celular"
                                                        placeholder="Número celular a 10 dígitos"
                                                        id="phoneInput"
                                                        name="phoneInput"
                                                        value={phoneValue}
                                                        // onChange={onPhoneChange}
                                                        {...register(
                                                            "phoneInput",
                                                            registerOptions?.phoneInput
                                                        )}
                                                        className="m-tb-20 form-control"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <span>
                                                        {errors?.phoneInput && (
                                                            <p className="required-incidence m-0">
                                                                {" "}
                                                                {
                                                                    errors
                                                                        ?.phoneInput
                                                                        .message
                                                                }
                                                            </p>
                                                        )}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        {/* <Grid>{response}</Grid> */}

                                        <Grid
                                            item
                                            xs={12}
                                            className={"clase-del-boton"}
                                        >
                                            <CustomButtonComponent
                                                bgColor={"primary-purple"}
                                                text={"Cambiar número"}
                                                height={"50px"}
                                                loading={loaderButton}
                                                loadingPosition="end"
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            ) : (
                                <Grid item>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"portability-check"}
                                    >
                                        <CheckCircleIcon />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={"portability-title"}
                                    >
                                        <h3>
                                            Tu cambio de número está en proceso
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "portability-text success-response"
                                        }
                                    >
                                        Pronto podrá seguir utilizando tu número
                                        de siempre con Dalefon.
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}
                            className={"portability-image-container"}
                        >
                            <img
                                src={PortabilityImage}
                                className={"portability-image"}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Container>

            <PortalFooter />
            {/* </Container> */}
            <ErrorModal />
        </div>
    );
};

/** Redux map state to props **/
const mapStateToProps = (state) => ({
    portability: state?.portability,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    postPortabilityAction,
    cleanPortabilityAction,
};

/** Export component connected to redux **/
export default connect(mapStateToProps, mapDispatchToProps)(PortabilityContent);
