export const taxRegimeList = () => {
    return {
        taxRegimeOptions: [
            { id: "601", name: "General de Ley Personas Morales" },
            { id: "603", name: "Personas Morales con Fines no Lucrativos" },
            { id: "606", name: "Arrendamiento" },
            {
                id: "612",
                name: "Personas Físicas con Actividades Empresariales y Profesionales",
            },
            {
                id: "620",
                name: "Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
            },
            { id: "621", name: "Incorporación Fiscal" },
            {
                id: "622",
                name: "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
            },
            { id: "623", name: "Opcional para Grupos de Sociedades" },

            { id: "624", name: "Coordinados" },
            {
                id: "625",
                name: "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
            },

            { id: "626", name: "Régimen Simplificado de Confianza" },
        ],
    };
};
