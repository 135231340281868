export const giftFormInputs = (params) => {
    const { errors, registerOptions, handlers, cfdiUses, taxRegimes } =
        params || {};

    const { onFocusHandler, onChangeHandler } = handlers || {};
    const { cfdiUseOptions, cfdiOptionSelected, setcfdiOptionSelected } =
        cfdiUses || {};
    const { taxRegimeOptions, taxRegimeSelected, setTaxRegimeSelected } =
        taxRegimes || {};

    return {
        inputs: [
            {
                type: "text",
                id: "name",
                label: "Nombre del beneficiario",
                placeholder: "Nombre completo del beneficiario",
                containerCls: "payment-input",
                error: errors?.name,
                validations: registerOptions?.name,
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
            },
            {
                type: "email",
                id: "email",
                label: "Correo electrónico del beneficiario",
                placeholder: "Correo electrónico",
                containerCls: "payment-input m-t-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.email,
                error: errors?.email,
            },
            {
                type: "email",
                id: "confirm_email",
                label: "Confirma el correo electrónico del beneficiario",
                placeholder: "Confirma el correo electrónico",
                containerCls: "payment-input m-t-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.confirm_email,
                error: errors?.confirm_email,
            },
            {
                type: "text",
                id: "message",
                label: "Mensaje para el beneficiario",
                placeholder: "Escribe un mensaje (opcional)",
                containerCls: "payment-input m-t-20",
                onFocusHandler: onFocusHandler,
                onChangeHandler: onChangeHandler,
                validations: registerOptions?.message,
                error: errors?.message,
            },
        ],
    };
};

export default giftFormInputs;
