import React from "react";

// Material Ui:
import { Box } from "@mui/material";

// Swiper:
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Images:
import imgShareWifi from "assets/images/utils/components/section-carousel-product/hotspot-share.svg";
import imgNoShareWifi from "assets/images/utils/components/section-carousel-product/no-hotspot.svg";

//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";

function ContentPrepaidsProductsComponents({
    shareSorted,
    notShareSorted,
    tabIndex,
    flow,
}) {
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
              ? AProductCardsItem
              : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    return (
        <Box mt={3}>
            {/*** RECARGAS ***/}
            <SectionCarouselProductComponent
                title={"No comparte datos"}
                img={imgNoShareWifi}
                alt={"Imagen sobre una antena que NO comparte Wifi"}
                showButton={false}
            >
                {notShareSorted?.map((element, i) => {
                    let recommendCls = "";
                    if (element.price === 200) {
                        recommendCls =
                            i === 0 ? "recommended" : "recommended mobile";
                    }

                    return (
                        <SwiperSlide key={element.name} style={swiperStyle}>
                            <ProductCard
                                index={i}
                                colorClass={tabIndex}
                                element={element}
                                recommendCls={recommendCls}
                            />
                        </SwiperSlide>
                    );
                })}
            </SectionCarouselProductComponent>

            <SectionCarouselProductComponent
                title={"Comparte datos"}
                img={imgShareWifi}
                alt={"Imagen sobre una antena que comparte Wifi"}
                showButton={false}
            >
                {shareSorted.map((element, i) => {
                    return (
                        <SwiperSlide key={element.name} style={swiperStyle}>
                            <ProductCard
                                key={i}
                                index={i}
                                colorClass={tabIndex}
                                element={element}
                                recommendCls={""}
                            />
                        </SwiperSlide>
                    );
                })}
            </SectionCarouselProductComponent>
        </Box>
    );
}

export default ContentPrepaidsProductsComponents;
