import {
    DELETE_SUBSCRIPTION,
    DELETE_SUBSCRIPTION_ERROR,
} from "_models/redux/types";

const initialState = {
    deleteSubscriptiongObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_SUBSCRIPTION:
            return {
                ...state,
                deleteSubscriptiongObj: action.payload,
                loading: true,
            };
        case DELETE_SUBSCRIPTION_ERROR:
            return {
                ...state,
                deleteSubscriptiongObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
