import {
    MUNICIPALITIES_CLEAN,
    MUNICIPALITIES_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanMunicipalitiesAction = () => async (dispatch) => {
    try {
        dispatch({
            type: MUNICIPALITIES_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: MUNICIPALITIES_CLEAN_ERROR,
            payload: [],
        });
    }
};
