export const getCfdiUseOptions = () => {
    return {
        cfdiUseOptions: [
            { id: "0", name: "Seleccione una opción" },
            { id: "G01", name: "G01 - Adquisición de mercancías" },
            { id: "G03", name: "G03 - Gastos en general" },
            { id: "I06", name: "I06 - Comunicaciones telefónicas" },
        ],
    };
};
