export const gridContainerLogin = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
};

export const containerFormLogin = {
    mt: { xs: 5, md: 0 },
    mb: { xs: 3, md: 0 },
    backgroundColor: "white",
    borderRadius: 7,
    px: { xs: 3, md: 9 },
    pt: { xs: 0, md: 2 },
    pb: { xs: 1, md: 1 },
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 25px 50px -12px",
};

export const titleContainer = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    mt: { xs: 3 },
};

export const iconArrowBack = {
    fontSize: 32,
    color: "white",
    mb: { xs: 1, md: 1 },
    mt: 0,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};

export const typographyTitleOne = {
    fontSize: { xs: 45, md: 65 },
    color: "white",
    lineHeight: { xs: 1.5, md: 1.2 },
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
    textAlign: { xs: "center", md: "start" },
};

export const typographyTitleTwo = {
    fontSize: { xs: 55, md: 80 },
    color: "#a361ff",
    lineHeight: 1,
    fontFamily: "Poppins-Regular",
    fontWeight: 700,
};

export const linkContainerForgotPassword = {
    mt: 2,
};

export const linkForgotPassword = {
    linkDecoration: "none",
    color: "#5f5f5f",
    textTransform: "none",
    fontSize: 13.5,
    fontFamily: "Poppins-Regular",
    fontWeight: 500,
    "&:hover": {
        color: "#a361ff",
        backgroundColor: "transparent",
        fontWeight: 600,
    },
};
