import { display } from "@mui/system";

export const gridContainer = {
    pb: { xs: 0, md: 2 },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "white",
    display: "flex",
    justifyContent: "center",
};

export const typographyTitle = {
    fontSize: { xs: 30, md: 37 },
    fontWeight: 700,
};

export const typographyDescription = {
    mt: 3,
    fontSize: 16,
    mb: 4,
    lineHeight: { xs: 1.7, md: 1.6 },
    px: { xs: 3 },
};

export const buttonContainer = {
    mt: 1,
    width: "100%",
};

export const imageContainer = {
    mb: 2,
};
