import {
    MUNICIPALITIES,
    MUNICIPALITIES_ERROR,
    MUNICIPALITIES_CLEAN,
    MUNICIPALITIES_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    municipalitiesObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MUNICIPALITIES:
            return {
                ...state,
                municipalitiesObj: action.payload,
                loading: true,
            };
        case MUNICIPALITIES_ERROR:
            return {
                ...state,
                municipalitiesObj: action.payload,
                loading: false,
            };
        case MUNICIPALITIES_CLEAN:
        case MUNICIPALITIES_CLEAN_ERROR:
            return {
                ...state,
                municipalitiesObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
