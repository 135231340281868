import { GETOFFERS_CLEAN, GETOFFERS_CLEAN_ERROR } from "_models/redux/types";

export const cleanGetOffersAction = () => async (dispatch) => {
    try {
        dispatch({
            type: GETOFFERS_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: GETOFFERS_CLEAN_ERROR,
            payload: [],
        });
    }
};
