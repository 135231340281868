import axiosAuthInstance from "../../../services/api/authentication";
import {
    CHECKING_COMPATIBLE_DEVICE,
    CHECKING_COMPATIBLE_DEVICE_ERROR,
} from "_models/redux/types";

export const postCheckingCompatibleDevice = (params) => async (dispatch) => {
    const { device_id, dataToken } = params || {};

    try {
        const response = await axiosAuthInstance.post(
            `/api/leads/steps/devices`,
            { device_id },
            {
                headers: {
                    Authorization: `Bearer ${dataToken}`,
                },
            }
        );

        dispatch({
            type: CHECKING_COMPATIBLE_DEVICE,
            payload: response,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: CHECKING_COMPATIBLE_DEVICE_ERROR,
            payload: e || {},
        });
    }
};
