import { alpha } from "@mui/material/styles";

export const menuMuiPapper = (theme) => {
    return {
        borderRadius: 6,
        marginTop: theme.spacing(5),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&a:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    };
};

export const appBarStyle = {
    bgcolor: "transparent",
    boxShadow: "none",
    margin: "0 auto",
    maxWidth: { xs: "100%", md: "1200px" },
};

export const boxContainerMenuPhoneHamburger = {
    flexGrow: 1,
    display: { xs: "flex", md: "none" },
};

export const boxContainerMenuDesktop = {
    flexGrow: 1,
    justifyContent: "flex-end",
    paddingRight: { xs: "none", md: "20px" },
    display: { xs: "none", md: "flex" },
};

export const buttonMenuDesktopStyle = {
    my: 2,
    color: "white",
    display: "white",
    justifyContent: "flex-end",
    padding: "0 20px",
    fontSize: 16,
    textTransform: "none",
    "&hover": {
        color: "blue !important",
    },
};

export const boxContainerMenuAccountDesktop = {
    display: { xs: "none", md: "flex" },
    mr: 3,
    alignText: "end",
};

export const boxContainerMenuAccountPhone = {
    flexGrow: 0,
    display: { xs: "flex", md: "none" },
};

export const gridContainerMenuAccountDesktop = {
    display: { xs: "none", md: "flex" },
    mr: 1,
};
