import { useEffect, useState } from "react";

//React Hook Form:
import { useForm } from "react-hook-form";

//React Router Dom:
import { useNavigate } from "react-router-dom";

//Controllers:
import { getCampaingData } from "utils/helpers/localstorage/campaing";
import { usePixelsPurchase } from "./usePixelsPurchase";

export default function usePurchaseHook({
    addPurchaseContactInfoAction,
    cleanPurchaseContactInfoAction,
    initRegisterAction,
    addPurchaseContactInfo,
    initRegister,
}) {
    const { addShippingInfo } = usePixelsPurchase({});

    const navigate = useNavigate();

    const sourceId = 1;
    const [channelId, setChannelId] = useState();
    const [campaingId, setCampaingId] = useState();
    const [dataInitRegister, setDataInitRegister] = useState("");
    const [contactPhone, setContactPhone] = useState("");
    const [contactEmail, setContactEmail] = useState("");
    const [contactFullName, setContactFullName] = useState("");
    const [checkedConditions, setCheckedConditions] = useState(false);
    const [getLoading, setGetLoading] = useState(false);
    const [submitLoading, setLoading] = useState(false);
    const [detail, setDetail] = useState();

    useEffect(() => {
        const { campaingId: cid, channelId: chid } = getCampaingData();
        setCampaingId(cid);
        setChannelId(chid);
    }, []);

    useEffect(() => {
        const { initRegisterObj } = initRegister || {};
        const { data } = initRegisterObj || {};
        setGetLoading(false);

        if (initRegister?.initRegisterObj?.status === 200) {
            setDataInitRegister(data);
            setGetLoading(false);
        } else if (initRegister?.initRegisterObj?.status > 400) {
            navigate(`/purchase`);
        }
    }, [initRegister]);

    useEffect(() => {
        initSubmitRegister();
        setGetLoading(true);
    }, [channelId, campaingId]);

    useEffect(() => {
        if (addPurchaseContactInfo?.response?.status === 200) {
            addShippingInfo({
                contact: {
                    email: contactEmail,
                    phone: contactPhone,
                },
            });
            setLoading(false);
            navigate(`/purchase/compatibility`, {
                state: { dataToken: dataInitRegister?.token },
            });
        } else {
            if (
                addPurchaseContactInfo?.addPurchaseContactInfoObj?.data?.detail
            ) {
                setDetail(
                    addPurchaseContactInfo?.addPurchaseContactInfoObj?.data
                        ?.detail
                );
            } else if (
                addPurchaseContactInfo?.addPurchaseContactInfoObj?.detail
            ) {
                setDetail(
                    addPurchaseContactInfo?.addPurchaseContactInfoObj?.detail
                );
            } else if (addPurchaseContactInfo?.response?.detail) {
                setDetail(addPurchaseContactInfo?.response?.detail);
            }
        }

        if (
            addPurchaseContactInfo?.addPurchaseContactInfoObj?.status ===
            undefined
        ) {
            setLoading(false);
        }
    }, [addPurchaseContactInfo]);

    useEffect(() => {
        return () => {
            cleanPurchaseContactInfoAction();
        };
    }, [addPurchaseContactInfo?.addPurchaseContactInfoObj?.data]);

    const initSubmitRegister = (_e) => {
        if (channelId !== undefined && campaingId !== undefined) {
            const { referenceLead } = getCampaingData();
            const init = {
                source_id: parseInt(sourceId),
                channel_id: parseInt(channelId),
                campaing_id: parseInt(campaingId),
                landing_id: 2,
                reference: referenceLead,
            };

            initRegisterAction(init);
        }
    };

    const handleChangeConditions = (event) => {
        setCheckedConditions(event.target.checked);
    };

    const registerOptions = {
        contactFullName: {
            required: "Nombre Completo es requerido",
            minLength: 2,
            maxLength: 250,
        },
        contactPhone: {
            required: "Número telefónico es requerido",
            maxLength: {
                value: 10,
                message: "Teléfono a 10 dígitos",
            },
            // minLength: {
            //     value: 10,
            //     message: "Número telefónico a 10 dígitos",
            // },
            pattern: {
                value: /[0-9]{10}/,
                message: " Número telefónico a 10 dígitos",
            },
        },
        contactEmail: {
            // required: "Correo electrónico es requerido",
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (e) => {
        setLoading(true);

        let post = {};

        if (contactEmail) {
            post = {
                name: contactFullName,
                contactPhone: contactPhone,
                email: contactEmail,
                token: dataInitRegister?.token,
            };
        } else {
            post = {
                name: contactFullName,
                contactPhone: contactPhone,
                token: dataInitRegister?.token,
            };
        }

        addPurchaseContactInfoAction(post);
    };
    return {
        handleChangeConditions,
        registerOptions,
        register,
        handleSubmit,
        errors,
        onSubmit,
        contactPhone,
        setContactPhone,
        contactEmail,
        setContactEmail,
        contactFullName,
        setContactFullName,
        checkedConditions,
        getLoading,
        submitLoading,
        detail,
    };
}
