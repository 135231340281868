import React from "react";

//CSS:
import "assets/styles/css/utils/components/flows-products/components/content-no-plans-products.css";

// Material Ui:
import { Box, Grid } from "@mui/material";

// Swiper:
import { SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

//Images:
import Classic from "assets/images/products/Classic.png";
import imgNoPlansPromoLite from "assets/images/utils/components/section-show-title/banner-meses-desk-LITE.png";
import imgNoPlansPromoLiteMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-LITE.png";
import imgNoPlansPromoClassic from "assets/images/utils/components/section-show-title/banner-meses-desk-CLASSIC.png";
import imgNoPlansPromoClassicMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-CLASSIC.png";
import imgNoPlansPromoSlimMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-PLUS.png";
import imgNoPlansPromoFitMobile from "assets/images/utils/components/section-show-title/banner-meses-movil-fit.png";
import Slim from "assets/images/products/Slim.png";
import Fit from "assets/images/products/Fit.png";
import imgNoPlansPromoFit from "assets/images/utils/components/section-show-title/banner-meses-desktop-fit.png";
import imgNoPlansPromoSlim from "assets/images/products/recurso-slim.png";
import Litev2 from "assets/images/products/Litev2.png";
//Components:
import AProductCardsItem from "modules/purchase/pages/widgets/productCardsItem";
import BProductCardsItem from "modules/compras/pages/widgets/productCardsItem";
import SectionCarouselProductComponent from "utils/components/section-carousel-product/SectionCarouselProductComponent";
import ProductCardsItemPrepaid from "modules/portal/pages/products/widgets/ProductCardsItemPrepaid";

function ContentNoPlansProductsComponents({
    classicProducts,
    slimProducts,
    fitProducts,
    liteProducts,
    flow,
}) {
    console.log("products:", {
        classicProducts,
        slimProducts,
        fitProducts,
        liteProducts,
        flow,
    });
    console.log("flow:", flow);
    const ProductCard =
        flow === "prepaid"
            ? ProductCardsItemPrepaid
            : flow === "purchase"
              ? AProductCardsItem
              : BProductCardsItem;

    const swiperStyle = {
        height: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const boxImgPromoResponsive = {
        display: { xs: "flex", md: "none" },
        my: 1,
    };

    const boxImgPromoInterestFree = {
        pt: { xs: 2, md: 2 },
        display: { xs: "none", md: "flex" },
    };

    return (
        <Box style={{ backgroundColor: "#ffff" }}>
            {/*** NO Planes - Slim ***/}
            {slimProducts?.length > 0 && (
                <>
                    <Box sx={{ mt: 10, textAlign: "center" }}>
                        <img
                            src={Slim}
                            alt="Titulo de los paquetes Plus"
                            className="img-title-no-plans"
                            style={{ marginTop: "50px" }}
                        />
                        <Box>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoSlim}
                                    alt="Banner de promoción de planes Lite"
                                    sx={{
                                        maxWidth: "45%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                        "@media (max-width: 900px)": {
                                            height: "180px",
                                        },
                                        ...boxImgPromoInterestFree,
                                    }}
                                />
                                <Box sx={boxImgPromoResponsive}>
                                    <Box
                                        component="img"
                                        src={imgNoPlansPromoSlimMobile}
                                        alt="Banner de promoción de planes Lite en móvil"
                                        sx={{
                                            width: "fit-content",
                                            height: "110px",
                                            "@media (max-width: 900px)": {
                                                width: "100%",
                                                height: "180px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {slimProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const gigaBytes =
                                    (mobile_data +
                                        mobile_data_roaming +
                                        mobile_data_throttling) /
                                    gigabyte_divisor;

                                return (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={2}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls=""
                                        />
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}

            {/*** NO Planes - FIT ***/}
            {fitProducts.length > 0 && (
                <>
                    <Box sx={{ mt: 10, textAlign: "center" }}>
                        <img
                            src={Fit}
                            alt="Titulo de los paquetes Fit"
                            className="img-title-no-plans"
                        />
                        <Box>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoFit}
                                    alt="Banner de promoción de planes Fit"
                                    sx={{
                                        maxWidth: "45%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                        "@media (max-width: 900px)": {
                                            height: "180px",
                                        },
                                        ...boxImgPromoInterestFree,
                                    }}
                                />
                                <Box sx={boxImgPromoResponsive}>
                                    <Box
                                        component="img"
                                        src={imgNoPlansPromoFitMobile}
                                        alt="Banner de promoción de planes Lite en móvil"
                                        sx={{
                                            width: "fit-content",
                                            height: "110px",
                                            "@media (max-width: 900px)": {
                                                width: "100%",
                                                height: "180px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {fitProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const gigaBytes =
                                    (mobile_data +
                                        mobile_data_roaming +
                                        mobile_data_throttling) /
                                    gigabyte_divisor;

                                return (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={3}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls=""
                                        />
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}

            {/*** NO Planes - LITE ***/}
            {liteProducts.length > 0 && (
                <>
                    <Box sx={{ mt: 5, textAlign: "center" }}>
                        <img
                            src={Litev2}
                            alt="Titulo de los paquetes Lite"
                            className="img-title-no-plans"
                        />
                        <Grid
                            container
                            display={"flex"}
                            justifyContent={"center"}
                        >
                            <Box
                                component="img"
                                src={imgNoPlansPromoLite}
                                alt="Banner de promoción de planes Lite"
                                sx={{
                                    maxWidth: "45%",
                                    height: "auto",
                                    display: "block",
                                    margin: "0 auto",
                                    "@media (max-width: 900px)": {
                                        height: "180px",
                                    },
                                    ...boxImgPromoInterestFree,
                                }}
                            />

                            <Box sx={boxImgPromoResponsive}>
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoLiteMobile}
                                    alt="Banner de promoción de planes Lite en móvil"
                                    sx={{
                                        width: "fit-content",
                                        height: "110px",
                                        "@media (max-width: 900px)": {
                                            width: "100%",
                                            height: "180px",
                                        },
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {liteProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    is_monthly,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const sumGigaBytes =
                                    mobile_data +
                                    mobile_data_roaming +
                                    mobile_data_throttling;
                                const gigaBytes =
                                    sumGigaBytes / gigabyte_divisor;

                                return is_monthly && gigaBytes <= 15 ? (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={0}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                is_monthly,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls={""}
                                        />
                                    </SwiperSlide>
                                ) : null;
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}

            {/*** NO Planes - CLÁSICOS ***/}
            {classicProducts.length > 0 && (
                <>
                    <Box sx={{ mt: 10, textAlign: "center" }}>
                        <img
                            src={Classic}
                            alt="Titulo de los paquetes Classic"
                            className="img-title-no-plans"
                        />
                        <Box>
                            <Grid
                                container
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Box
                                    component="img"
                                    src={imgNoPlansPromoClassic}
                                    alt="Banner de promoción de planes Lite"
                                    sx={{
                                        maxWidth: "45%",
                                        height: "auto",
                                        display: "block",
                                        margin: "0 auto",
                                        "@media (max-width: 900px)": {
                                            height: "180px",
                                        },
                                        ...boxImgPromoInterestFree,
                                    }}
                                />
                                <Box sx={boxImgPromoResponsive}>
                                    <Box
                                        component="img"
                                        src={imgNoPlansPromoClassicMobile}
                                        alt="Banner de promoción de planes Lite en móvil"
                                        sx={{
                                            width: "fit-content",
                                            height: "110px",
                                            "@media (max-width: 900px)": {
                                                width: "100%",
                                                height: "180px",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Box>
                    </Box>
                    <SectionCarouselProductComponent showButton={false}>
                        {classicProducts.map(
                            (
                                {
                                    mobile_data,
                                    mobile_data_roaming,
                                    mobile_data_throttling,
                                    gigabyte_divisor,
                                    ...rest
                                },
                                i
                            ) => {
                                const gigaBytes =
                                    (mobile_data +
                                        mobile_data_roaming +
                                        mobile_data_throttling) /
                                    gigabyte_divisor;

                                return (
                                    <SwiperSlide
                                        key={rest.name}
                                        style={swiperStyle}
                                    >
                                        <ProductCard
                                            key={i}
                                            index={i}
                                            colorClass={1}
                                            element={{
                                                mobile_data,
                                                mobile_data_roaming,
                                                mobile_data_throttling,
                                                ...rest,
                                            }}
                                            gigaBytesParent={gigaBytes}
                                            recommendCls=""
                                        />
                                    </SwiperSlide>
                                );
                            }
                        )}
                    </SectionCarouselProductComponent>
                </>
            )}
        </Box>
    );
}

export default ContentNoPlansProductsComponents;
