import axiosAuthInstance from "../../../services/api/authentication";
import {
    GET_DEVICES_MODELS,
    GET_DEVICES_MODELS_ERROR,
} from "_models/redux/types";

export const listDevicesModels = (params) => async (dispatch) => {
    const model_id = params;

    try {
        const response = await axiosAuthInstance.get(
            `/api/leads/devices/models?brand_id=${model_id}`
        );

        const { data } = response;

        dispatch({
            type: GET_DEVICES_MODELS,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GET_DEVICES_MODELS_ERROR,
            payload: e || {},
        });
    }
};
