import React, { useContext, useEffect, useState, useRef } from "react";
import { InvoiceContext } from "../data/invoiceContext";
import { Grid, Box } from "@mui/material";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { postRequest } from "./postRequest";
import successImage from "assets/images/invoice/success.png";
import pdfImage from "assets/images/invoice/pdf.png";
import xmlImage from "assets/images/invoice/xml.png";
import { useParams } from "react-router-dom";

function ComponentHelp() {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep1 } = invoiceContext || {};
    const { stepOneInvoice } = invoiceStep1 || {};

    const recaptchaRef = useRef(null);

    const { uuid: urlUuid = undefined } = useParams() || {};
    const uuid =
        stepOneInvoice?.payload?.uuid ||
        (stepOneInvoice &&
            stepOneInvoice.payload &&
            stepOneInvoice.payload.uuid) ||
        urlUuid ||
        undefined;

    const { executeRecaptcha } = useGoogleReCaptcha();
    const [dataGenerateLinks, setDataGenerateLinks] = useState({
        uuid: "",
        recaptcha_token: "",
    });
    const [dataLinks, setDataLinks] = useState(null);
    const [loading, setLoading] = useState(true);

    const renderRecaptcha = async () => {
        const recaptchaContainer = document.getElementById(
            "recaptcha-show-invoices"
        );

        if (recaptchaContainer && executeRecaptcha) {
            try {
                const token = await executeRecaptcha?.(
                    "generate_download_links"
                );

                setDataGenerateLinks({
                    uuid: uuid,
                    recaptcha_token: token,
                });

                setLoading(false);
            } catch (error) {
                // Handle any errors that might occur during reCAPTCHA execution
                console.error("Error executing reCAPTCHA:", error);
            }
        }
    };

    useEffect(() => {
        const checkRecaptchaAndRender = async () => {
            if (executeRecaptcha) {
                try {
                    await executeRecaptcha?.("generate_download_links");
                } catch (error) {
                    // Handle any errors that might occur during reCAPTCHA execution
                    console.error("Error executing reCAPTCHA:", error);
                }
            }
        };

        checkRecaptchaAndRender();
    }, [executeRecaptcha, uuid]);

    useEffect(() => {
        const postData = async () => {
            try {
                const url = `${process.env.REACT_APP_API_URL}/api/transactions/invoices/generate-links`;
                const datos = dataGenerateLinks;
                const response = await postRequest(url, datos);

                if (response.ok) {
                    console.log("Solicitud POST enviada con éxito");
                    const data = await response.json();
                    setDataLinks(data);
                } else {
                    const payload = await response.json();
                    console.log("Error al enviar la solicitud:", payload);
                }
            } catch (error) {
                console.error("Error al enviar la solicitud ERROR:", error);
            }
        };

        if (dataGenerateLinks.recaptcha_token !== "") postData();
    }, [uuid, dataGenerateLinks]);

    useEffect(() => {
        // Observador para detectar cambios en el DOM del reCAPTCHA
        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (
                    mutation.type === "childList" &&
                    mutation.addedNodes.length > 0
                ) {
                    console.log(
                        "El reCAPTCHA se ha renderizado",
                        executeRecaptcha
                    );
                    if (executeRecaptcha !== undefined) {
                        renderRecaptcha();
                    }
                }
            }
        });

        if (recaptchaRef.current) {
            observer.observe(recaptchaRef.current, { childList: true });
        }

        // Detener la observación cuando el componente se desmonta
        return () => {
            observer.disconnect();
        };
    }, [executeRecaptcha]);

    return (
        <Grid
            container
            className="invoice-download-invoice invoice-wrapper"
            display={"flex"}
            justifyContent={"center"}
        >
            {loading && <div>Loading reCAPTCHA...</div>}

            {!loading && dataLinks !== null
                ? dataLinks.links.map((element, index) => (
                      <Grid item xs={12} key={index}>
                          <Grid
                              container
                              display={"flex"}
                              justifyContent={"center"}
                              mt={4}
                          >
                              <Grid item xs={12}>
                                  <img
                                      src={successImage}
                                      className={"success-image"}
                                  />
                                  <h1>{element.filename}</h1>
                                  <p>Tu factura se generó con éxito.</p>
                              </Grid>
                              <Grid item xs={12} className="download-form">
                                  <Grid item xs={12} md={6}>
                                      <a href={element.pdf_url}>
                                          <img
                                              src={pdfImage}
                                              className={"pdf-image"}
                                          />
                                      </a>
                                      <h3>Descargar PDF</h3>
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                      <a href={element.xml_url}>
                                          <img
                                              src={xmlImage}
                                              className={"xml-image"}
                                          />
                                      </a>
                                      <h3>Descargar XML</h3>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Grid>
                  ))
                : null}

            <Grid
                item
                xs={12}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 5,
                    mb: 3,
                }}
            >
                <Box>
                    <Box id="recaptcha-show-invoices" ref={recaptchaRef}></Box>
                </Box>
            </Grid>
        </Grid>
    );
}

export default ComponentHelp;
