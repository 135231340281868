import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose,
} from "redux";
import { thunk } from "redux-thunk";

import rootReducer from ".";

const initalState = {};

const middleware = [thunk];

const Store = createStore(
    rootReducer,
    initalState,
    compose(applyMiddleware(...middleware))
    // composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
