import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import forgotPasswordSuccessImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import { useNavigate } from "react-router-dom";

const SuccessForgotPasswordPage = () => {
    let navigate = useNavigate();
    let pathSuccess = `/sign-up/security-code`;
    setTimeout(() => {
        navigate(pathSuccess);
    }, 3000);
    useEffect(() => {
        return () => {};
    }, []);
    return (
        <Grid container className={"forgot-password-page"}>
            <Grid container className={"forgot-password-component-container"}>
                <Grid container className={"forgot-password-component"}>
                    <Grid item xs={12} className={"forgot-password-form"}>
                        <Grid
                            item
                            md={12}
                            lg={6}
                            className={"forgot-password-image-container"}
                        >
                            <img
                                title="Forgot Password Success Image"
                                alt="Forgot Password Success Image"
                                src={forgotPasswordSuccessImage}
                                className={"forgot-password-image"}
                            />
                        </Grid>

                        <Grid
                            item
                            md={12}
                            lg={6}
                            mt={{ xs: 5, md: 2 }}
                            className={"forgot-password-component-container"}
                        >
                            <Grid
                                item
                                xs={12}
                                className={"forgot-password-component"}
                            >
                                <Grid
                                    container
                                    className={"forgot-password-form no-form"}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "forgot-password-title-container"
                                        }
                                    >
                                        <h3
                                            className={"response-title success"}
                                        >
                                            Se está procesando tu solicitud
                                        </h3>
                                    </Grid>
                                    <Grid
                                        container
                                        className={
                                            "forgot-password-text-container"
                                        }
                                    >
                                        <p className={"forgot-password-text"}>
                                            Hemos enviado un mensaje a tu
                                            bandeja de correo electrónico con
                                            las instrucciones para reestablecer
                                            tu contraseña.
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormFooterComponent />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SuccessForgotPasswordPage;
