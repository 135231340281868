import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid, Box, Button } from "@mui/material";

//Styles MUI:
import { buttonText, leyendText } from "./styles-mui/logInWidgetStyleMUI";

const LoginLinkComponent = () => {
    const history = useNavigate();
    const goToLogin = () => {
        history("/login");
    };

    return (
        <Grid container display={"flex"} justifyContent={"center"}>
            <Box sx={leyendText}>
                <p>
                    Si ya tienes una cuenta,
                    <Button sx={buttonText} onClick={goToLogin}>
                        <b>
                            <span>inicia sesión.</span>
                        </b>
                    </Button>
                </p>
            </Box>
        </Grid>
    );
};

export default LoginLinkComponent;
