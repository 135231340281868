import React, { useEffect } from "react";

import { Grid } from "@mui/material";

import ActivationContainer from "./container";

const ActivationPage = () => {
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    return (
        <Grid container className={"main-page"}>
            <Grid item className={"main-component-container"}>
                <ActivationContainer />
            </Grid>
        </Grid>
    );
};

export default ActivationPage;
