import { useEffect } from "react";

export const useSignUp = (props = {}) => {
    const { signup, signupAction } = props;

    const sendMethod = (data = {}) => {
        signupAction(data);
    };

    return { sendMethod };
};
