import React, { useEffect } from "react";
import { Container, Grid } from "@mui/material";
import { connect } from "react-redux";
import PromotionsHeader from "../components/header";
import PromotionsContent from "../components/content";
import PortalNavigation from "modules/portal/components/navigation";
import PortalFooter from "modules/portal/components/footer";

const Promotions = (props) => {
    return (
        <div>
            <PortalNavigation />
            <PromotionsHeader />
            <PromotionsContent />
            <PortalFooter />
        </div>
    );
};

/** Export component connected to redux **/
export default Promotions;
