import React from "react";
import PortalNavigation from "./components/navigation";
import HomePage from "modules/portal/pages/home/page";

import PortalFooter from "./components/footer";

const PortalPage = () => {
    return (
        <div>
            <PortalNavigation />
            <HomePage />
            <PortalFooter />
        </div>
    );
};

export default PortalPage;
