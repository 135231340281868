import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

//Redux:
import { connect } from "react-redux";
import { setPasswordAction } from "../../_models/redux/set-password/action";

//Material UI:
import { Container, Grid, Box, Typography, TextField } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

//Hooks:
import { useForm } from "react-hook-form";

//Components:
import PasswordStrengthBar from "react-password-strength-bar";
import CustomButtonComponent from "utils/templates/CustomButtonComponent";
import BaseFirstStepsComponent from "utils/components/base-first-steps/BaseFirstStepsComponent";

//Recaptcha:
import FormRecaptchaComponent from "../../utils/helpers/recaptcha/form-recaptcha-component";

//YUP:
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//Styles MUI:
import {
    gridContainer,
    titleContainer,
    formContainer,
    typographyTitleOne,
    typographyTitleTwo,
    typographyDescription,
    buttonContainer,
} from "./styles-mui/resetPasswordStyleMUI";

const ResetPasswordPage = (props) => {
    let navigate = useNavigate();
    const location = useLocation();
    const { contactPhone, pin } = location.state || {};

    const { setPassword } = props;
    const { newPasswordObj } = setPassword || "undefined";
    const { response, message } = newPasswordObj;
    const { data, status } = response || "undefined";
    const { detail } = data || "undefined";
    const [loading, setLoading] = useState(false);
    const [confirmPwd, createSetPassword] = useState("");
    const [password, createPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);

    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState();
    const [reCaptchaSubmit, setReCaptchaSubmit] = useState();
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "";
    const reCaptchaAction = "set_password";
    const reCaptchaValues = {
        reCaptchaToken,
        setReCaptchaToken,
        reCaptchaSubmit,
        setReCaptchaSubmit,
        reCaptchaKey,
        reCaptchaAction,
    };

    useEffect(() => {
        setReCaptchaSubmit();
        setReCaptchaToken();
    }, [newPasswordObj]);

    const handleClickShowPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);
    const handleMouseDownPasswordRepeat = () =>
        setShowPasswordRepeat(!showPasswordRepeat);

    if (message === "success") {
        // Borrar 'textFieldValue' del localStorage
        localStorage.removeItem("textFieldValue");

        // Navegar a la nueva ruta
        let setPasswordPath = `/reset-password-success-page`;
        navigate(setPasswordPath);
    }

    const formSchema = Yup.object().shape({
        password: Yup.string()
            .required("La contraseña es obligatoria")
            .min(8, "La contraseña debe tener 8 caracteres"),
        confirmPwd: Yup.string()
            .required("La contraseña es obligatoria")
            .oneOf([Yup.ref("password")], "Las contraseñas no coinciden")
            .matches(
                /^(?=.*\d).{8,}$/,
                "Mín. 8 caracteres entre números y letras"
            ),
    });
    const formOptions = { resolver: yupResolver(formSchema) };
    const { register, handleSubmit, value, getValues, formState } =
        useForm(formOptions);

    const { errors } = formState;

    const onSubmit = (creatingPassword) => {
        setLoading(true);
        //   creatingPassword.preventDefault();
        setReCaptchaSubmit({ status: "clicked", e: creatingPassword });
    };

    useEffect(() => {
        const { e } = reCaptchaSubmit || {};

        if (reCaptchaToken && e) {
            const { confirmPwd } = e;
            const post = {
                direct_number: contactPhone,
                pin: parseInt(pin),
                new_password: confirmPwd,
                recaptcha_token: reCaptchaToken,
            };

            props.setPasswordAction(post);
        }
    }, [reCaptchaToken]);

    useEffect(() => {
        if (status >= 400) {
            setLoading(false);
        }
    }, [setPassword]);

    return (
        <BaseFirstStepsComponent>
            <Container maxWidth="lg" sx={gridContainer}>
                <Grid container display={"flex"} alignItems={"center"}>
                    <Grid item xs={12} md={7} sx={titleContainer}>
                        <Typography sx={typographyTitleOne}>Nueva</Typography>
                        <Typography sx={typographyTitleTwo}>
                            Contraseña
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={formContainer}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Typography sx={typographyDescription}>
                                Ingresa tu nueva contraseña.
                            </Typography>

                            <Grid container>
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        {...register("password")}
                                        fullWidth
                                        helperText="Mín. 8 caracteres entre números y letras"
                                        id="password"
                                        label="Contraseña"
                                        variant="outlined"
                                        name="password"
                                        value={password}
                                        type={
                                            showPassword ? "text" : "password"
                                        }
                                        onChange={(e) => {
                                            createPassword(e.target.value);
                                        }}
                                        className={`form-control ${
                                            errors.password ? "is-invalid" : ""
                                        }`}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.password?.message}
                                    </div>
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        {...register("confirmPwd")}
                                        fullWidth
                                        id="confirmPassword"
                                        label="Confirmar contraseña"
                                        name="confirmPwd"
                                        value={confirmPwd}
                                        type={
                                            showPasswordRepeat
                                                ? "text"
                                                : "password"
                                        }
                                        onChange={(e) => {
                                            createSetPassword(e.target.value);
                                        }}
                                        className={`form-control ${
                                            errors.confirmPwd
                                                ? "is-invalid"
                                                : ""
                                        }`}
                                        InputProps={{
                                            // <-- This is where the toggle button is added.
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPasswordRepeat
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPasswordRepeat
                                                        }
                                                    >
                                                        {showPasswordRepeat ? (
                                                            <VisibilityIcon />
                                                        ) : (
                                                            <VisibilityOffIcon />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        sx={{
                                            "& .MuiOutlinedInput-root": {
                                                "&:hover fieldset": {
                                                    borderColor: "#612cab",
                                                },
                                            },
                                        }}
                                    />
                                    <div className="invalid-feedback">
                                        {errors.confirmPwd?.message}
                                    </div>
                                    <PasswordStrengthBar
                                        password={password}
                                        minLength={3}
                                        onChangeScore={(score, feedback) => {}}
                                    />
                                </Grid>
                                {detail}
                            </Grid>

                            <Box sx={buttonContainer}>
                                <CustomButtonComponent
                                    async={true}
                                    loading={loading}
                                    bgColor={"primary-purple"}
                                    text={"Continuar"}
                                    height={55}
                                />
                            </Box>
                            <FormRecaptchaComponent
                                reCaptchaValues={reCaptchaValues}
                            />
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </BaseFirstStepsComponent>
    );
};

// export default Modal;
const mapStateToProps = (state) => ({
    setPassword: state.setPassword,
});
const mapDispatchToProps = {
    setPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
