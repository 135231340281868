import { useEffect } from "react";

const WbcAppPurchasePage = (props) => {
    useEffect(() => {
        return () => {};
    }, []);

    return null;
};

export default WbcAppPurchasePage;
