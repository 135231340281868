import {
    ADDPURCHASECONTACTINFO,
    ADDPURCHASECONTACTINFO_ERROR,
    LOGIN_CLEAN,
    LOGIN_CLEAN_ERROR,
    ADDPURCHASECONTACTINFO_CLEAN,
    ADDPURCHASECONTACTINFO_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    addPurchaseContactInfoObj: [],
    loading: false,
};

export default function addPurchaseContactInfo(state = initialState, action) {
    switch (action.type) {
        case ADDPURCHASECONTACTINFO:
        case LOGIN_CLEAN:
            return {
                ...state,
                response: action.payload,
                loading: true,
            };
        case ADDPURCHASECONTACTINFO_ERROR:
        case LOGIN_CLEAN_ERROR:
            return {
                ...state,
                response: action.payload,
                loading: false,
            };
        case ADDPURCHASECONTACTINFO_CLEAN:
        case ADDPURCHASECONTACTINFO_CLEAN_ERROR:
            return {
                ...state,
                response: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
