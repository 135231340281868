import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Grid, SvgIcon, Button } from "@mui/material";

import forgotPasswordErrorImage from "assets/images/login/login-image.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";

import { ReactComponent as forgotPasswordErrorIcon } from "assets/icons/login/error.svg";
import { cleanForgotAction } from "../../../_models/redux/forgot-password/clean";
import { connect } from "react-redux";

const ErrorForgotPasswordPage = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [errorNext, setErrorNext] = useState("");
    const [errorLast, setErrorLast] = useState("");

    const history = useNavigate();
    useEffect(() => {
        return () => {
            //action del clean
        };
    }, []);
    const goToLogin = () => {
        history("/forgot-password");
    };

    useEffect(() => {
        const { from } = props || { from: undefined };
        if (from == "password-expired") {
            setErrorMessage("La liga expiró");
            setErrorNext("");
            setErrorLast("Ya puedes cerrar esta ventana");
        } else {
            setErrorMessage("No se pudo procesar la solicitud");
            setErrorNext("Inténtalo nuevamente");
            setErrorLast("");
        }
        return () => {
            //     props.cleanForgotAction();
        };
    }, []);
    return (
        <Grid container className={"forgot-password-page"}>
            <Grid container className={"forgot-password-component-container"}>
                <Grid container className={"forgot-password-component"}>
                    <Grid item xs={12} className={"forgot-password-form"}>
                        <Grid
                            item
                            md={12}
                            lg={6}
                            className={"forgot-password-image-container"}
                        >
                            <img
                                title="Forgot Password Error Image"
                                alt="Forgot Password Error Image"
                                src={forgotPasswordErrorImage}
                                className={"forgot-password-image"}
                            />
                        </Grid>
                        <Grid
                            item
                            md={12}
                            lg={6}
                            mt={{ xs: 5, md: 2 }}
                            className={"forgot-password-component-container"}
                        >
                            <Grid
                                item
                                xs={12}
                                className={"forgot-password-component"}
                            >
                                <Grid
                                    container
                                    className={"forgot-password-form no-form"}
                                >
                                    <Grid
                                        container
                                        className={
                                            "forgot-password-icon-container"
                                        }
                                    >
                                        <SvgIcon
                                            className={
                                                "forgot-password-icon error-icon"
                                            }
                                            component={forgotPasswordErrorIcon}
                                            viewBox="0 0 60 60"
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "forgot-password-title-container"
                                        }
                                    >
                                        <h3 className={"response-title error"}>
                                            {errorMessage}
                                        </h3>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "forgot-password-text-container"
                                        }
                                    >
                                        <p
                                            className={
                                                "forgot-password-text custom-text"
                                            }
                                        >
                                            {errorNext}
                                        </p>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "forgot-password-text-container custom-text-container"
                                        }
                                    >
                                        <p className={"forgot-password-text"}>
                                            {errorLast}
                                        </p>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        className={
                                            "reset-password-button-container"
                                        }
                                    >
                                        <Button
                                            variant="text"
                                            className={"reset-password-button"}
                                            onClick={goToLogin}
                                        >
                                            Iniciar sesión
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <FormFooterComponent />
            </Grid>
        </Grid>
    );
};

const mapDispatchToProps = {
    cleanForgotAction,
};

export default connect(mapDispatchToProps)(ErrorForgotPasswordPage);
