import {
    GET_PURCHASE_PAYMENT_STATUS,
    GET_PURCHASE_PAYMENT_STATUS_ERROR,
} from "_models/redux/types";

import axiosAuthInstance from "services/api/authentication";

export const getPurchasePaymentStatusAction = (params) => async (dispatch) => {
    const { uuid } = params || {};

    try {
        const response = await axiosAuthInstance.get(
            `/api/leads/steps/v3/${uuid}/payment-status`
        );

        const { data } = response;

        dispatch({
            type: GET_PURCHASE_PAYMENT_STATUS,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: GET_PURCHASE_PAYMENT_STATUS_ERROR,
            payload: e || {},
        });
    }
};
