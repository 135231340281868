import { combineReducers } from "redux";

import loginAccountReducer from "./login-account/reducer";
import loginReducer from "./login/reducer";
import forgotPasswordReducer from "./forgot-password/reducer";
import resetPasswordReducer from "./reset-password/reducer";
import resetPasswordStatusReducer from "./reset-password/statusReducer";
import ticketsReducer from "./tickets/reducer";
import reenvioPinReducer from "./re-envio-pin/reducer";
import setPasswordReducer from "../../_models/redux/set-password/reducer";
import accountInfoReducer from "../redux/account-info/reducer";
import activeOffersReducer from "../redux/active-offers/reducer";
import historyShopingReducer from "../redux/history-shopping/reducer";
import productItemReducer from "../../_models/redux/product-item/reducer";
import paymentReducer from "../redux/payment/reducer";
import validatePinReducer from "../redux/validate-pin/reducer";
import addPurchaseContactInfoReducer from "./add-purchase-contact-info/reducer";
import checkImeiReducer from "../redux/check-imei/reducer";
import getOffersReducer from "../redux/get-offers/reducer";
import offerSelectionReducer from "../redux/offer-selection/reducer";
import shippingAddressReducer from "../redux/shipping-address/reducer";
import municipalitiesDataReducer from "../redux/municipalities/reducer";
import neighborhoodsDataReducer from "../redux/neighborhoods/reducer";
import paymentConfirmReducer from "../redux/payment-confirm/reducer";
import completeProcessReducer from "../redux/complete-process/reducer";

import portabilityReducer from "./portability/reducer";

import accountCardsTokenReducer from "../redux/account-cards-tokens/reducer";
import listRFCReducer from "../redux/legal-data/reducer";

import myCardsReducer from "./my-cards/reducer";
import myCardReducer from "./my-card/reducer";

import initRegisterReducer from "../redux/initRegister/reducer";
import myLegalReducer from "./my-legal/reducer";
import myLegalsReducer from "./my-legals/reducer";

import prepaidSubscriptionReducer from "_models/redux/prepaid-subscription/reducer";
import deleteSubscriptionReducer from "_models/redux/delete-suscription/reducer";

import leadsPaymentsReducer from "_models/redux/leads-payment/reducer";
import paymentLeadsUrlReducer from "_models/redux/payment-lead-url/reducer";

import purchaseRequestReducer from "_models/redux/purchase-request/reducer";
import getPromotionsReducer from "_models/redux/get-promotions/reducer";
import leadsPurchaseRequestReducer from "_models/redux/leads-purchase-request/reducer";

import getPrepaidConfirmationReducer from "_models/redux/prepaid-confirmation/reducer";

import purchasePaymentStatusReducer from "./purchase-payment-status/reducer";

import getLandingEsimReducer from "./get-landing-esim/reducer";

import postInvoiceSimPurchaseReducer from "./post-invoice-sim-purchase/reducer";
import getBrandsDevicesReducer from "./consult-brand-device/reducer";
import getDevicesModelsReducer from "./consult-device-models/reducer";
import postCompatibleDevicesReducer from "./select-devices/reducer";
import profileUpdatedReducer from "./profile-updated/reducer";

import postGiftFormReducer from "./post-gift-form/reducer";
import getLandingGiftReducer from "./get-landing-gift/reducer";
import stepOneInvoiceReducer from "./step-invoice-1/reducer";
import createInvoiceStep2Reducer from "./step-invoice-2/reducer";

import postLeadDataReducer from "./post-lead-data/reducer";

import postLeadCheckImeiReducer from "./post-lead-check-imei/reducer";

import getLeadOffersReducer from "./get-lead-offers/reducer";

import getLeadDataReducer from "./get-lead-data/reducer";
import InvoicePageDownloadReducer from "./invoice-page-download/reducer";

import getStoredLegalsReducer from "./get-stored-legals/reducer";

import postPrepaidAuthnInvoiceReducer from "./post-prepaid-authn-invoice/reducer";

import invoiceAutentificationReducer from "./invoice-autentification/reducer";

import checkImeiGiftReducer from "./check-imei-gift/reducer";
import checkZipCodeGiftReducer from "./zipcode-gift/reducer";
import verifyPinGiftReducer from "./verification-pin-gift/reducer";
import setUserGiftReducer from "./set-user-gift/reducer";
import sendEmailAndCompleteGiftReducer from "./email-process-complete/reducer";

export default combineReducers({
    setPassword: setPasswordReducer,
    login: loginReducer,
    loginAccount: loginAccountReducer,
    reenvioPin: reenvioPinReducer,
    validatePin: validatePinReducer,
    forgotPassword: forgotPasswordReducer,
    resetPassword: resetPasswordReducer,
    resetPasswordStatus: resetPasswordStatusReducer,
    tickets: ticketsReducer,
    accountInfo: accountInfoReducer,
    activeOffers: activeOffersReducer,
    historyShopping: historyShopingReducer,
    productItem: productItemReducer,
    addPurchaseContactInfo: addPurchaseContactInfoReducer,
    checkImei: checkImeiReducer,
    getOffers: getOffersReducer,
    offerSelection: offerSelectionReducer,
    shippingAddress: shippingAddressReducer,
    municipalitiesData: municipalitiesDataReducer,
    neighborhoodsData: neighborhoodsDataReducer,
    payment: paymentReducer,
    paymentConfirm: paymentConfirmReducer,
    completeProcess: completeProcessReducer,

    portability: portabilityReducer,

    accountCardsToken: accountCardsTokenReducer,
    listRFC: listRFCReducer,

    myCard: myCardReducer,
    initRegister: initRegisterReducer,
    myCards: myCardsReducer,

    myLegal: myLegalReducer,
    myLegals: myLegalsReducer,

    prepaidSubscription: prepaidSubscriptionReducer,
    deleteSubscription: deleteSubscriptionReducer,

    leadsPayments: leadsPaymentsReducer,
    paymentLeadsUrl: paymentLeadsUrlReducer,

    purchaseRequest: purchaseRequestReducer,
    getPromotions: getPromotionsReducer,
    leadsPurchaseRequest: leadsPurchaseRequestReducer,

    getPrepaidConfirmation: getPrepaidConfirmationReducer,

    purchasePaymentStatus: purchasePaymentStatusReducer,

    getLandingEsim: getLandingEsimReducer,

    postInvoiceSimPurchase: postInvoiceSimPurchaseReducer,
    getBrandsDevices: getBrandsDevicesReducer,
    getDevicesModels: getDevicesModelsReducer,
    postCompatibleDevices: postCompatibleDevicesReducer,
    profileUpdated: profileUpdatedReducer,

    postGiftForm: postGiftFormReducer,
    getLandingGift: getLandingGiftReducer,

    stepOneInvoice: stepOneInvoiceReducer,
    createInvoiceStep2: createInvoiceStep2Reducer,

    postLeadData: postLeadDataReducer,

    postLeadCheckImei: postLeadCheckImeiReducer,

    getLeadOffers: getLeadOffersReducer,

    getLeadData: getLeadDataReducer,

    InvoicePageDownload: InvoicePageDownloadReducer,

    getStoredLegals: getStoredLegalsReducer,
    postPrepaidAuthnInvoice: postPrepaidAuthnInvoiceReducer,

    invoiceAutentification: invoiceAutentificationReducer,
    checkImeiGift: checkImeiGiftReducer,
    checkZipCodeGift: checkZipCodeGiftReducer,
    verifyPinGift: verifyPinGiftReducer,
    setUserGift: setUserGiftReducer,
    sendEmailAndCompleteGift: sendEmailAndCompleteGiftReducer,
});
