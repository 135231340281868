import {
    GET_PREPAID_CONFIRMATION,
    GET_PREPAID_CONFIRMATION_ERROR,
    GET_PREPAID_CONFIRMATION_CLEAN,
    GET_PREPAID_CONFIRMATION_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    prepaidConfirmationObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PREPAID_CONFIRMATION:
            return {
                ...state,
                prepaidConfirmationObj: action.payload,
                loading: true,
            };
        case GET_PREPAID_CONFIRMATION_ERROR:
            return {
                ...state,
                prepaidConfirmationObj: action.payload,
                loading: false,
            };
        case GET_PREPAID_CONFIRMATION_CLEAN:
            return {
                ...state,
                prepaidConfirmationObj: action.payload,
                loading: true,
            };
        case GET_PREPAID_CONFIRMATION_CLEAN_ERROR:
            return {
                ...state,
                prepaidConfirmationObj: action.payload,
                loading: false,
            };
        default:
            return state;
    }
}
