import { useContext, useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AppContext } from "_models/context/app";

export const usePasswordAcountController1 = (props) => {
    const { loginAccount, loginAccountObj, response, data } = props || {};

    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const dataMessage = data || {};
    const viewPin = data || {};

    if (dataMessage.message === "success") {
        const codePinPath = "/forgot-password/media-selection-pin";
        navigate(codePinPath, { state: { gettingType: "CreateAccount" } });
    }

    const detailMessage = response || {};
    const { status } = response || {};
    const { detail } = detailMessage.data || {};

    appContext.setpinCodeNumber(viewPin.pin);

    const {
        register,
        handleSubmit,
        formState: { errors },
        trigger,
    } = useForm();

    const [direct_number, setDirectNumber] = useState("");
    const [name, setName] = useState("");
    const [last_name, createLast_name] = useState("");
    const [mothers_last_name, setMothers_last_name] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [checkedConditions, setCheckedConditions] = useState(false);
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [gender, setGender] = useState("");
    const [dateError, setDateError] = useState("");
    const [token, setToken] = useState(null);
    const reCaptchaAction = "create_account";
    const [callActionFlag, setCallActionFlag] = useState(false);
    const [formData, setFormData] = useState();
    // Antes de redirigir

    const handleChangeConditions = (event) => {
        setCheckedConditions(event.target.checked);
    };

    appContext.setDirectNumber(direct_number);

    const registerOptions = {
        direct_number: { required: "Número teléfonico requerido" },
        name: {
            required: "Nombre es requerido",
            validate: (value) => {
                if (value.length < 2) {
                    return "Debe tener al menos 2 caracteres";
                }
                return true;
            },
        },
        last_name: {
            required: "Apellido Paterno es requerido",
            validate: (value) => {
                if (value.length < 2) {
                    return "Debe tener al menos 2 caracteres";
                }
                return true;
            },
        },
        mothers_last_name: {
            required: "Apellido Materno es requerido",
            validate: (value) => {
                if (value.length < 2) {
                    return "Debe tener al menos 2 caracteres";
                }
                return true;
            },
        },
        dateOfBirth: { required: "Fecha de nacimiento es requerido" },
        gender: { required: "Este campo es requerido" },
        email: {
            pattern: {
                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                message: "Correo electrónico no válido",
            },
        },
    };

    const onSubmit = async (data) => {
        setFormData(data);
        localStorage.setItem("formData", JSON.stringify(data));

        // Realiza la validación de los campos requeridos
        const isValid = await trigger(); // Esto activará las validaciones

        if (isValid) {
            // Si todos los campos requeridos son válidos, procede con el envío del formulario
            setLoading(true);

            // Después del envío exitoso, redirige al usuario
            const codePinPath = "/forgot-password/media-selection-pin";
            navigate(codePinPath, {
                state: { gettingType: "CreateAccount", data },
            });
        }
    };

    useEffect(() => {
        if (status >= 400) {
            setLoading(false);
        }
    }, [loginAccount]);

    return {
        direct_number,
        name,
        last_name,
        mothers_last_name,
        email,
        loading,
        checkedConditions,
        dateOfBirth,
        gender,
        dateError,
        handleChangeConditions,
        registerOptions,
        setDirectNumber,
        setName,
        setMothers_last_name,
        setEmail,
        handleSubmit,
        onSubmit,
        errors,
        setDateOfBirth,
        setGender,
        setDateError,
        viewPin,
        detail,
        register,
        createLast_name,
        formData,
    };
};
