export const gridContainerInfoProduct = {
    background: "rgba(121, 107, 172, 0.25) 0% 0% no-repeat padding-box",
    borderRadius: "25px",
    py: 4,
    color: "white",
    mt: 3,
};

export const gridItemInfoProduct1 = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

export const typographyNameProduct = {
    fontSize: { xs: 25, md: 28 },
    fontWeight: "bold",
    fontFamily: "Poppins-Bold",
};

export const typographyPriceProduct = {
    fontSize: { xs: 60, md: 81 },
    my: 1,
    fontWeight: "bold",
    fontFamily: "Poppins-ExtraBold",
};

export const gridItemButtonInvoice = {
    mt: 1,
    display: "flex",
    justifyContent: "center",
};

export const gridItemInfoPayment = {
    mt: 3,
};

export const boxContainerInfoProduct = {
    background: "rgba(177, 162, 207, 0.18) 0% 0% no-repeat padding-box",
    borderRadius: 2,
    py: 2,
    mx: 4,
};

export const gridContainerDetailsProduct = {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    px: 3,
};

export const typographyTitleDetail = {
    fontSize: 15,
    fontFamily: "Poppins-Regular",
    textAlign: "left",
};

export const typographyValueOfItem = {
    fontSize: 17,
    textAlign: "right",
    fontFamily: "Poppins-Medium",
};
