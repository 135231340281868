import React from "react";
import "assets/styles/css/gift/gift-compatibility.css";
import logoDalefon from "assets/images/logo.png";
import { MessageResponse } from "../components/gift-compatibility/message-responses/MessageResponse";
import { CardResponse } from "../components/gift-compatibility/card-responses/CardResponse";
import { Container, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";

export const GilftCompatibilityPage = () => {
    const location = useLocation();
    console.log("location:", location);
    const isCompatibility = location.state?.isCompatibility || "";
    const tokenLead = location.state?.tokenLead || "";
    console.log("isCompatibility:", isCompatibility);
    console.log("tokenLead:", tokenLead);

    return (
        <Container maxWidth="lg" className="gift-container-compatibility">
            <img src={logoDalefon} alt="Logo dalefon" width={160} />
            <Grid
                container
                display={"flex"}
                alignItems={"center"}
                height={{ xs: "auto", md: "80%" }}
                mt={{ xs: "60px", md: 0 }}
            >
                <Grid item xs={12} md={7}>
                    <MessageResponse
                        isCompatibility={isCompatibility}
                        tokenLead={tokenLead}
                    />
                </Grid>
                <Grid item xs={12} md={5}>
                    <CardResponse
                        isCompatibility={isCompatibility}
                        tokenLead={tokenLead}
                    />
                </Grid>
            </Grid>
        </Container>
    );
};
