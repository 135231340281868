export const getCardTypeOptions = () => {
    return {
        cardTypeOptions: [
            { id: "0", name: "Seleccione una opción" },
            {
                id: "03",
                name: "03 - Transferencia electrónica de fondos",
            },
            { id: "04", name: "04 - Tarjeta de crédito" },
            { id: "28", name: "28 - Tarjeta de débito" },
        ],
    };
};
