import axiosTokenInstance from "services/api/service-client";

import {
    PREPAID_SUBSCRIPTION,
    PREPAID_SUBSCRIPTION_ERROR,
} from "_models/redux/types";

export const prepaidSubscriptionAction = (params) => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/actions/prepaid-subscription`
        );

        const { data } = response;

        dispatch({
            type: PREPAID_SUBSCRIPTION,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: PREPAID_SUBSCRIPTION_ERROR,
            payload: e || {},
        });
    }
};
