// // Import: Middleware Action & Custom Types
// import MiddlewareClass from "services/MiddlewareClass";
import { LISTRFC, LISTRFC_ERROR } from "_models/redux/types";
import axiosTokenInstance from "services/api/service-client";

export const listRFCAction = () => async (dispatch) => {
    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/invoices/legal-data`
        );

        const { data } = response;

        dispatch({
            type: LISTRFC,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: LISTRFC_ERROR,
            payload: e || {},
        });
    }
};
