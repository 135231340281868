import {
    POST_PREPAID_AUTHN_INVOICE,
    POST_PREPAID_AUTHN_INVOICE_ERROR,
} from "_models/redux/types";

import axiosTokenInstance from "services/api/service-client";

export const postPrepaidAuthnInvoiceAction = (params) => async (dispatch) => {
    try {
        //TODO: Change this to the correct params and endpoint
        const { client_token, id_transaction, recaptcha_token, post_data } =
            params || {};
        // const requestData = { id_transaction, recaptcha_token };

        const response = await axiosTokenInstance.post(
            `/api/transactions/prepaids/invoices`,
            post_data
        );

        const { data, status } = response || {};

        dispatch({
            type: POST_PREPAID_AUTHN_INVOICE,
            status: status || {},
            payload: data,
        });
    } catch (e) {
        console.error(e);

        const { response } = e || {};
        const { data, status } = response || {};

        dispatch({
            type: POST_PREPAID_AUTHN_INVOICE_ERROR,
            status: status || {},
            payload: data || {},
        });
    }
};
