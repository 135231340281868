import { Box } from "@mui/material";
import React from "react";
import "assets/styles/css/gift/gift.css";

export const CardGift = ({ children, ...props }) => {
    return (
        <Box className="card-gift-container" {...props}>
            {children}
        </Box>
    );
};
