import { useContext, useEffect } from "react";
import { AppContext } from "_models/context/app";

import { useNavigate } from "react-router-dom";
import { usePixelsPurchase } from "modules/purchase/hook/usePixelsPurchase";

export const HomeMenuController = ({
    completeProcess,
    cleanCompleteProcessAction,
}) => {
    const navigate = useNavigate();

    const appContext = useContext(AppContext);
    const { setPressed } = appContext;

    const { clickButtonInitialPage } = usePixelsPurchase({});

    const purchaseEvent = () => {
        clickButtonInitialPage({ description: "Purchase Button" });
        navigate("/purchase", {});
    };

    const clientEvent = () => {
        clickButtonInitialPage({ description: "Client Button" });
        navigate(`/client`);
    };

    useEffect(() => {
        return () => {
            cleanCompleteProcessAction();
            window.onpopstate = () => {
                setPressed(false);
            };
        };
    }, []);

    return { clientEvent, purchaseEvent };
};
