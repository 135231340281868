export const gridContainerInvoiceYourPrepaid = {
    display: "flex",
    justifyContent: "space-between",
    textAlign: { xs: "center", md: "left" },
};

export const typographyTitleInvoice = {
    fontSize: { xs: 25, md: 40 },
    color: "#4F3078",
    fontWeight: "bold",
    fontFamily: "Poppins-Bold",
};

export const boxContainerInfoProduct = {
    background:
        "transparent linear-gradient(180deg, #F2EEF8 0%, #F5EEFF 51%, #DBD0FA 100%) 0% 0% no-repeat padding-box",
    borderRadius: "20px",
    p: { xs: 3, md: 5 },
    color: "#3A2951",
};

export const typographyNameProduct = {
    fontSize: { xs: 19, md: 23 },
    fontFamily: "Poppins-Bold",
};

export const typographyPriceProduct = {
    fontSize: { xs: 46, md: 55 },
    fontFamily: "Poppins-ExtraBold",
};

export const typographyDate = {
    fontSize: 15,
    fontFamily: "Poppins-Bold",
};

export const boxContainerSelected = {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    border: "1px solid #E1E1E1",
    borderRadius: "10px",
    p: { xs: 2, md: 3 },
    pb: { xs: 3, md: 4 },
    textAlign: "left",
    mb: 4,
};

export const typographyTitleCard = {
    fontSize: { xs: 16, md: 20 },
    fontFamily: "Poppins-Bold",
};

export const typographyItem = {
    mt: { xs: 2, md: 4 },
    fontSize: 13,
    color: "#3D3D3D",
    mb: 1.5,
    fontWeight: "bold",
};

export const boxContainerButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    mt: 4,
};

export const boxButton = {
    width: { xs: "100%", md: "45%" },
};
