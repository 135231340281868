import React from "react";

import GiftConfirmationContent from "./components/gift-confirmation-content";

const GiftConfirmationPage = () => {
    return (
        <>
            <GiftConfirmationContent />
        </>
    );
};

export default GiftConfirmationPage;
