import {
    SET_USER_GIFT,
    SET_USER_GIFT_ERROR,
    CLEAN_SET_USER_GIFT,
    CLEAN_SET_USER_GIFT_ERROR,
} from "_models/redux/types";

const initialState = {
    loaded: undefined,
    method: undefined,
    payload: undefined,
    status: undefined,
    state: undefined,
};

export default function setUserGiftReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_GIFT:
            return {
                ...state,
                loaded: true,
                method: action?.method || null,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "success",
            };

        case SET_USER_GIFT_ERROR:
            return {
                ...state,
                loaded: true,
                payload: action?.payload || {},
                status: action?.status || null,
                state: "error",
            };

        case CLEAN_SET_USER_GIFT:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "success",
            };

        case CLEAN_SET_USER_GIFT_ERROR:
            return {
                ...state,
                loaded: false,
                payload: action?.payload || undefined,
                status: undefined,
                state: "error",
            };
        default:
            return state;
    }
}
