import React from "react";
import { Container, Grid } from "@mui/material";
import promotionsImage from "assets/images/promotions/promotions-header.png";

const PromotionsHeader = (props) => {
    return (
        <div className={"header"}>
            <Container>
                <Grid container className="promotions-header">
                    <Grid item xs={12} className="promo-text">
                        <h1>
                            Porque nos encanta <span>consentirte</span>
                        </h1>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default PromotionsHeader;
