import React from "react";
import RouterContainer from "../container";
import PrivatePage from "router/pages/private";

const PublicRoutes = ({ children }) => {
    return (
        <RouterContainer
            loggedChildren={<PrivatePage />}
            unloggedChildren={children}
        />
    );
};

export default PublicRoutes;
