import {
    Container,
    Grid,
    Box,
    Typography,
    CircularProgress,
} from "@mui/material";
import "assets/styles/css/gift/gift.css";
import imgLogo from "assets/images/logo.png";
import {
    gridContainer,
    logoContainer,
    typographyTitleOne,
    typographyTitleTwo,
    typographyTitleThree,
    titleContainer,
} from "../styles-mui/giftStyleMUI";
import CardPromotion from "./card-promotion/CardPromotion";
import { useGift } from "../hooks/useGift";
import ErrorSignUpPage from "./ErrorComponent";
import { initRegisterAction } from "_models/redux/initRegister/action";
import { connect } from "react-redux";
import { cleanCheckImeiGift } from "_models/redux/check-imei-gift/clean-action";
import { cleanSendEmailAndCompleteGift } from "_models/redux/email-process-complete/clean-action";
import { cleanVerifyPinGift } from "_models/redux/verification-pin-gift/clean-action";
import { cleanCheckZipCodeGift } from "_models/redux/zipcode-gift/clean-action";
import { cleanSetUserGift } from "_models/redux/set-user-gift/clean-action";

function GiftMainFormComponent({
    initRegisterAction,
    initRegister,
    cleanCheckImeiGift,
    cleanCheckZipCodeGift,
    cleanVerifyPinGift,
    cleanSetUserGift,
    cleanSendEmailAndCompleteGift,
}) {
    const { hasError, loading } = useGift(
        initRegisterAction,
        initRegister,
        cleanCheckImeiGift,
        cleanCheckZipCodeGift,
        cleanVerifyPinGift,
        cleanSetUserGift,
        cleanSendEmailAndCompleteGift
    );
    return (
        <Container maxWidth="lg" className="gift-container">
            {hasError ? (
                <ErrorSignUpPage />
            ) : (
                <Grid container sx={gridContainer}>
                    <Grid item xs={12} md={7} sx={titleContainer}>
                        <Box sx={logoContainer}>
                            <img src={imgLogo} className="logo-title" />
                        </Box>
                        <Typography sx={typographyTitleOne}>
                            La Telefonía Morada
                        </Typography>
                        <Typography sx={typographyTitleThree}>
                            te regala
                        </Typography>
                        <Typography sx={typographyTitleTwo}>
                            una eSIM
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ color: "white" }}>
                        {loading ? (
                            <CircularProgress color="inherit" />
                        ) : (
                            <CardPromotion />
                        )}
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

const mapStateToProps = (state) => ({
    initRegister: state.initRegister,
});

const mapDispatchToProps = {
    initRegisterAction,
    cleanCheckImeiGift,
    cleanCheckZipCodeGift,
    cleanVerifyPinGift,
    cleanSetUserGift,
    cleanSendEmailAndCompleteGift,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GiftMainFormComponent);
