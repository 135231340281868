import axiosTokenInstance from "services/api/service-client";
import { getLoginDataHelper } from "../../../_controllers/helpers/getLoginData";
import {
    ACCOUNTCARDSTOKENS,
    ACCOUNTCARDSTOKENS_ERROR,
} from "_models/redux/types";

export const accountCardsTokensAction = (params) => async (dispatch) => {
    const { token } = getLoginDataHelper();

    try {
        const response = await axiosTokenInstance.get(
            `/api/clients/cards/tokenizations`
        );

        const { data } = response || {};

        dispatch({
            type: ACCOUNTCARDSTOKENS,
            payload: data,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            type: ACCOUNTCARDSTOKENS_ERROR,
            payload: e || {},
        });
    }
};
