import { useCallback, useEffect, useState } from "react";

import { Grid } from "@mui/material";

import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const ReCaptchaComponent = (props) => {
    const { parentProps } = props || {};
    const { containerProps } = parentProps || {};

    const { reCaptchaAction, callActionFlag, setToken, token } =
        containerProps || {};

    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha || !callActionFlag) {
            return;
        }

        const tokenGenerated = await executeRecaptcha(reCaptchaAction);

        setToken(tokenGenerated);
    }, [executeRecaptcha, callActionFlag]);

    // You can use useEffect to trigger the verification as soon as the component being loaded
    useEffect(() => {
        if (executeRecaptcha && callActionFlag) {
            handleReCaptchaVerify();
        }
    }, [executeRecaptcha, callActionFlag]);

    return null;
};

const CustomRecaptchaComponent = (props) => {
    const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_ENTERPRISE || "";
    const useEnterprise = true;
    const recaptchaId = "recaptchaContainer";
    const badge = "inline";
    const theme = "default";

    return (
        <Grid className={""}>
            <div id={recaptchaId}></div>
            <GoogleReCaptchaProvider
                reCaptchaKey={reCaptchaKey}
                useEnterprise={useEnterprise}
                container={{
                    element: recaptchaId,
                    parameters: {
                        badge: badge,
                        theme: theme,
                    },
                }}
            >
                <ReCaptchaComponent parentProps={props} />
            </GoogleReCaptchaProvider>
        </Grid>
    );
};

export default CustomRecaptchaComponent;
