import {
    SHIPPINGADDRESS_CLEAN,
    SHIPPINGADDRESS_CLEAN_ERROR,
} from "_models/redux/types";

export const cleanShippinghAddressAction = () => async (dispatch) => {
    try {
        dispatch({
            type: SHIPPINGADDRESS_CLEAN,
            payload: [],
        });
    } catch (e) {
        dispatch({
            type: SHIPPINGADDRESS_CLEAN_ERROR,
            payload: [],
        });
    }
};
