import React from "react";

import { Grid, Button, TextField } from "@mui/material";

import miniLogo from "assets/images/mini-logo.png";
import FormFooterComponent from "widgets/form-footer/FormFooterComponent";
import activationImage from "assets/images/activation/activation.png";

const ActivationPage = () => {
    return (
        <Grid container className={"main-component"}>
            <Grid item xs={12} className={"main-form"}>
                <Grid item xs={12} md={6} className={"main-image-container"}>
                    <img src={activationImage} className={"main-image"} />
                </Grid>
                <Grid item xs={12} md={6} className="activation-form">
                    <form>
                        <Grid
                            item
                            xs={12}
                            className={"activation-title-container flex"}
                        >
                            <Grid item container xs={6}>
                                <h2>Activa tu nueva SIM</h2>
                            </Grid>
                            <Grid
                                item
                                container
                                xs={6}
                                className={"main-logo-container"}
                            >
                                <img
                                    title="Mini Logo"
                                    alt="Mini Logo"
                                    src={miniLogo}
                                    className={"main-logo"}
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className="activation-text">
                            <p>
                                Ingresa el <span>número de orden</span> de la
                                tarjeta SIM que deseas activar
                            </p>

                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Número de orden"
                                variant="outlined"
                                className="activation-textfield-container"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                className={"main-button"}
                            >
                                Continuar
                            </Button>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
            <FormFooterComponent />
        </Grid>
    );
};

export default ActivationPage;
