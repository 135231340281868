import {
    PAYMENT,
    PAYMENT_ERROR,
    PAYMENT_CLEAN,
    PAYMENT_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    paymentObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PAYMENT:
            return {
                ...state,
                paymentObj: action.payload,
                loading: true,
            };
        case PAYMENT_ERROR:
            return {
                ...state,
                paymentObj: action.payload,
                loading: false,
            };
        case PAYMENT_CLEAN:
        case PAYMENT_CLEAN_ERROR:
            return {
                ...state,
                paymentObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
