import React, { useEffect } from "react";

import { Container } from "@mui/material";
import { connect } from "react-redux";

import { getTickets } from "_models/redux/tickets/action";
import TicketHeader from "./widgets/header";
import TicketsDatagrid from "./widgets/datagrid";

const UserTicketsPage = (props) => {
    return (
        <Container>
            <TicketHeader />
            <TicketsDatagrid />
        </Container>
    );
};

const mapStateToProps = (state) => ({ tickets: state.tickets });

const mapDispatchToProps = { getTickets };

export default connect(mapStateToProps, mapDispatchToProps)(UserTicketsPage);
