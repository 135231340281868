import React from "react";

import { Grid, CircularProgress } from "@mui/material";

const EsimLoader = () => {
    return (
        <Grid item xs={12} className="qr-activation-loader">
            <CircularProgress className="white" />
        </Grid>
    );
};

export default EsimLoader;
