import { useContext, useState, useEffect } from "react";

import { Grid, Typography } from "@mui/material";

import { InvoiceContext } from "../data/invoiceContext";

import InvoiceFormComponent from "./InvoiceFormComponent";
import FormRecaptchaComponent from "utils/helpers/recaptcha/form-recaptcha-reducer-component";
import InvoiceErrorResponse from "./InvoiceErrorResponseComponent";
import InvoiceLoaderResponse from "./InvoiceLoaderResponseComponent";
import InvoiceSuccessResponse from "./InvoiceSuccessResponseComponent";
import Divider from "@mui/material/Divider";

import CustomDialog from "utils/helpers/genericModal/modal";
const InvoiceByStepRegisterComponent = () => {
    const invoiceContext = useContext(InvoiceContext);
    const { invoiceStep2, invoiceStep1 } = invoiceContext;
    const { invoiceDataStep2, dispatchInvoiceDataStep2 } = invoiceStep2 || {};

    const { sectionResponse, loaderResponse } = invoiceDataStep2;
    const simOrderNumber =
        invoiceStep1?.invoiceDataStep1?.simOrderNumber ||
        invoiceStep1?.invoiceDataStep1?.orderNumberParam;
    const selectedOption = invoiceStep1?.invoiceDataStep1?.selectedOption;
    const phoneNumber = invoiceStep1?.invoiceDataStep1?.phoneNumber;
    const transactionId = invoiceStep1?.invoiceDataStep1?.transactionId;
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const handleSuccess = () => {
            // Lógica específica para el caso de éxito, si es necesario
            openModal("Éxito", <InvoiceSuccessResponse />);
        };

        const handleError = () => {
            // Lógica específica para el caso de error, si es necesario
            openModal("Error", <InvoiceErrorResponse />);
        };

        const openModal = (title, content) => {
            // Función para abrir el modal
            setIsModalOpen(true);
            // Lógica adicional para personalizar el modal según tus necesidades
            // Puedes agregar más configuraciones o acciones aquí
        };

        if (sectionResponse === "success") {
            handleSuccess();
        } else if (sectionResponse === "error") {
            handleError();
        }
    }, [sectionResponse]);

    return (
        <Grid container spacing={2}>
            {/* Left Content */}
            <Grid item xs={12} md={6}>
                <Typography variant="h4">¡Estás a un paso más!</Typography>
                <Typography
                    variant="body1"
                    style={{
                        fontSize: "17px",
                        textAlign: "left",
                        fontWeight: "500",
                        lineHeight: "28px",
                    }}
                >
                    {selectedOption === "prepaid_purchase" ? (
                        <>
                            Ingresa tus datos fiscales para obtener tu factura.
                            Todos los campos son obligatorios.
                        </>
                    ) : (
                        <>
                            ¡Gracias por tu compra! Solo necesitamos algunos
                            datos fiscales para completar tu factura. Todos los
                            campos son obligatorios.
                        </>
                    )}
                </Typography>
                <Grid item xs={12} className="invoice-data" mt={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid
                                container
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h6">
                                        TIPO DE COMPRA
                                    </Typography>
                                </Grid>

                                {/* RECARGA */}
                                <Grid item xs={6} md={6}>
                                    <Typography variant="h5" textAlign="right">
                                        {selectedOption === "prepaid_purchase"
                                            ? "Recarga"
                                            : "Compra eSIM/SIM"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* Tipo de Compra */}

                        {/* Divider */}
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    bgcolor: "#4E3284",
                                    paddingLeft: "20",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            {/* NÚMERO DE ORDEN */}
                            {selectedOption !== "prepaid_purchase" &&
                                simOrderNumber && (
                                    <Grid container alignItems="center">
                                        <Grid item xs={6} md={6}>
                                            <Typography variant="h6">
                                                NÚMERO DE ORDEN
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <Typography
                                                variant="h5"
                                                textAlign={{
                                                    xs: "left",
                                                    md: "right",
                                                }}
                                                sx={{
                                                    marginLeft: {
                                                        xs: "0",
                                                        md: "15rem",
                                                    },
                                                }}
                                            >
                                                {simOrderNumber}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                )}

                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                {/* NÚMERO TELEFÓNICO */}
                                {selectedOption === "prepaid_purchase" &&
                                    phoneNumber && (
                                        <>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} md={6}>
                                                    <Typography variant="h6">
                                                        NÚMERO TELEFÓNICO
                                                    </Typography>
                                                    <Typography variant="h5">
                                                        {phoneNumber}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}

                                {/* ID DE TRANSACCIÓN */}
                                {selectedOption === "prepaid_purchase" &&
                                    transactionId && (
                                        <>
                                            <Grid container alignItems="center">
                                                <Grid item xs={6} md={6}>
                                                    <Typography
                                                        variant="h2"
                                                        sx={{
                                                            marginLeft: {
                                                                xs: "0",
                                                                md: "14rem",
                                                            },
                                                        }}
                                                    >
                                                        ID DE TRANSACCIÓN
                                                    </Typography>
                                                    <Grid item xs={6} md={6}>
                                                        <Typography
                                                            variant="h5"
                                                            textAlign="right"
                                                            sx={{
                                                                marginLeft: {
                                                                    xs: "0",
                                                                    md: "14rem",
                                                                },
                                                            }}
                                                        >
                                                            {transactionId}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Right Content */}
            <Grid item xs={12} md={6}>
                <Grid container className="invoice-form">
                    <InvoiceFormComponent />
                    {sectionResponse === "success" ||
                    sectionResponse === "error" ? (
                        <CustomDialog
                            title={
                                sectionResponse === "success"
                                    ? "Éxito"
                                    : "Error"
                            }
                            content={
                                sectionResponse === "success" ? (
                                    <InvoiceSuccessResponse />
                                ) : (
                                    <InvoiceErrorResponse />
                                )
                            }
                            open={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            backgroundColor="#ffffff"
                        />
                    ) : (
                        <>{loaderResponse && <InvoiceLoaderResponse />}</>
                    )}
                    <FormRecaptchaComponent
                        stateReducer={invoiceDataStep2}
                        dispatchReducer={dispatchInvoiceDataStep2}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default InvoiceByStepRegisterComponent;
