export const gridItemContainerSeparator = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mt: { xs: 0, md: 4 },
    my: { xs: 2, md: 0 },
};

export const typographySeparator = {
    color: "white",
    fontSize: { xs: 25, md: 30 },
};
