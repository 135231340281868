import {
    COMPLETEPROCESS,
    COMPLETEPROCESS_ERROR,
    COMPLETEPROCESS_CLEAN,
    COMPLETEPROCESS_CLEAN_ERROR,
} from "_models/redux/types";

const initialState = {
    completeProcessObj: [],
    loading: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case COMPLETEPROCESS:
            return {
                ...state,
                completeProcessObj: action.payload,
                loading: true,
            };
        case COMPLETEPROCESS_ERROR:
            return {
                ...state,
                completeProcessObj: action.payload,
                loading: false,
            };
        case COMPLETEPROCESS_CLEAN:
        case COMPLETEPROCESS_CLEAN_ERROR:
            return {
                ...state,
                completeProcessObj: action.payload,
                loading: false,
            };

        default:
            return state;
    }
}
