import React from "react";
import { connect } from "react-redux";
import { Grid, Box } from "@mui/material";
import imgRibbon from "assets/images/gift/ribbon.png";
import imgGift from "assets/images/gift/imagen-con-esim-de-regalo.jpg";
import { useCardPromotion } from "modules/gift/hooks/useCardPromotion";
import { checkImeiGiftAction } from "_models/redux/check-imei-gift/action";
import { gridResponsive } from "modules/gift/styles-mui/giftStyleMUI";
import PriceSection from "../PriceSection";
import GBSection from "../GBSection";
import SocialMediaSection from "../SocialMediaSection";
import ImeiForm from "../ImeiForm";
import CustomDialog from "utils/helpers/genericModal/modal";
import { cleanCheckImeiGift } from "_models/redux/check-imei-gift/clean-action";

const CardPromotion = ({
    checkImeiGiftAction,
    checkImeiGift,
    cleanCheckImeiGift,
}) => {
    const {
        handleInputChange,
        handleButtonClickWithLoader,
        imei,
        loading,
        openModalMessage,
        openModal,
        handleClose,
        onSubmit,
        register,
        handleSubmit,
        errors,
    } = useCardPromotion({
        checkImeiGiftAction,
        checkImeiGift,
        cleanCheckImeiGift,
    });

    return (
        <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            className="card-container"
        >
            <Grid item xs={12}>
                <img src={imgRibbon} className="ribbon-image" />
            </Grid>
            <Grid item xs={12}>
                <PriceSection />
                <GBSection />
            </Grid>
            <Box>
                <img
                    src={imgGift}
                    className="gift-image"
                    alt="Caja de regalo con una esim"
                    style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                    }}
                />
            </Box>
            <Grid item xs={12} sx={gridResponsive}>
                <SocialMediaSection />
            </Grid>
            <Box className="container-responsive">
                <ImeiForm
                    imei={imei}
                    handleInputChange={handleInputChange}
                    handleButtonClickWithLoader={handleButtonClickWithLoader}
                    openModalMessage={openModalMessage}
                    onSubmit={onSubmit}
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    loading={loading}
                />
            </Box>

            <CustomDialog
                title="Error"
                content={openModalMessage}
                open={openModal}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                backgroundColor="white"
                extraButtons={[
                    {
                        label: "Cerrar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    checkImeiGift: state.checkImeiGift,
});

const mapDispatchToProps = {
    checkImeiGiftAction,
    cleanCheckImeiGift,
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPromotion);
